﻿@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/css-property-to-em" as *;
@use "../mixins/button-animation" as *;

.button-1 {
    font-size: $font-size-14;
    font-family: $main-font;
    font-weight: $weight-bold;
    letter-spacing: 0.02em;
    height: math.div(54,14)*1em;
    min-width: math.div(248,14)*1em;
    padding: 0 math.div(10,14)*1em;
    text-align: center;

    @include button-animation($primary);

    @include tablet {
        height: math.div(50,14)*1em;
        min-width: math.div(196,14)*1em;
    }

    &.silver-btn {
        @include button-animation($silver);
    }

    &.white-button {
        @include button-animation($primary, false, transparent);
    }
}

.button-2 {
    font-size: $font-size-14;
    font-family: $main-font;
    font-weight: $weight-bold;
    letter-spacing: 0.02em;
    height: math.div(45,14)*1em;
    padding: 0 math.div(10,14)*1em;
    text-align: center;

    @include button-animation($primary, false, transparent);
}

a {
    &.button-1 {
        display: inline-block;
        line-height: math.div(52,14)*1em;

        @include tablet {
            line-height: math.div(50,14)*1em;
        }
    }

    &.button-2 {
        display: inline-block;
        line-height: math.div(43,14)*1em;
    }
}

.brown-btn {
    width: 100%;
    padding: 15px 12px;
}

.add-to-compare-list-button {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-12;
    color: $silver;
    line-height: 1.5;
    font-weight: $weight-bold;
    letter-spacing: $large-letter-spacing;
    padding-top: math.div(15,12)*1em;
    padding-bottom: math.div(8,12)*1em;
    background-color: $whitelight;
    height: auto;


    &:focus-visible {
        outline-width: 0;
    }

    img {
        margin-right: 5px;
        max-width: 26px;
        transition: transform .2s ease-out;
    }

    &:hover {
        color: $silver;

        img {
            transform: scale(1.1);
        }
    }

    &:before {
        content: none;
    }
}

.add-to-wishlist-button {
    font-size: 0;
    border: 0;
    background-color: transparent;
    width: 26px;
    height: 22px;
    background-image: url('../../images/wishlist-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:before {
        content: none;
    }

    &:hover, &.active {
        background-image: url('../../images/wishlist-icon-filled.svg')
    }
}