@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.manufacturer-list-page {
    .man-page-info-text-wrapper {
        margin: 0 0 math.div(32,14)*1em;
        text-align: center;
        font-size: $font-size-14;
        color: $primary;
    }

    .man-filter-options {
        text-align: center;
        margin-bottom: clamp(math.div(32,16)*1rem, math.div(56,1280)*100vw, math.div(56,16)*1rem);

        .text-center {
            color: $primary;
            margin: 0 0 math.div(12,14)*1em;
        }
    }

    .filterbuttons {
        background: transparent;
        border: 0;
        padding: math.div(7,16)*1em;
        font-weight: $weight-bold;
        font-size: 1rem;

        @include tablet {
            padding-top: 0;
            padding-bottom: 0;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .my-shuffle-container {
        border-top: 1px solid $beige;
        position: relative;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;

        @include from(768px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }

        @include from(1024px) {
            border-color: $primary;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .item-box {
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;

        .picture {
            img {
                max-width: 75%;
                max-height: 75%;
                filter: grayscale(100%);
            }
        }

        .title {
            font-size: $font-size-14;
            display: flex;
            align-items: center;
            justify-content: center;
            height: math.div(56,16)*1rem;
            font-weight: $weight-600;
            text-align: center;
            padding: 0 math.div(5,16)*1rem;
            border-top: 1px solid $beige;
        }

        &:not(.item-box-man-heading) {
            border: 1px solid $beige;
        }

        &:hover {
            .picture {
                img {
                    filter: grayscale(0);
                }
            }
        }
    }

    .picture {
        margin-bottom: 0;
    }

    .item-box-man-heading {
        width: 100%;
        max-width: 100%;
        background: transparent;
        grid-column: 1/-1;
        margin-top: math.div(20,16)*1rem;
        margin-bottom: math.div(20,16)*1rem;

        .page-title {
            margin-bottom: 0;
        }
    }
}