﻿@use 'sass:math';

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800&family=Manrope:wght@400;500;600;700;800&display=swap');

/* -------------------- Colors -------------------- */
$beige: #DFD9D3 !default;
$primary: #7A6453 !default;
$silver: #728E8F !default;
$silver2: #BDBDBD !default;
$blue: #abced6 !default;
$whitelight: #F5F5F5 !default;
$red: #D95858 !default;
$gray: #F2F2F2 !default;
$gray2: #4F4F4F !default;
$gray3: #828282 !default;
$bgGray: #E5E5E5 !default;
$mine_shaft: #333333 !default;
$rough_blue: #0D9ABF !default;
$white: #FFFFFF !default;
$black: #000000 !default;
$aqua_forest: #66B17B !default;

/* -------------------- Custom Font Sizes -------------------- */
$font-size-61: math.div(61,16)*1rem !default;
$font-size-48: math.div(48,16)*1rem !default;
$font-size-40: math.div(40,16)*1rem !default;
$font-size-31: math.div(31,16)*1rem !default;
$font-size-24: math.div(24,16)*1rem !default;
$font-size-21: math.div(21,16)*1rem !default;
$font-size-20: math.div(20,16)*1rem !default;
$font-size-18: math.div(18,16)*1rem !default;
$font-size-16: math.div(16,16)*1rem !default;
$font-size-14: math.div(14,16)*1rem !default;
$font-size-13: math.div(13,16)*1rem !default;
$font-size-12: math.div(12,16)*1rem !default;
$font-size-10: math.div(10,16)*1rem !default;

/* -------------------- Custom Letter Spacing -------------------- */
$basic-letter-spacing: 0.02em;
$medium-letter-spacing: 0.15em;
$large-letter-spacing: 0.29em;
$xlarge-letter-spacing: 0.45em;

/* -------------------- Custom Spaces -------------------- */
$gap-12: math.div(12,16)*1rem !default;
$gap-18: math.div(18,16)*1rem !default;
$gap-75: math.div(75,16)*1rem !default;

/* -------------------- Custom Variables -------------------- */
$mobile-padding: $gap-18 !default; 
$container-width: 1106px !default;
$main-font: 'Manrope' !default;
$second-font: 'Cairo' !default;
$weight-500: 500 !default;
$weight-600: 600 !default;
$weight-800: 800 !default;

/* -------------------- Bulma Variables -------------------- */
$family-sans-serif: $main-font, $second-font, sans-serif;

$size-1: $font-size-61;
$size-2: $font-size-48;
$size-3: $font-size-40;
$size-4: $font-size-31;
$size-5: $font-size-24;
$size-6: $font-size-20;
$size-7: $font-size-16;

$body-font-size: $font-size-16;

$primary: $primary;
$body-background-color: $gray;
$text: $mine_shaft;
$strong-color: $text;
$text-strong: $text;
// $link: $hoki;

$column-gap: 12px;
$gap: 12px;
$tablet: 1024px;
$desktop: 1176px + (2 * $gap);
$widescreen: $container-width + (2 * $gap);
$widescreen-enabled: true;
$fullhd: $container-width + (2 * $gap);
$fullhd-enabled: true;

$navbar-burger-color: $primary;