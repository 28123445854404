@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.html-home-page {
    .master-wrapper-page {
        position: relative;

        .header {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            
            &:not(.menu-is-active) {
                background-color: transparent;
            }

            @include tablet {
                padding-bottom: math.div(16,16)*1em;
        
                &:before {
                    content: '';
                    display: block;
                    border-bottom: 1.15px solid $gray;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: math.div(16,16)*1em;
                }
            }
        }
    }
}