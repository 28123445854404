@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/button-animation" as *;
@use "../mixins/custom-scrollbar" as *;

.page-products {
    .page-title {
        margin-bottom: 0;

        h1 {
            color: $silver;
        }
    }

    .category-info-wrapper {
        margin-bottom: min(88px, max(64px, 88/1280*100vw));
    }

    .spare-sub-category {
        margin-bottom: min(88px, max(64px, 88/1280*100vw));
    }

    .sub-category-grid {
        padding-top: min(56px, max(36px, 56/1280*100vw));
        padding-bottom: min(56px, max(36px, 56/1280*100vw));

        @include tablet {
            border-bottom: 1px solid $primary;
        }

        .title {
            font-size: clamp($font-size-12, math.div(14,1280)*100vw, $font-size-14);
            font-weight: $weight-bold;
            line-height: 1.3;
            transition: none;

            a {
                padding: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include button-animation($primary, false);

                @include tablet {
                    padding: 12px;
                }
            }
        }

        .item-grid {
            @include tablet {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: math.div(44,16)*1em math.div(24,16)*1em;
            }

            @include mobile {
                display: flex;
                overflow-x: auto;
                margin-right: -#{$mobile-padding};
                @include custom-scrollbar(46px, false);

                &::-webkit-scrollbar-track {
                    margin-right: $mobile-padding;
                }
            }

            &:after {
                content: none;
            }
        }

        .item-box {
            margin-bottom: 0;

            @include tablet {
                width: 100%;

                * {
                    height: 100%;
                }
            }

            @include mobile {
                white-space: nowrap;
                margin-right: $mobile-padding;
            }
        }
    }

    .side-2 {
        @include tablet {
            width: 28%;
        }

        @include mobile {
            margin-bottom: 0;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 100%;
            left: 0;
            z-index: 100;
            background-color: $gray;
            transform: translateY(0);
            transition: transform 0.5s ease-in-out;
        }
    }

    .center-2 {
        width: 100%;

        @include tablet {
            width: 72%;
            padding-left: 5%;
        }
    }

    .products-container {
        @include tablet {
            margin-top: min(72px, max(42px, math.div(72,1280)*100vw));
        }
    }

    .product-item {
        margin-bottom: min(64px, max(40px, math.div(64,1280)*100vw));
    }

    .product-grid {
        font-size: 1rem;
        margin-bottom: math.div(64,16)*1em;

        @include tablet {
            margin-bottom: math.div(32,16)*1em;
        }

        .column { 
            &.is-6 {
                @include from(768px) {
                    @include mobile {
                        flex: none;
                        width: 50%;
                    }
                }
            }
        }

        .columns {
            @include from(768px) {
                @include mobile {
                    display: flex;
                }
            }
        }
    }

    .page-main-wrapper {
        @include mobile {
            z-index: 11;
            position: relative;
        }
    }

    .category-description, .manufacturer-description {
        margin: math.div(10,14)*1em 0 0;
        text-align: center;
        font-size: $font-size-14;
        color: $primary;

        p {
            text-align: inherit;
            font-size: inherit;
            margin: 0;
        }
    }
}


.active-filters {
    @include mobile {
        overflow: hidden;

        .side-2 {
            transform: translateY(-100%);
        }

        .header {
            z-index: 9;
        }
    }
}

.search-input { 
    margin-bottom: min(56px, max(36px, math.div(56,1280)*100vw));
    padding-top: min(56px, max(36px, math.div(56,1280)*100vw));

    .fieldset {
        margin-bottom: 0;
    }

    .form-fields {
        padding: 0;
    }
}