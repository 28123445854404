﻿@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.hr-line {
    width: 85%;
    margin: 0 auto;
    border-top: 1px solid $beige;

    @include tablet {
        width: 100%;
    }
}
