@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as basic;
@use "../addons/bulma-navigation" as navigation;
@use "../mixins/link" as *;

$navbar-burger-width: math.div(30,16)*1rem;
$navbar-burger-gap-between-lines: 8px;
$navbar-burger-gap-between-lines-mobile: 15px;

.navbar-burger {
    margin-left: 0;
    height: math.div(32,16)*1em;

    @include basic.tablet {
        display: block;
        height: math.div(42,16)*1em;
    }

    @include basic.mobile {
        width: math.div(30,16)*1em;
    }

    span {
        width: $navbar-burger-width;
        left: calc(50% - #{$navbar-burger-width / 2});

        &:nth-child(1){
            top: calc(50% - #{$navbar-burger-gap-between-lines-mobile});
        }
        &:nth-child(2) {
            top: calc(50%);
        }
        &:nth-child(3) {
            top: calc(50% + #{$navbar-burger-gap-between-lines-mobile});
        }

        @include basic.tablet {
            &:nth-child(1){
                top: calc(50% - #{$navbar-burger-gap-between-lines});
            }
            &:nth-child(3) {
                top: calc(50% + #{$navbar-burger-gap-between-lines});
            }
        }
    }

    &.is-active {
        span {
            &:nth-child(1){
                transform: translateY($navbar-burger-gap-between-lines-mobile) rotate(45deg)
            }
            &:nth-child(3) {
                transform: translateY(-$navbar-burger-gap-between-lines-mobile) rotate(-45deg)
            }

            @include basic.tablet {
                &:nth-child(1){
                    transform: translateY($navbar-burger-gap-between-lines) rotate(45deg)
                }
                &:nth-child(3) {
                    transform: translateY(-$navbar-burger-gap-between-lines) rotate(-45deg)
                }
            }
        }
    }

    &:hover {
        background-color: transparent;
    }
}

.navbar-burger-text {
    display: none;
    font-size: $font-size-14;
    font-weight: $weight-600;
    margin-left: math.div(10,14)*1em;
    color: $primary;
    position: absolute;
    right: -#{math.div(55,16)*1rem};
    top: 7px;
}

.burger-button-wrapper {
    position: relative;
    cursor: pointer;
}

.top-menu {
    font-size: $font-size-14;
    letter-spacing: $medium-letter-spacing;

    .top-menu-first-level {
        @include basic.touch {
            &:not(:last-child) {
                border-bottom: 1px solid $whitelight;
            }
        }

        > a {
            @include basic.touch {
                padding: math.div(40,16)*1rem math.div(54,16)*1rem math.div(40,16)*1rem 4px;
            }
        }
    }

    a {
        @include link($primary, $primary, false);
        font-weight: $weight-800;

        @include basic.touch {
            display: block;
        }

        @include basic.desktop {
            font-weight: $weight-600;
        }
    }


    &.notmobile {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include basic.touch {
            display: none;
        }
    }

    &.mobile {
        text-align: left;
        padding: 0 $mobile-padding;

        @include basic.desktop {
            display: none;
        }

        @include basic.mobile {
            border-top: 1px solid $whitelight;
        }

        li {
            position: relative;
            margin: 1px 0;
            background-color: transparent;

            &.active {
                > .sublist-toggle {
                    transform: rotate(180deg);
                }
            }
        }

        .sublist {
            display: none;
            position: static;
        }
    }

    .top-menu-link , li {
        @include basic.desktop {
            &:hover {
                > .sublist {
                    visibility: visible;

                    > ul {
                        opacity: 1;
                        transform: translateY(0);
                    }
        
                    &:after {
                        transform: scaleY(1);
                    }
                }
            }
        }
    }
}

.mobile-menu-wrapper {
    @include basic.mobile {
        border-top: 1px solid $whitelight;
    }
}

.menu-toggle {
    @include basic.desktop {
        display: none;
    }
}

.top-menu-link {
    @include basic.desktop {
        &:not(:first-child) {
            margin-left: 2%;
        }

        &:hover {
            > a {
                font-weight: $weight-800;
                letter-spacing: 1.85px;
            }
        }

        > .sublist {
            border-top: math.div(39,16)*1rem solid transparent;
        }

        .sublist {
            // padding: min(math.div(48,16)*1rem, math.div(48,1400)*100vw);
            // width: min(math.div(414,16)*1rem, math.div(414,1400)*100vw);
            padding: min(math.div(28,16)*1rem, math.div(28,1400)*100vw);
            width: min(math.div(380,16)*1rem, math.div(380,1400)*100vw);
            z-index: 1;
            visibility: hidden;
            display: block;

            a {
                font-size: $font-size-14;
            }

            ul {
                opacity: 0;
                transform: translateY(-25px);
                transition: opacity 0.3s ease-out, transform 0.3s ease-out;
                transition-delay: .3s;
            }

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: 0px 4px 45px 2px rgba($black, 0.06);
                background-color: rgba($color: $whitelight, $alpha: 0.95);
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: -1;
                transform: scaleY(0);
                transform-origin: top;
                transition: transform .4s ease-out;
            }

            .sublist {
                top: -#{math.div(28,1400)*100vw};

                @include basic.from(1400px) {
                    top: -#{math.div(28,16)*1rem};
                }
            }
        }

        &.last-child {
            .sublist {
                .sublist {
                    @include basic.desktop {
                        @include basic.until(1800px) {
                            left: auto;
                            right: 100%;
                        }
                    }
                }
            }
        }
    }
}

.sublist {
    @include basic.touch {
        padding-bottom: math.div(50,16)*1rem;
    }

    a {
        @include link($primary, $primary, true);
        font-weight: $weight-600;
        line-height: 1.3;
        letter-spacing: $basic-letter-spacing;

        @include basic.desktop {
            font-weight: $weight-800;
        }

        @include basic.touch {
            padding: math.div(15,16)*1rem math.div(40,16)*1rem math.div(15,16)*1rem 4px;
        }
    }

    li {
        &:not(:last-child){
            @include basic.desktop {
                margin-bottom: math.div(20,16)*1rem;
            }
        }
    }

    .sublist {
        left: 100%;

        @include basic.touch {
            padding-bottom: math.div(20,16)*1rem;

            a {
                padding-right: 0;
                padding-left: math.div(20,16)*1rem;
            }

            .sublist-toggle {
                display: none;
            }
        }

        .sublist {
            display: none !important;
        }
    }

    .sublist-toggle {
        @include basic.touch {
            width: math.div(35,16)*1rem;
            height: math.div(48,16)*1rem;
            right: -4px;
        }
    }
}

.left-to-right-animation {
    position: fixed;
    width: 100%;
    height: calc(100% - 76px);
    left: 100%;
    bottom: 0;
    background: $beige;
    transform: translateX(0);
    transition: transform .4s ease;
    overflow-y: auto;

    @include basic.tablet {
        height: calc(100% - 112px);
    }

    @include basic.desktop {
        padding-top: min(44px, math.div(44,1400)*100vw);
        transition-duration: .6s;
    }
}

.more-categories-wrapper {
    display: flex;
    justify-content: space-between;
}

.menu-is-active {
    .left-to-right-animation {
        transform: translateX(-100%);
    }
}

.menu-link {
    @include basic.desktop {
        padding: 8px 0;

        > a {
            font-size: clamp($font-size-16, math.div(20,1400)*100vw, $font-size-20);
            font-weight: $weight-600;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                border-left: 1px solid $primary;
                height: math.div(42,16)*1rem;
                position: absolute;
                top: 0;
                bottom: 0;
                left: -#{math.div(24,16)*1rem};
                opacity: 0;
                margin: auto;
            }
        }

        .sublist {
            display: block;
            padding-top: min(52px, math.div(52,1400)*100vw);
            background-color: $beige;
            visibility: hidden;

            a {
                opacity: 0;
                transform: translateX(1.5rem);
                transition: none;

                &:not(:where(.all-products-link-url)) {
                    font-size: clamp($font-size-14, math.div(18,1400)*100vw, $font-size-18);

                    &:hover {
                        text-decoration: none;
                        position: relative;

                        &:before {
                            opacity: 1;
                        }
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        border-left: 1px solid $primary;
                        height: math.div(42,16)*1rem;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -#{math.div(27,16)*1rem};
                        opacity: 0;
                        margin: auto;
                    }
                }
            }

            > ul {
                max-height: min(482px, math.div(482, 1500)*100vw);
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }

            li {
                width: 50%;
            }
        }

        .child {
            margin-bottom: math.div(20,16)*1rem;

            a {
                font-size: clamp($font-size-12, math.div(14,1400)*100vw, $font-size-14);
                font-weight: basic.$weight-bold;
            }

            &.last-child {
                margin-bottom: math.div(32,16)*1rem;
            }
        }

        .parent {
            padding-bottom: math.div(32,16)*1rem;

            &:not(:last-child) {
                margin-bottom: 0;
            }

            &.has-children {
                padding-bottom: math.div(14,16)*1rem;
            }
        }

        > .sublist {
            position: absolute;
            width: 100%;
            left: 0;
            top: 100%;
        }

        &.active {
            > a {
                font-weight: $weight-800;

                &:before {
                    opacity: 1;
                }
            }

            .sublist {
                visibility: visible;

                a {
                    opacity: 1;
                    transform: translateX(0);
                    transition: opacity 277ms ease-in-out, transform 277ms ease-in-out;
                }
            }
        }

        li {
            &:hover {
                > .sublist {
                    display: block;
                }
            }
        }
    }
}

.sublist-toggle {
    @include basic.touch {
        background-image: url('../../images/dropdown-icon-primary.svg');
        background-repeat: no-repeat;
        background-size: 12px 8px;
        background-position: center;
        width: math.div(50,16)*1rem;
        height: math.div(105,16)*1rem;
        position: absolute;
        right: -12px;
        top: 0;
    }    
    
    @include basic.desktop {
        display: none;
    }
}

.all-products-link {
    display: none;
    font-size: $font-size-14;
    text-align: right;
    text-decoration: underline;

    a {
        &:hover {
            color: basic.bulmaLighten($primary, 15%);
        }
    }

    @include basic.desktop {
        display: block;
    }
}

// UPPERCASE MAIN MENU LINKS
// .top-menu-first-level {
//     > a {
//         text-transform: uppercase;
//     }

//     .sublist {
//         &.first-level {
//             > ul > li > a{
//                 text-transform: uppercase;
//             }
//         }
//     }
// }
// .top-menu-link, .menu-link {
//     > a {
//         text-transform: uppercase;
//     }
// }
// .menu-link {
//     .parent {
//         text-transform: uppercase;
//     }
// }