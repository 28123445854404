﻿@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.homepage-banners {
    margin: min(56px, max(24px, 56/1280*100vw)) 0;

    @include from(768px) {
        padding: 0 $mobile-padding;
    }

    .banner-image {
        width: 100%;
        height: clamp(283px, 448/1280*100vw, 448px);

        @include from(768px) {
            max-width: 583px;
        }

        @include tablet {
            pointer-events: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .banner-container {
        display: flex;
        align-items: center;

        @include until(768px) {
            flex-direction: column;
        }
    }

    .column-banner {
        width: 100%;

        @include from(768px) {
            width: 58%;
        }
    }

    .column-text {
        width: 80%;

        @include from(768px) {
            width: 42%;
        }
    }

    &.image-left {
        .column-banner {
            @include from(768px) {
                padding-right: 3%;
            }
        }
    }

    &.image-right {
        .column-banner {
            @include from(768px) {
                padding-left: 3%;
                order: 1;

                .banner-image {
                    float: right;
                }
            }
        }

        .promo-text {
            @include tablet {
                padding-left: 10%;
            }
        }

        .promo-link {
            @include from(768px) {
                text-align: right;
            }
        }
    }

    .promo-text {
        font-size: clamp($font-size-24, 31/1280*100vw, $font-size-31);
        font-weight: $weight-bold;
        color: $primary;
        line-height: 125%;
        padding: 40px 0;
        text-align: left;

        @include from(768px) {
            padding-top: 30px;
            padding-bottom: 30px;
            line-height: 116%;
            margin-bottom: 40px;
            position: relative;

            &:before, &:after {
                content: '';
                display: block;
                width: 100%;
                transition: transform 640ms ease-out;
                transform: scaleX(0);
                position: absolute;
                left: 0;
            }

            &:before {
                border-top: 1.15px solid $primary;
                top: 0;
                transform-origin: top left;
            }

            &:after {
                border-bottom: 1.15px solid $primary;
                bottom: 0;
                transform-origin: top right;
            }
        }
    }

    .promo-link {
        text-align: left;
    }

    &.active {
        .promo-text {
            @include from(768px) {
                &:before, &:after {
                    transform: scaleX(1);
                }
            }
        }
    }
}