@use 'sass:math';
@use "../addons/bulma-basic" as *;

.custom-product-grid {
    margin-bottom: math.div(56,16)*1em;

    .product-item {
        @include tablet {
            font-size: 1rem;
            margin-bottom: math.div(40,16)*1em;
        }
    }

    .slick-dots {
        position: static;
        margin-top: math.div(64,16)*1em;
    }

    
    @include from(375px) {
        @include until(630) {
            .column {
                width: 344px;
            }

            .slick-list {
                overflow: visible;
            }
        }
    }

    @include mobile {
        .columns {
            &:not(.slick-slider) {
                scroll-snap-type: x mandatory;
                overflow-x: scroll;
                display: flex;
                width: 100%;
                flex-wrap: nowrap;
                
                .column {
                    scroll-snap-align: start;
                    min-width: 100%;
    
                    @include from(630px) {
                        min-width: 50%;
                    }
                }
            }
        }
    }
}