@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.slick-slider {
    .slick-dots {
        font-size: 1rem;
        padding: 0 $mobile-padding;

        @include mobile {
            display: flex;
        }

        li {
            margin: 0;
            height: 3px;
            border-bottom: 1px solid rgba($color: $primary, $alpha: 0.6);
            width: 100%;

            @include tablet {
                margin: 0 4px;
                width: math.div(96,16)*1em;
            }

            &.slick-active {
                border-bottom-width: 3px;
                border-color: $primary;
            }

            button {
                width: 100%;
                height: 100%;

                &:before {
                    content: none;
                }
            }
        }
    }

    .slick-arrow {
        width: min(48px, max(22px, math.div(48,1400)*100vw)); 
        height: min(48px, max(22px, math.div(48,1400)*100vw)); 
        border: 0.5px solid $silver;
        background-color: $white;
        z-index: 1;
        top: 87%;

        &:before {
            content: '';
            display: block;
            background-image: url('../../images/previous-next-page.svg');
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 1;
            width: min(13px, max(6px, math.div(13,1400)*100vw));
            height: min(22px, max(10px, math.div(22,1400)*100vw));
            margin: 0 auto;
        }

        &:hover {
            background-color: $silver;

            &:before {
                background-image: url('../../images/previous-next-page-white.svg');
            }
        }
    }

    .slick-prev  {
        left: calc(50% - min(48px, max(22px, math.div(48,1400)*100vw)) - 8px);

        &:before {
            transform: rotate(180deg);
        }
    }

    .slick-next {
        right: calc(50% - min(48px, max(22px, math.div(48,1400)*100vw)) - 8px);
    }
}