@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

$price-font-size: $font-size-24;
$price-sliders-font-size: $font-size-21;

$price-tax-font-size:  $font-size-18;
$price-tax-sliders-font-size:  $font-size-12;

$price-font-size-mobile: $font-size-18;
$price-tax-font-size-mobile:  $font-size-14;

$price-tax-span: $font-size-16;

.prices {
    color: $rough_blue;
    display: flex;
    justify-content: space-around;
    padding: 12px 5px;
    position: relative;

    .prices-container {
        display: flex;
        margin-bottom: 0;
        flex-direction: column;
        padding: 0 3px;
        text-align: center;
    }

    .price-label, label {
        display: none;
    }

    .price-span {
        font-size: clamp($price-tax-font-size, math.div(24,1280)*100vw, $price-font-size);
        font-weight: $weight-800;
        line-height: 22px;
        padding-bottom: math.div(14,16)*1rem;
        margin-bottom: math.div(6,16)*1rem;
        position: relative;

        @include tablet {
            margin-bottom: math.div(9,16)*1rem;
        }

        &:after {
            content: '';
            display: block;
            font-size: 1rem;
            width: math.div(77,16)*1em;
            border-top: 1px solid $beige;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .price-vat-span {
        font-size: clamp($price-tax-font-size-mobile, math.div(18,1280)*100vw, $price-tax-font-size);
        font-weight: $weight-600;
        line-height: 22px;

        @include tablet {
            font-weight: $weight-800;
        }

        span {
            font-size: clamp($price-tax-font-size-mobile, math.div(16,1280)*100vw, $price-tax-span);
            width: auto;
            margin-left: 2px;
            
            @include tablet {
                font-weight: $weight-bold;
            }
        }
    }

    &.slider-prices {
        .price-span {
            font-size: clamp($price-font-size-mobile, math.div(24,1280)*100vw, $price-sliders-font-size);
        }

        .price-vat-span {
            @include tablet {
                font-size: $price-tax-sliders-font-size;
                font-weight: $weight-600;
            }
        }

        &:not(.has-old-price) {
            &:after {
                content: none;
            }
        }
    }

    &.has-old-price {
        @include mobile {
            justify-content: space-between;
        }

        .actual-price, .product-price {
            color: $red;
        }
    }

    > div {
        margin-bottom: 0;
    }

    &:after {
        content: '';
        display: block;
        border-right: 1px solid $beige;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
    }
}

.overview {
    .prices {
        margin-bottom: 0;

        @include from(600px) {
            flex-direction: column;
        }

        @include until(600px) {
            padding-top: 14px;
            padding-bottom: 14px;
        }

        span {
            @include from(600px) {
                width: 50%;
                text-align: center;
            }
        }

        &:after {
            height: calc(100% - 24px);
            top: 12px;
        }

        .prices-container {
            @include from(600px) {
                flex-direction: row;
                align-items: center;
            }
        }

        .price-span {
            @include from(600px) {
                font-weight: $weight-bold;
            }

            @include until(600px) {
                margin-bottom: math.div(12,16)*1rem;
            }
        }

        &:not(.has-old-price) {
            .product-price {
                > span {
                    @include from(600px) {
                        padding: min(18px, max(12px, math.div(18,1280)*100vw));
                    }

                    @include until(600px) {
                        width: auto;
                    }
                }
            }

            @include until(600px) {
                &:after {
                    content: none;
                }
            }
        }

        @include from(600px) {
            .product-price {
                .price-span, .price-vat-span {
                    padding-bottom: 0;
                    margin-bottom: 0;

                    &:after {
                        content: none;
                    }
                }
            }

            .old-product-price {
                .price-vat-span {
                    position: relative;
                    padding-bottom: math.div(14,16)*1rem;
                    margin-bottom: math.div(9,16)*1rem;

                    &:after {
                        content: '';
                        display: block;
                        font-size: 1rem;
                        width: math.div(77,16)*1em;
                        border-top: 1px solid $beige;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }

            &.has-old-price {
                .old-product-price {
                    padding-top: 7px;
                }

                .product-price {
                    padding-bottom: 7px;
                }
            }
        }
    }
}