@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;

.order-review-data, 
.order-details-area, 
.shipment-details-area {
    display: grid;
    gap: math.div(20,16)*1rem;
    margin-bottom: math.div(50,16)*1rem;

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }

    > div {
        padding: math.div(20,16)*1rem;
        background: $white;
    }
}

.opc {
    .checkout-custom-select {
        width: max-content;
        max-width: 100%;
        margin: 0 auto;
        text-align: left;

        @include tablet {
            min-width: math.div(400,16)*1rem;
        }
    }

    select, .select-selected {
        background-color: transparent;
    }

    .step-title {
        border: 1px solid $beige;

        .number {
            border-color: $beige;
        }
    }

    .allow {
        .step-title {
            background-color: $primary;
            border-color: $primary;

            .number {
                background-color: $primary;
                border-color: $white;
            }
        }
    }

    .active {
        .step {
            border: 1px solid $beige;
            padding: math.div(32,16)*1rem math.div(24,16)*1rem;
        }
    }

    .tab-section {
        margin-bottom: math.div(32,16)*1rem;
    }

    .edit-address {
        max-width: math.div(600,16)*1rem;
        margin: 0 auto;
        background-color: transparent;
        padding-top: math.div(28,16)*1rem;
    }

    .section {
        > label {
            margin-bottom: math.div(16,16)*1rem;
        }

        & + .section {
            .edit-address {
                border-top: 1px solid $beige;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: clamp(24px, math.div(40,1280)*100vw, 40px);

        .please-wait {
            width: 100%;
            margin-top: math.div(24,16)*1rem;
        }

        p, button {
            @include from(600px) {
                margin: 0 math.div(8,16)*1rem;
            }
        }

        a {
            @include link($text, bulmaLighten($primary, 15%), false);
            width: auto;
        }

        @include until(600px) {
            * {
                width: 100%;
                margin: math.div(8,16)*1rem 0;
            }
        }
    }

    .method-list {
        max-width: math.div(600,16)*1rem;
        margin: 0 auto;
        text-align: left;
        font-size: $font-size-16;

        li {
            &:not(:last-child) {
                margin-bottom: math.div(24,16)*1rem;
            }
        }
    }

    .method-name {
        input {
            margin-right: math.div(10,16)*1rem;
        }
    }

    .method-description, .payment-description {
        font-size: $font-size-14;
    }

    .shipping-method {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .payment-method {
        .method-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .payment-logo {
            order: 1;
            margin-left: math.div(16,16)*1rem;

            img {
                max-width: math.div(100,16)*1rem;
                max-height: math.div(40,16)*1rem;
            }
        }
    }

    .payment-info{ 
        max-width: math.div(600,16)*1rem;
        margin: 0 auto;

        .info { 
            padding: 0;

            tr {
                text-align: center;
            }
        }
    }

    .order-review-data {
        .title {
            font-size: $font-size-18;

            strong {
                font-weight: $weight-600;
            }
        }   

        .info-list {
            font-size: $font-size-14;
            text-align: left;
        }
    }

    .totals {
        width: math.div(400,16)*1rem;
        background: $white;
        padding: math.div(15,16)*1rem math.div(20,16)*1rem;
        font-size: clamp($font-size-14, math.div(16,1024)*100vw, $font-size-16);

        td {
            padding: math.div(8,16)*1rem 0;
            vertical-align: bottom;
        }

        .cart-total-right {
            text-align: right;
        }

        .order-total {
            color: $rough_blue;

            .cart-total-left {
                font-size: $font-size-18;
            }

            .cart-total-right {
                font-size: $font-size-24;
                font-weight: $weight-600;

                strong {
                    color: $rough_blue;
                    font-weight: 600;
                }
            }
        }
    }

    .terms-of-service {
        @include mobile {
            text-align: left;
        }

        input {
            margin-right: math.div(10,16)*1rem;
        }

        a {
            @include link($primary, $primary, true);
        }
    }
}

.order-completed {
    .title {
        text-align: center;
    }

    .details-link {
        a {
            @include link($primary, $primary, true);
        }
    }

    .buttons {
        text-align: center;
    }
}