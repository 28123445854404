@use "../base/variables" as *;
@use "../mixins/link" as *;

.wishlist-page {
    .cart{ 
        .product {
            width: 30%;
        }
    }

    .share-info {
        overflow-wrap: break-word;

        a {
            @include link($primary, $primary, true)
        }
    }

    .no-data {
        text-align: center;
    }
}