@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;

//404 PAGE
.not-found-page {
    max-width: math.div(600,16)*1rem;
    margin: math.div(60,16)*1rem auto 0;
    text-align: center;

    .error-page-container {
        margin-top: math.div(32,16)*1rem;

        a{
            @include link($primary, bulmaLighten($primary, 15%), true);
            text-decoration: underline;
        }
    }
}

//SITEMAP
.sitemap-page { 
    .entity-body {
        background: $white;

        a {
            @include link($text, $text, true);
        }
    }

    h2 {
        font-size: $font-size-21;
        font-weight: $weight-500;
    }

    .entity {
        li {
            font-size: $font-size-14;
            margin: math.div(10,16)*1rem;

            &:before {
                background-color: $primary;
                top: math.div(10,16)*1rem;
            }
        }
    }
}

//CONTACT
.contact-page {
    form {
        .required {
            display: none;
        }
    }

    table {
        @include mobile {
            display: block;

            tbody, tr, td {
                display: block;
                width: 100% !important;
            }
        }
        
        td {
            @include tablet {
                padding: math.div(10,16)*1rem;
            }

            @include mobile {
                &:not(:first-child) {
                    margin-top: math.div(24,16)*1rem;
                }
            }
        }
    }

    .page-title-outer {
        margin-top: min(64px, max(40px, 64/1280*100vw));
    }

    .contact-info-header {
        font-size: clamp($font-size-24, math.div(28,1280)*100vw, $font-size-31);
        margin-bottom: math.div(32,16)*1rem;
        font-weight: $weight-500;
    }

    .contact-page-inner-wrapper {
        display: grid;
        gap: math.div(32,16)*1rem;

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        p {
            font-size: $font-size-14;
        }

        a {
            @include link($primary, $primary, true);
        }
    }

    .contact-info-title {
        font-size: $font-size-18;
        font-weight: $weight-600;
        display: block;
        margin-bottom: math.div(10,16)*1rem;
    }

    .nested-wrapper {
        margin-top: math.div(32,16)*1rem;
    }

    .second-block {
        @include tablet {
            position: relative;

            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                height: 100%;
                border-left: 1px solid $beige;
            }

            &:before {
                left: -#{math.div(16,16)*1rem};
            }

            &:after {
                right: -#{math.div(16,16)*1rem};
            }
        }
    }
}