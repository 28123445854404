@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;
@use "../mixins/custom-scrollbar" as *;

.filters-title {
    font-size: clamp($font-size-18, math.div(24,1280)*100vw, $font-size-24);
    font-weight: $weight-800;
    color: $primary;
    line-height: 1.2;

    @include tablet {
        font-weight: $weight-bold;
        margin-bottom: 48px;
    }

    @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.filters-button-wrapper {
    font-size: 1rem;
    margin: math.div(42,16)*1em 0;

    .filters-title {
        max-width: 280px;
        margin: 0 auto;
        color: $white;
        background: $silver;
        padding: math.div(17,18)*1em math.div(24,18)*1em;
        justify-content: space-between;

        &::after {
            content: '+';
            display: block;
        }
    }
}

.filters-content-wrapper {
    @include mobile {
        overflow-y: auto;
        height: calc(100% - 213px);
    }
}

.product-filters {
    @include mobile {
        height: 100%;
    }

    .filters-title {
        @include mobile {
            padding-top: math.div(48,18)*1em;
            padding-bottom: math.div(48,18)*1em;
            border-bottom: 1px solid $beige;

            &:before {
                content: 'x';
                margin-right: math.div(15,16)*1em;
                font-size: 1rem;
                padding-bottom: math.div(2,16)*1em;
            }
        }
    }

    .filters-ajax-products-busy {
        position: fixed;
        top: 0;
        left: 0;
    }
}

.product-filter{ 
    border-bottom: 1px solid $beige;

    .filter-title {
        font-size: clamp($font-size-14, math.div(16,1280)*100vw, $font-size-16);
        font-weight: $weight-600;
        line-height: 1.5;
        color: $primary;
        background-color: transparent;
        margin: 0;
        padding: math.div(40,14)*1em 12%;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            font-weight: $weight-bold;
            padding: math.div(32,16)*1em math.div(30,16)*1em math.div(32,16)*1em math.div(14,16)*1em;
        }

        &:after {
            content: '+';
            display: block;
            line-height: 1;

            @include mobile {
                font-size: 1rem;
            }
        }

        span {
            padding-right: 5%;
        }
    }

    .filter-content {
        display: none;
        // background-color: $beige;
        font-size: 1rem;
        padding: math.div(32,16)*1em 12%;

        @include tablet {
            padding: math.div(24,16)*1em math.div(16,16)*1em;
        }
    }

    &.active {
        .filter-title {
            &:after {
                content: '-';
            }
        }
    }

    .group {
        font-size: 1rem;
        max-height: math.div(250,16)*1em;
        overflow-y: auto;
        padding-bottom: 3px;
        @include custom-scrollbar(4px);

        .item {
            padding-left: 0;
            font-size: clamp($font-size-14, math.div(16,1280)*100vw, $font-size-16);
            color: $primary;

            @include tablet {
                &:hover {
                    color: bulmaLighten($primary, 15%);
                }
            }

            @include mobile {
                font-weight: $weight-600;
            }

            & + .item {
                padding-top: 20px;

                @include tablet {
                    padding-top: 16px;
                }
            }

            input {
                min-width: 12px;
                height: 12px;
                opacity: 0;
                visibility: hidden;

                &:checked {
                    & + label {
                        &:before {
                            background-color: $primary;
                        }
                    }
                }
            }

            label {
                padding-left: 0;
                line-height: 1.2;
                margin-left: -12px;

                &:before {
                    content: '';
                    display: block;
                    min-width: 12px;
                    height: 12px;
                    border: 1px solid $primary;
                    margin-right: 21px;
                    pointer-events: none;
                }
            }

            .attribute-square {
                display: none !important;
            }
        }
    }

    .apply-price {
        text-align: center;
        text-decoration: underline;
        font-size: $font-size-16;
        font-weight: $weight-bold;
        margin-top: math.div(24,16)*1em;
        color: $primary;

        span {
            cursor: pointer;
        }
    }

    .selected-price-range {
        padding: 0;
        margin-bottom: math.div(32,16)*1em;
        display: flex;
        align-items: center;
        justify-content: center;

        .to-label {
            font-size: clamp($font-size-14, math.div(16,1280)*100vw, $font-size-16);
            font-weight: $weight-600;
            padding: 0 math.div(12,16)*1em;
            color: $primary;

            @include tablet {
                font-weight: $weight-bold;
            }
        }

        input {
            font-size: $font-size-14;
            width: math.div(80,14)*1em;
            height: 30px;
            background-color: transparent;
            border-color: $primary;
            padding: 0 math.div(15,14)*1em 0 math.div(6,14)*1em;
            background-image: url("../../images/euro-symbol.svg");
            background-size: 9px 11px;
            background-repeat: no-repeat;
            background-position: math.div(63,14)*1em;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    &.price-range-filter {
        .filter-content {
            @include tablet {
                padding-left: math.div(32,16)*1em;
                padding-right: math.div(32,16)*1em;
            }
        }

        .ui-slider {
            border: 0;
            height: 2px;
            background-color: $whitelight;

            .ui-slider-range {
                background-color: $primary;
            }

            .ui-slider-handle {
                width: 5px;
                height: 10px;
                top: -4px;
                margin-left: -2px;
                background-color: $primary;
                border: 0;
                border-radius: 0;

                &:focus-visible {
                    outline-style: solid;
                    outline-color: $primary;
                }
            }
        }
    }
}

.clear-filters {
    font-size: clamp($font-size-12, math.div(16,1280)*100vw, $font-size-16);
    font-weight: $weight-bold;

    @include tablet {
        margin-bottom: 22px;
        padding-left: 11%;
    }

    &.hide{
        display: none;
    }

    &.show{
        display: block;
    }

    button {
        border: 0;
        background-color: transparent;
        color: $silver;
        font-weight: 700;
        font-size: $font-size-14;
        /*@include link($silver, $silver, false);*/

        @include tablet {
            font-size: $font-size-16;

            &:before {
                content: 'x';
                margin-right: math.div(10,16)*1em;
                font-size: 1rem;
            }
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    @include mobile {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: math.div(20,12)*1em;
        padding: math.div(20,12)*1em $mobile-padding;
        background-color: $gray;
        box-shadow: 0px -1px 10px 0px $beige;
    }

    .clear-button {
        @include mobile {
            border: 1px solid $silver;
            text-align: center;
            letter-spacing: $medium-letter-spacing;
        }
    }

    .apply-button {
        border: 0;
        background-color: $silver;
        color: $white;
        letter-spacing: $medium-letter-spacing;
    }
}

.mobile-filte-button {
    @include mobile {
        font-size: $font-size-12;
        font-weight: $weight-bold;
        height: math.div(55,12)*1em;
        line-height: math.div(54,12)*1em;
    }
}