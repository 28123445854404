﻿@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.homepage-usp-wrapper {
    background: $gray;
    box-shadow: 0px 4px 45px 15px rgba($color: $black, $alpha: 0.05);
    // padding-top: min(72px, max(56px, 72/1280*100vw));
    // padding-bottom: min(72px, max(56px, 72/1280*100vw));
    padding-top: math.div(56,16)*1rem;
    padding-bottom: math.div(56,16)*1rem;
}

.usps-container {
    border: 1.15px solid $silver;
    font-size: $font-size-12;
    padding: math.div(2,12)*1em math.div(33,12)*1em;
    color: $silver;

    @include tablet {
        padding: math.div(20,12)*1em;
    }

    .column {
        position: relative;

        &:not(:first-child) {
            @include tablet {
                &:before {
                    content: '';
                    display: block;
                    height: 100%;
                    position: absolute;
                    border-left: 1.15px solid $silver;
                    top: 0;
                    left: 0;
                    transform: scaleY(0);
                    transition: transform 640ms ease-out;
                }
            }

            @include mobile {
                .usp-box {
                    border-top: 1.15px solid $silver;
                }
            }
        }

        &:nth-child(even) {
            @include tablet {
                &:before {
                    transform-origin: top left;
                }
            }
        }

        &:nth-child(odd) {
            @include tablet {
                &:before {
                    transform-origin: bottom left;
                }
            }
        }
    }

    &.active {
        .column {
            &:not(:first-child) {
                @include tablet {
                    &:before {
                        transform: scaleY(1);
                    }
                }
            }
        }
    }
}

.usp-box {
    line-height: math.div(18,12)*1em;
    padding: math.div(36,12)*1em 0;

    @include tablet {
        padding-top: math.div(16,12)*1em;
        padding-bottom: math.div(16,12)*1em;
    }

    img {
        display: block;
        margin: 0 auto math.div(24,12)*1em;
        transition: transform .2s ease-out;
    }

    &:hover {
        img {
            transform: scale(1.3);
        }
    }
}

.usp-title {
    letter-spacing: $large-letter-spacing;
    text-transform: uppercase;
    font-weight: $weight-bold;
}

.usp-description {
    margin-top: math.div(10,12)*1em;

    @include tablet {
        font-weight: $weight-600;
    }

    @include mobile {
        font-size: $font-size-14;
        margin-top: math.div(10,14)*1em;
    }
}

.tel-description {
    @include tablet {
        letter-spacing: $large-letter-spacing;
    }
}
