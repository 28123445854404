@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/custom-scrollbar" as *;
@use "../mixins/link" as *;

.flyout-cart {
    background-color: transparent;
    border-top: math.div(42,16)*1rem solid transparent;
    top: calc(100% - 5px);
    width: math.div(350,16)*1rem;

    .mini-shopping-cart {
        background: $whitelight;
        box-shadow: 0px 4px 45px 2px rgb($black, .06);

        .items {
            max-height: math.div(300,16)*1rem;
            overflow-y: auto;
            border-top: 1px solid $beige;
            border-bottom: 1px solid $beige;

            @include custom-scrollbar(10px);
        }

        .name {
            margin-bottom: 5px;
            line-height: 1;

            a {
                @include link($primary, $primary, true);
                font-size: $font-size-14;
                line-height: 1.2;
            }
        }

        .picture {
            min-width: math.div(80,16)*1rem;

            a{
                background-color: $white;
            }

            img {
                max-height: 90%;
            }
        }

        .item {
            display: flex;
        }

        .product {
            padding-left: math.div(10,16)*1rem;
        }
    }

    .buttons {
        button {
            width: 100%;

            &:first-child {
                margin-bottom: math.div(10,16)*1rem;
            }
        }
    }

    &.active {
        @include tablet {
            display: block;
        }
    }
}