@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;

$account-pages-breakpoint: 1001px; //nopcommerce's breakpoint

.html-account-page {
    .side-2 {
        @include until($account-pages-breakpoint) {
            margin-bottom: math.div(24,16)*1rem;
        }
    }
}

.block-account-navigation {
    min-height: auto;

    @include until($account-pages-breakpoint) { 
        padding: math.div(8,16)*1rem;
        border: 1px solid $primary;
    }

    .title {
        font-size: $font-size-20;
        background-position-y: center;

        @include from($account-pages-breakpoint) {
            display: none;
        }

        strong {
            font-weight: $weight-600;
        }
    }

    .list {
        @include from($account-pages-breakpoint) {
            padding-top: math.div(120,16)*1rem;
        }

        a {
            @include link($text, $primary, true);
        }

        .active {
            > a {
                color: $primary;
                font-weight: $weight-bold;
            }
        }

        li {
            padding: math.div(8,16)*1rem 0;

            &:first-child {
                @include until($account-pages-breakpoint) {
                    padding-top: math.div(20,16)*1rem;
                }
            }
        }
    }
}

.account-page {
    .page-title {
        h1 {
            font-size: clamp($font-size-24,math.div(31,1280)*100vw,$font-size-31);
        }
    }

    .no-data {
        text-align: center;
        margin-bottom: math.div(40,16)*1rem;
    }

    &.address-edit-page,
    &.change-password-page {
        .required {
            display: none;
        }

        .inputs {
            margin-bottom: 0;
        }
    }

    &.address-list-page,
    &.order-list-page {
        .buttons {
            margin-bottom: 0;
            text-align: left;
        }

        .info {
            font-size: $font-size-14;
        }
    }

    &.address-list-page {
        .name {
            font-size: $font-size-16;
        }

        .address-item {
            padding: math.div(20,16)*1rem;
            border: 1px solid $primary;
            width: 100%;
        }

        .delete-address-button {
            position: absolute;
            top: math.div(20,16)*1rem;
            right: math.div(20,16)*1rem;
            font-size: 0;
            background-image: url('../../images/close.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: math.div(15,16)*1rem;
            height: math.div(15,16)*1rem;
            padding: 0;
            border: 0;

            &:before{
                content: none;
            }
        }
    }

    &.change-password-page {
        .form-fields {
            display: grid;
            gap: math.div(20,16)*1rem;
            margin-bottom: math.div(40,16)*1rem;
        }
    }

    &.order-list-page {
        .order-item {
            padding: math.div(20,16)*1rem;
            border: 1px solid $primary;
            width: 100%;
        }

        .info {
            span {
                font-weight: $weight-600;
            }
        }
    }
}

.order-details-page {
    .page-title {
        a {
            margin: 0 clamp(8px, math.div(16,1280)*100vw, 16px);
        }
    }

    .order-number {
        font-size: $font-size-18;
    }

    .order-overview-content {
        font-size: $font-size-14;
    }

    .order-total {
        strong {
            color: $rough_blue;
        }
    }

    .info-list {
        font-size: $font-size-14;
    }

    .product-unit-price,
    .product-subtotal {
        color: $rough_blue;
        font-weight: $weight-600;
    }

    .total-info {
        padding: math.div(15,16)*1rem math.div(20,16)*1rem;
        background: $white;

        @include mobile {
            font-size: $font-size-14;
        }

        td {
            padding: math.div(8,16)*1rem;
            vertical-align: bottom;
        }
    }

    .cart-total-right {
        strong {
            color: $rough_blue;
            font-size: $font-size-18;
        }
    }

    .totals {
        float: right;
        width: math.div(400,16)*1rem;
        max-width: 100%;
    }

    .re-order-button {
        width: 100%;
    }
}

.edit-address {
    display: grid;
    gap: math.div(20,16)*1rem;
    margin-bottom: math.div(40,16)*1rem;

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }

    .email-input {
        @include tablet {
            grid-column: 1 / -1;
        }
    }

    .required {
        display: none;
    }
}