@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/button-animation" as *;

.main-slider {
    .slide-content {
        width: 100%;
        height: math.div(637, 16)*1rem; 
        position: relative;
        display: flex;
        align-items: flex-end;

        @include mobile {
            justify-content: center;
            padding-bottom: math.div(112, 16)*1em;
        }

        @include tablet {
            align-items: center;
            padding-top: math.div(111, 16)*1em;
            height: max(600px, 82vh);
            max-height: 681px;
        }

        picture{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            img{
                width: 100%;
                height: 100%;
                object-fit:cover;
            }
        }

        .slider-info-container {
            .slider-info {
                width: min(500px, 90%);
                color: $primary;

                @include tablet {
                    font-size: 1rem;
                    padding-bottom: math.div(24,16)*1em;
                }

                @include mobile {
                    margin: 0 auto;
                    text-align: center;
                }
            }

            .info-main {
                font-size: clamp($font-size-40, math.div(61,1280)*100vw, $font-size-61);
                line-height: 1.1;
                font-weight: $weight-800;
            }

            .info-sub {
                font-size: clamp($font-size-14, math.div(16,1280)*100vw, $font-size-16);
                line-height: 1.5;
                font-weight: $weight-600;
                margin-top: math.div(13,14)*1em;

                @include tablet {
                    font-weight: $weight-bold;
                    margin-top: math.div(24,16)*1em;
                }
            }
        }

        .button-1 {
            margin-top: min(40px, max(32px, math.div(40,1280)*100vw));
            @include button-animation($primary, false, transparent);
        }
    }

    .slick-dots {
        bottom: min(64px, max(48px, math.div(64,1280)*100vw));
    }
}