﻿@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;

.product-item {
    text-align: center;

    .details {
        padding-top: math.div(16,16)*1em;
        background-color: $whitelight;
        border: 1px solid $beige;
    }

    .wishlist-button {
        font-size: 1rem;
        text-align: right;
        padding-right: math.div(18,16)*1rem;
        line-height: 1;
    }

    .product-title {
        font-size: $font-size-18;
        font-weight: $weight-800;
        line-height: 1.3;
        height: math.div(50,18)*1em;
        margin-bottom: math.div(14,18)*1em;
        overflow: hidden;

        @include tablet {
            margin-bottom: math.div(8,18)*1em;
        }

        a {
            @include link($primary, $primary, true);
            max-width: 72%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            margin: 0 auto;
        }
    }

    .manufacturers {
        font-weight: $weight-600;
        font-size: $font-size-14;
        line-height: 1.5;
        padding-bottom: 18px;
        height: 38px;
        border-bottom: 1px solid $beige;
        overflow: hidden;

        @include tablet {
            font-weight: $weight-500;
            font-size: $font-size-13;
        }

        a {
            @include link($rough_blue, $rough_blue, true)
        }
    }

    .product-box_picture {
        font-size: 1rem;
        padding: math.div(32,16)*1em 0;
        border-bottom: 1px solid $beige;
        background-color: $white;

        .picture {
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 15px;
        }

        img {
            max-height: 200px;
            display: block;
            width: auto;
        }
    }

    .product-box_prices {
        border-bottom: 1px solid $beige;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        background-color: #F2F2F2;
    }

    .product-box-add-to-cart-button {
        font-size: $font-size-12;
        margin-top: math.div(8,12)*1em;
        letter-spacing: $large-letter-spacing;
        height: math.div(54,12)*1em;
        width: 100%;

        @include tablet {
            height: math.div(50,12)*1em;
        }
    }
}
