@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.tabs-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    grid-auto-flow: dense;

    @include tablet {
        // grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
        grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));

        &.has-1-tabs {
            grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        }

        &.has-2-tabs {
            grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        }

        &.has-3-tabs {
            grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
        }

        &.has-4-tabs {
            grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
        }
    }

    @include mobile {
        gap: 8px;
    }
}

.tab-title {
    width: 100%;
    display: flex;
    align-items: center;
    height: min(77px, max(64px, math.div(77,1400)*100vw));
    font-size: $font-size-12;
    font-weight: $weight-bold;
    letter-spacing: $large-letter-spacing;
    color: $primary;
    cursor: pointer;
    border: 1px solid $beige;
    padding: 0 math.div(28,12)*1em;

    &:after {
        content: '';
        display: block;
        background-image: url('../../images/dropdown-icon-primary.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 12px;
        height: 7px;
    }

    &.active {
        background-color: $primary;
        color: $beige;

        &:after {
            background-image: url('../../images/dropdown-icon-primary-white.svg');
        }
    }

    @include tablet {
        justify-content: center;
        padding: 0 10px;

        &:after {
            content: none;
        }
    }

    @include mobile {
        justify-content: space-between;
    }

    &:not(:first-child) {
        @include tablet {
            border-left: 0;
        }

        @include mobile {
            margin-top: 8px;
        }
    }
}

.tab-content {
    display: none;
    grid-column: 1 / -1;
    border: 1px solid $beige;
    text-align: left;
    
    @include tablet {
        border-top: 0;
    }

    &.active {
        display: block;
    }

    .tab-content-content {
        &:not(.product-specs-box) {
            padding: min(56px, max(28px, math.div(56,1400)*100vw)) min(86px, max(28px, math.div(86,1400)*100vw));
        }
    }
}