@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;

.product-selectors {
    display: flex;
    justify-content: space-between;
    color: $primary;

    @include mobile {
        font-size: 1rem;
        justify-content: center;
        margin-top: math.div(64,16)*1em;
        padding-bottom: math.div(64,16)*1em;
    }

    span {
        font-size: clamp($font-size-14, math.div(16,1280)*100vw, $font-size-16);
        margin-right: 15px;

        @include mobile {
            font-weight: $weight-600;
        }
    }

    .custom-select {
        @include mobile {
            z-index: 10;
        }
    }

    select, .select-selected {
        font-size: clamp($font-size-12, math.div(16,1280)*100vw, $font-size-16);
        font-weight: $weight-800;
        color: $primary;
        background-color: transparent;
        border-color: $primary;
        min-width: 66px;
        padding: 0 math.div(12,16)*1em 0 math.div(16,16)*1em;
        white-space: nowrap;

        @include tablet {
            height: 28px;
        }

        &:focus-within {
            outline-color: $primary;
        }
    }

    .custom-option {
        padding-left: math.div(16,16)*1em;
        padding-right: math.div(16,16)*1em;
    }

    .product-sorting {
        display: flex;
        align-items: center;
        max-width: 100%;

        select, .select-selected {
            @include tablet {
                min-width: 294px;
            }

            @include mobile {
                max-width: 400px;
                height: 51px;
            }
        }
    }

    .product-page-size {
        display: flex;
        align-items: center;

        @include mobile {
            display: none;
        }
    }
}