@use "../base/variables" as *
    
=button-animation($background, $filled:true, $background-hover: $whitelight)
    border: 1px solid $background
    position: relative
    z-index: 1
    overflow: hidden
    transition: color .27s ease-in-out

    &:before 
        content: ''
        display: block
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        z-index: -1

    @if $filled
        background-color: $background
        color: $whitelight

        &:before 
            background-color: $background-hover
            transform: translateY(-105%)

    @else
        background-color: $background-hover
        color: $background

        &:before 
            background-color: $background
            transform: translateY(105%)


    &:before
        transition: transform .27s ease-in-out
        // transform: translateY(100%)

    &:hover 
        &:before
            transform: translateY(0)
        
        @if $filled
            color: $background
        @else 
            color: $whitelight
    