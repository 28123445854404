﻿@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as basic;
@use "../addons/bulma-essentials" as essentials;
@use "../mixins/link" as *;

html {
    min-width: 100%;
}

body {
    max-width: 100%;
    overflow-x: hidden;
    font-weight: basic.$weight-normal;
    font-style: normal;
    line-height: 24px;    
    letter-spacing: $basic-letter-spacing;

    .ajax-loading-block-window {
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        margin: 0;
        background-color: rgba($black,0.1);
    }
}

h1, h2, h3, h4, h5, h6 {
    color: $primary;
}

a {
    color: inherit;
    cursor: pointer;
}

a img {
    opacity: 0.99; /*firefox scale bug fix*/
}

table {
    width: 100%;
    border-collapse: collapse;
}

.master-wrapper-content {
    position: relative;
    z-index: 0;
    width: 90%;
    margin: 0 auto;
}

.master-column-wrapper {
    position: relative;
    z-index: 0;
}

.master-column-wrapper:after {
    content: "";
    display: block;
    clear: both;
}

.center-1 {
    margin: 0 0 100px;
}

.center-2, .side-2 {
    margin: 0 0 50px;
}

.side-2:after {
    content: "";
    display: block;
    clear: both;
}

.page {
    min-height: 200px;
    // text-align: center;
}

.page-title {
    padding-bottom: min(36px, max(30px, 36/1280*100vw));
    margin-bottom: min(56px, max(36px, 56/1280*100vw));
    text-align: center;

    @include basic.tablet {
        border-bottom: 1px solid $primary;
    }

    @include  basic.mobile {
        position: relative;

        &:after {
            content: '';
            display: block;
            width: calc(100% + 2 * #{$mobile-padding});
            border-bottom: 1px solid $beige;
            position: absolute;
            bottom: 0;
            left: -#{$mobile-padding};
        }
    }

    h1, h2 {
        font-size: clamp($font-size-24, math.div(40,1280)*100vw, $font-size-40);
        font-weight: $weight-800;
        line-height: 1.25;

        @include basic.tablet {
            line-height: 1.1;
        }
    }
}

.section-title {
    margin-top: min(64px, max(40px, math.div(64,1280)*100vw));
    margin-bottom: min(48px, max(40px, math.div(48,1280)*100vw));
    font-size: clamp($font-size-24, math.div(40,1280)*100vw, $font-size-40);
    line-height: 1.1;
    font-weight: basic.$weight-bold;
    color: $silver;
    text-align: center;

    @include basic.tablet {
        font-weight: $weight-800;
    }
}

.page:after,
.page-title:after,
.page-body:after {
    content: "";
    display: block;
    clear: both;
}

.center-1 .title {
    text-align: center;
    font-size: $font-size-24;
    line-height: normal;
    color: $primary;
    text-align: left;
}

.buttons {
    margin: 0 0 30px;
}

.link-rss {
    display: none;
    width: 24px;
    height: 24px;
    background: url('../../images/rss.png') center no-repeat;
    font-size: 0 !important;
}

.category-description ul,
.manufacturer-description ul,
.full-description ul,
.topic-block ul,
.topic-page ul,
.post-body ul {
    margin: 12px 0;
    padding: 0 0 0 36px;
    list-style: disc;
}

.category-description ol,
.manufacturer-description ol,
.full-description ol,
.topic-block ol,
.topic-page ol,
.post-body ol {
    margin: 12px 0;
    padding: 0 0 0 36px;
    list-style: decimal;
}

.category-description p,
.manufacturer-description p,
.full-description p,
.topic-block p,
.topic-page p,
.post-body p,
.news-body p {
    margin: 10px 0;
    text-align: justify;
}

.flyout-cart {
    display: none;
}

.silver-text{
    color: $silver;
}

.mobile-padding {
    padding: 0 $mobile-padding;
}

.page-main-wrapper {
    overflow: hidden;
}

.container {
    .columns {
        margin-bottom: 0;
        margin-top: 0;
    }

    .column {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.breadcrumb {
    font-size: $font-size-12;
    font-weight: basic.$weight-bold;
    line-height: 1.5;
    text-transform: uppercase;
    margin-bottom: min(44px, max(30px, 44/1280*100vw));
    color: $rough_blue;

    li {
        display: inline;
    }

    a{
        @include link($rough_blue, $rough_blue, true)
    }

    .current-item {
        color: $primary;
    }

    .delimiter {
        margin: 0 math.div(4,12)*1em 0 math.div(2,12)*1em;

        @include basic.tablet {
            margin: 0 math.div(6,12)*1em 0 math.div(4,12)*1em;
        }
    }
}

.mobile-full-width-border {
    @include basic.mobile {
        position: relative;

        &:after {
            content: '';
            display: block;
            width: calc(100% + 2 * #{$mobile-padding});
            border-bottom: 1px solid $beige;
            position: absolute;
            bottom: 0;
            left: -#{$mobile-padding};
        }
    }
}

.main-slider {
    .slick-dotted{
        &.slick-slider {
            margin-bottom: 0;
        }
    }
}

.slider-items-wrapper {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;
    width: 100%;

    &:not(.slick-slider) {
        .slider-item {
            min-width: 100%;
        }
    }

    @include basic.tablet {
        overflow-x: hidden;
    }

    .slider-item {
        scroll-snap-align: start;
    }
}

.active-fixed-wrapper {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.content {
    color: $gray2;
    font-size: clamp($font-size-14, math.div(16,1280)*100vw, $font-size-16);

    * {
        font-family: $family-sans-serif !important;
    }

    h1 {
        font-size: clamp($font-size-24, math.div(31,1400)*100vw, $font-size-31);
        color: $silver;
        font-weight: basic.$weight-bold;
        margin-bottom: math.div(32,16)*1rem;

        @include basic.tablet {
            font-weight: $weight-800;
        }
    }

    h2, h3, h4, h5, h6 {
        color: $primary;
        font-weight: $weight-800;

        @include basic.tablet {
            font-weight: basic.$weight-bold;
        }
    }

    p,
    dl,
    ol,
    ul,
    blockquote,
    pre,
    table {
        &:not(:last-child) {
            margin-bottom: math.div(10,16)*1rem;
        }
    }

    a {
        @include link($primary, basic.bulmaLighten($primary, 15%), true);
        text-decoration: underline;
    }

    strong {
        color: inherit;
        font-weight: $weight-800;
    }

    ul {
        padding-left: 22px;

        @include basic.tablet {
            padding-left: 25px;
        }
    }

    table {
        color: $gray2;

        td, th {
            background-color: transparent;
            border: 0;
            padding: 22px;
            line-height: 1.5;
            width: 50%;

            @include basic.tablet {
                padding: math.div(24,16)*1rem;
            }
        }

        tr {
            &:not(:last-child) {
                border-bottom: 1px solid $beige;
            }
        }

        .spec-name {
            font-weight: basic.$weight-bold;

            @include basic.mobile {
                border-right: 1px solid $beige;
            }

            @include basic.tablet {
                padding-left: 18%;
                width: 55%;
            }
        }

        .spec-value {
            @include basic.mobile {
                font-weight: $weight-600;
            }

            @include basic.tablet {
                width: 45%;
            }
        }
    }

    &.page-body {
        .table-bordered {
            border: 1px solid $beige;

            td {
                border: 1px solid $beige;
            }
        }

        table {
            td {
                width: auto;
            }

            td, th {
                padding: math.div(12,16)*1rem;
            }

            tr {
                border: 0;
            }

            @include basic.mobile {
                border: 0;
                overflow-x: auto;
                display: block;
                width: fit-content;
                max-width: 100%;
                height: auto !important;

                td {
                    min-width: 200px;
                }
            }
        }
    }
}

.topic-block-body {
    font-size: $font-size-14;
}

.bar-notification-container {
    text-align: center;
    
    .content {
        color: inherit;
    }

    .bar-notification{ 
        .close {
            @include basic.mobile {
                width: 25px;
                height: 25px;
                margin: 10px;
            }
        }
    }
}

.custom-cursor {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    cursor: none;
    z-index: 1000;
    transition: transform 50ms ease-out;

    &__view {
        transform: translate(-50%,-50%) scale(0);
        width: math.div(50,16)*1rem;
        height: math.div(50,16)*1rem;
        border-radius: 50%;
        background: $primary;
        font-size: $font-size-12;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform .3s ease-in-out;

        &.cursor__view--anim {
            transform: translate(-50%,-50%) scale(1);
        }
    }

    @include basic.touch {
        display: none;
    }
}