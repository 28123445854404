@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;
@use "../mixins/custom-scrollbar" as *;

$header-links-gap: 48;
$header-links-gap-mobile: math.div($header-links-gap, 2);
$header-contianer: 1303px;

.header {
    background-color: $beige;
    font-size: 1rem;
    // margin-bottom: min(64px, max(40px, math.div(64,1204)*100vw));
    margin-bottom: min(44px, max(40px, math.div(64,1204)*100vw));
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;

    @include mobile {
        z-index: 12;
    }

    .header-wrapper {
        padding: min(40px, max(26px, math.div(40,1280)*100vw)) 0 min(32px, max(18px, math.div(32,1280)*100vw));
        max-width: $header-contianer;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-logo {
        @include tablet {
            min-width: math.div(184,16)*1rem;
            line-height: 1;
        }

        @include mobile {
            padding-right: math.div($header-links-gap-mobile,16)*1em;
        }

        img {
            max-height: min(40px, max(28px, math.div(40,1204)*100vw));
            display: block;
        }
    }

    .header-menu {
        @include tablet {
            width: 100%;
            padding: 0 4%;
        }

        @include mobile {
            order: 1;
            margin-left: 7%;
        }
    }

    .header-lower {
        display: flex;
        align-items: center;
        z-index: 10;

        @include tablet {
            position: relative;
        }

        @include mobile {
            margin-left: auto;
        }

        img {
            min-width: math.div(17,16)*1rem;
        }
    }

    .header-links {
        ul {
            display: flex;
            align-items: center;
        }

        li {
            line-height: 1;
            font-size: 1rem;
            margin-left: math.div($header-links-gap-mobile,16)*1em;

            @include tablet {
                margin-left: math.div($header-links-gap,16)*1em;
            }
        }

        a {
            display: flex;
            align-items: center;
        }
    }

    .header-links-wrapper {
        display: flex;
        align-items: center;
    }

    .language-selector {
        margin-left: math.div($header-links-gap-mobile,16)*1em;

        @include tablet {
            margin-left: math.div($header-links-gap,16)*1em;
        }

        @include mobile {
            display: none;
        }

        select, .select-selected {
            min-width: auto;
            padding: 0;
            height: auto;
            background: transparent;
            font-size: 1rem;
            font-weight: $weight-bold;
            letter-spacing: $medium-letter-spacing;
            color: $primary;
            border-color: transparent;
            min-width: 43px;
        }

        .custom-option {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .cart-qty {
        font-size: $font-size-10;
        font-weight: $weight-800;
        color: $white;

        @include tablet {
            font-size: $font-size-14;
            font-weight: $weight-600;
            color: $primary;
            margin-left: 3px;
            white-space: nowrap;

            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }

        @include mobile {
            position: absolute;
            bottom: 4px;
            z-index: 1;
            color: white;
            margin: 0 auto;
            right: 0;
            left: 0;
        }
    }

    .ico-cart {
        @include mobile {
            position: relative;

            img {
                min-width: math.div(21,16)*1rem;
            }
        }
    }

    .open-menu-languages {
        display: none;

        @include mobile {
            padding: math.div(32,16)*1rem 0;
        }

        .language {
            @include link($primary, $primary, true);
            padding: math.div(8,14)*1em math.div(26,14)*1em;
            letter-spacing: $large-letter-spacing;
            font-size: $font-size-14;
            font-weight: $weight-500;

            @include desktop {
                padding: math.div(14,14)*1em math.div(47,14)*1em;
                font-weight: $weight-600;
            }

            &:not(:first-child) {
                border-left: 1px solid $whitelight;

                @include desktop {
                    border-color: $primary;
                }
            }

            &.selected {
                font-weight: $weight-800;
                text-decoration: none;
                cursor: default;
            }
        }
    }

    &.menu-is-active {
        .top-menu-link, .search-box, .header-links, .language-select {
            opacity: 0;
            visibility: hidden;
        }

        @include desktop {
            .header-links-wrapper, .header-lower {
                position: static;
            }

            .header-wrapper {
                position: relative;
            }
        }

        .top-menu {
            &.notmobile {
                justify-content: flex-end;
            }
        }

        .navbar-burger-text {
            display: block;
        }

        .open-menu-languages {
            display: flex;
            align-items: center;
            justify-content: center;

            @include tablet {
                position: absolute;
                left: 50%;
                top: 0;
                bottom: 0;
                transform: translateX(-50%);
            }
        }
    }
}

.html-home-page {
    .header {
        margin-bottom: 0;
    }
}

.small-search-box-form {
    display: flex;
    align-items: center;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 100%;

    @include tablet {
        right: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        width: min(820px, math.div(820,1350)*100vw);
        transform: scaleX(0);
        transition: transform .4s ease-out;
        transform-origin: top right;
    }

    @include mobile {
        left: 0;
        padding: 0 $mobile-padding $mobile-padding;
        background-color: $beige;
        transform: scaleY(0);
        transition: transform .2s ease-out;
        transform-origin: top;
    }

    .search-box-button {
        display: none;
        // background-color: transparent;
        // border: 0;
        // font-size: 0;
        // width: 17px;
        // height: 18px;
        // background-image: url('../../images/search-icon.svg');
        // background-size: contain;
        // background-repeat: no-repeat;
        // visibility: hidden;
        // opacity: 0;
    }

    .search-box-text {
        font-size: 1rem;
        width: 100%;
        height: 45px;
        background-color: $beige;
        border: 0;
        border-bottom: 1px solid $primary;

        @include tablet {
            margin-right: math.div(12,16)*1em;
        }

        &:focus-visible, &:focus {
            border-bottom-color: transparent;
            outline-offset: -1px;
        }
    }
}

.search-box {
    .ui-autocomplete {
        width: 100% !important;
        max-height: math.div(450,16)*1rem;
        overflow-y: auto;

        @include custom-scrollbar(4px); 

        @include tablet {
            width: min(808px, math.div(808,1440)*100vw) !important;
        }

        &.ui-widget {
            border: 0;
            box-shadow: 0px 4px 45px 2px rgba($black, 0.06);

            @include tablet {
                left: auto !important;
                right: calc(100% + 12px) !important;
                top: calc(100% + 9px) !important;
            }

            @include mobile {
                left: 0 !important;
                top: calc(100% + 63px) !important;
            }

            li {
                border-color: $beige;
            }
        }

        .ui-menu-item-wrapper {
            display: flex;
            align-items: center;
            font-size: $font-size-14;
            color: $text !important;

            &:hover {
                background-color: $whitelight;
            }
        }

        .mini-search-image {
            min-width: math.div(50,16)*1rem;
            margin-right: math.div(15,16)*1rem;

            img {
                margin: 0 auto;
                display: block;
            }
        }
    }

    .ui-autocomplete-loading {
        background-position-x: 98%;
    }
}

.active-mini-search {
    .small-search-box-form {
        visibility: visible;

        @include tablet {
            transform: scaleX(1);
        }

        @include mobile {
            transform: scaleY(1);
        }
    }
}

.open-header-search {
    cursor: pointer;
    line-height: 0;
}