﻿@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/custom-scrollbar" as *;

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="number"],
textarea, select, .select-selected {
    font-size: $font-size-14;
    height: math.div(45,14)*1rem; /*safari padding fix*/
    border: 1px solid $primary;
    padding: 8px;
    vertical-align: middle;
    font-family: $family-sans-serif;
    color: $text;
}

input, textarea, select, button, .select-selected {
    font-size: $font-size-14;
    font-family: $family-sans-serif;
}

textarea {
    min-height: 150px;
}

select, .select-selected {
    // min-width: 50px;
    height: math.div(45,14)*1rem; /*safari padding fix*/
    padding: 8px;

    &:focus-visible {
        outline-style: solid;
        outline-color: $primary;
    }
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus,
.select-selected:focus {
    color: $text;
    position: relative;
    z-index: 1;
    outline: 1px solid $primary;
}

input[type="checkbox"],
input[type="radio"],
input[type="checkbox"] + *,
input[type="radio"] + * {
    vertical-align: middle;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid $primary;
    width: math.div(12,16)*1rem;
    height: math.div(12,16)*1rem;
    cursor: pointer;

    &:checked {
        background-color: $primary;
    }
}

input[type="radio"] {
    border-radius: 50%;
}

input[type="button"], input[type="submit"],
button, .button-1, .button-2 {
    cursor: pointer;
}

input {
    &:focus-visible {
        outline-color: $primary;
        outline-style: solid;
    }
}

label, label + * {
    vertical-align: middle;
}

.fieldset, .section {
    position: relative;
    margin: 0 0 40px;
}

.fieldset .title,
.section .title {
    margin: 0 0 15px;
    // padding: 0 10px;
    font-size: $font-size-24;
    line-height: normal;
    color: $primary;
    text-align: left;
}

.fieldset .title strong,
.section .title strong {
    font-weight: normal;
}

.form-fields {
    position: relative;
    // border-top: 1px solid #e6e6e6;
    // background-color: #f9f9f9;
    // padding: 30px 15px;
}

.inputs {
    position: relative;
    margin: 0 0 15px;
    text-align: center;
    white-space: nowrap; /*fix for 'required' elements*/
    font-size: 0;

    &.required-input {
        label {
            &:after {
                content: '*';
                margin-left: 3px;
                color: #e4434b;
            }
        }
    }
}

.inputs:after {
    content: "";
    display: block;
    clear: both;
}

.inputs label {
    display: block;
    width: 100%;
    margin: 0;
    text-align: left;
    font-size: $font-size-14; /*reset zeroing*/
    color: $primary;
    padding-bottom: math.div(10,14)*1em;
}

.inputs input[type="text"],
.inputs input[type="email"],
.inputs input[type="tel"],
.inputs input[type="password"],
.inputs select, .inputs textarea,
.inputs .select-selected {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
}

.inputs.custom-attributes {
    white-space: normal;
}

.inputs .option-list {
    display: inline-block;
    max-width: 95%;
    vertical-align: middle;
}

.inputs .option-list li {
    display: inline-block;
    margin: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 8px 10px;
}

.inputs .option-list label {
    display: inline-block;
    width: auto !important;
    margin: 0 0 0 5px;
}

.inputs.reversed {
    margin: 0 0 20px;
    text-align: left;

    * {
        font-size: $font-size-14;
        color: $text;
    }
}

.required {
    margin: 0 -8px 0 3px; /*siblings offset*/
    vertical-align: top;
    font-size: 20px;
    font-weight: normal;
    color: #e4434b;
}

.message-error,
.field-validation-error,
.username-not-available-status,
.poll-vote-error, .password-error {
    display: block;
    text-align: left;
    font-size: $font-size-14;
    color: #e4434b;
    margin-top: 5px;
    font-weight: $weight-500;
}

.field-validation-valid,
.username-available-status {
    display: block;
    text-align: center;
    font-size: 13px;
    color: #4cb17c;
}

.captcha-box {
    text-align: center;
    line-height: 0; /*firefox line-height bug fix*/
}

.captcha-box > div {
    display: inline-block;
    max-width: 100%;
}

.captcha-box input {
    height: auto;
}

.custom-select {
    position: relative;
    z-index: 100;

    select {
        @include tablet {
            display: none;
        }

        @include mobile {
            opacity: 0;
        }
    }

    .select-selected {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 6px;
            background-image: url('../../images/dropdown-icon-primary.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-left: 4px;
        }

        &.custom-select-active {
            outline: 1px solid $primary;
        }
    }
}

.select-items { 
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    min-width: 100%;
    max-width: 100%;
    box-shadow: 0px 4px 45px 2px rgba($black, 0.06);
    background-color: rgba($whitelight, 0.95);
    max-height: math.div(340, 16)*1rem;
    overflow-y: auto;
    text-align: left;
    @include custom-scrollbar(4px);

    &.select-hide {
        display: none;
    }

    .custom-option {
        color: $text;
        font-size: 1rem;
        padding: math.div(8,16)*1em;
        cursor: pointer;
        white-space: normal;

        &.same-as-selected, &:hover {
            background: $primary;
            color: $white;
        }
    }
}