@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;
@use "../mixins/custom-scrollbar" as *;

$product-details-padding-left-right: min(25px, max(17px, math.div(25,1400)*100vw));
$overview-border: 1px solid $beige;

.html-product-details-page {
    .product-details-page {
        padding-top: 0;

        // @include tablet {
        //     padding-top: min(52px, max(24px, math.div(52,1400)*100vw));
        // }
    }

    .product-essential {
        margin-bottom: min(128px, max(64px, math.div(128,1400)*100vw));

        .add-to-wishlist-button {
            width: 24px;
            height: 20px;
    
            @include tablet {
                width: 32px;
                height: 27px;
            }
    
            @include mobile {
                background-image: url('../../images/wishlist-icon-white.svg');

                &.active {
                    background-image: url('../../images/wishlist-icon-filled-white.svg');
                }
            }
        }
    }

    .product-collateral {
        margin-bottom: min(104px, max(64px, math.div(104,1400)*100vw));
    }

    // GALLERY
    .gallery{
        @include tablet {
            width: 49%;
            float: left;
        }

        @include mobile {
            margin-bottom: math.div(32,16)*1rem;
            display: flex;
        }

        .picture {
            background-color: $white;
            margin-bottom: 0;

            @include tablet {
                margin-bottom: 14px;
            }

            @include mobile {
                width: 100%;
            }
        }
    
        .picture-thumbs {
            @include tablet {
                display: grid;
                gap: 14px;
                grid-template-columns: repeat(4, 1fr);
            }

            @include mobile {
                min-width: 78px;
                margin-left: 9px;
                max-height: clamp(254px, 46.09375vw, 590px);
                overflow-y: auto;

                @include custom-scrollbar(6px, true, 2px);

                &::-webkit-scrollbar-thumb {
                    border-color: $silver;
                }

                @supports (scrollbar-color: $silver rgba($silver, 0.3)) {
                    scrollbar-color: $silver rgba($silver, 0.3)
                }
            }
    
            .thumb-item {
                background-color: $white;

                @include mobile {
                    &:not(:last-child) {
                        margin-bottom: 9px;
                    }
                }
    
                &:before {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }

                img {
                    @include mobile {
                        max-height: 80%;
                        max-width: 90%;
                    }
                }
            }
        }

        .picture-container {
            height: clamp(254px, 590/1280*100vw, 590px);
        }

        .picture-item {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 80%;

            img {
                max-height: 90%;
                max-width: 92%;

                @include tablet {
                    max-height: 95%;
                }
            }
        }

        .one-image {
            .picture-item {
                height: 100%;
    
                img {
                    max-height: 90%;
                }
            }
        }

        img {
            width: auto;
        }
    }


    //PRODUCT DETAILS
    .overview {
        border-top: $overview-border;

        @include mobile {
            display: flex;
            flex-direction: column;
        }

        @include tablet {
            width: 49%;
            float: right;
            background-color: $whitelight;
        }

        .manufacturers {
            margin-bottom: 0;
        }

        > div {
            &:not(.usps-container) {
                @include tablet {
                    border-right: $overview-border;
                    border-left: $overview-border;
                }
            }
        }

        .product-name, .product-short-details {
            @include mobile {
                display: none;
            }
        }

        .product-manufacturers-image, .qty-availability-wrapper, .prices, .discontinued-product, .attributes {
            @include mobile {
                border-right: $overview-border;
                border-left: $overview-border;
                background-color: $white;
            }
        }

        .discontinued-product {
            padding: 1.125rem $product-details-padding-left-right 0;
            margin-bottom: 0;
            text-align: left;

            @include tablet {
                padding-top: 0;
            }

            h4 {
                background-position-x: 0;
                padding: 0 0 0 30px;
                line-height: 20px;
            }
        }

        .attributes {
            padding: math.div(18,16)*1rem $product-details-padding-left-right;
            margin: 0;

            dl {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                > * {
                    margin: 0;
                }

                & + dl {
                    margin-top: 10px;
                }
            }

            dt {
                position: relative;
            }

            .required {
                position: absolute;
                right: min(30px, max(12px, math.div(30,1400)*100vw));
                top: 0;
                font-size: 17px;
            }
        }

        label {
            font-size: clamp($font-size-14, math.div(16,1280)*100vw, $font-size-16);
            color: $primary;
            font-weight: $weight-600;
            // margin-right: min(30px, max(12px, math.div(30,1400)*100vw));
            margin-right: math.div(12,16)*1rem;

            @include tablet {
                font-weight: $weight-bold;
            }
        }

        select, .select-selected {
            border-color: $primary;
            background-color: transparent;
            color: $primary;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .product-name {
        font-size: clamp($font-size-24, math.div(31,1280)*100vw,$font-size-31);
        line-height: 1.25;
        font-weight: $weight-bold;
        color: $primary;
        padding: 0 math.div(10,16)*1rem math.div(20,16)*1rem;
        margin-bottom: 0;
        border-bottom: 0;
        text-align: left;

        @include tablet {
            line-height: 1.16;
            padding: math.div(18,16)*1rem $product-details-padding-left-right math.div(32,16)*1rem;
        }

        h1 {
            font-weight: inherit;
        }
    }

    .product-manufacturers-image {
        background-color: $white;
        font-size: 1rem;
        padding: math.div(20,16)*1em;
        border-bottom: 1px solid $beige;

        @include tablet {
            padding: math.div(12,16)*1em math.div(24,16)*1em;
        }

        img {
            max-height: min(73px, max(56px, math.div(73,1400)*100vw));
            display: block;

            @include mobile {
                margin: 0 auto;
            }
        }
    }

    .product-short-details {
        padding: 0 math.div(10,16)*1rem math.div(5,16)*1rem;
        display: flex;
        align-items: center;

        @include tablet {
            padding: math.div(12,16)*1rem $product-details-padding-left-right 0;
        }

        .manufacturers {
            font-size: 1rem;
            margin-right: math.div(14,16)*1em;

            @include mobile {
                display: none;
            }

            .label {
                display: none;
            }

            a {
                @include link($primary, $primary, true);
                font-size: $font-size-13;
                font-weight: $weight-800;
                letter-spacing: $large-letter-spacing;
            }
        }

        .additional-details {
            margin-bottom: 0;
            font-weight: $weight-800;
            font-size: clamp($font-size-10, math.div(12,1280)*100vw, $font-size-12);
            color: $silver;

            @include tablet {
                font-weight: $weight-600;
            }

            div {
                margin-bottom: 0;
            }

            .value {
                color: inherit;
            }
        }

        .add-to-wishlist {
            line-height: 0;
            margin-left: auto;
        }
    }

    .short-description {
        margin-bottom: 0;
        font-weight: $weight-600;
        font-size: clamp($font-size-14, math.div(16,1400)*100vw, $font-size-16);
        line-height: 1.5;
        color: $gray3;
        text-align: left;
        padding: math.div(40,14)*1em 0;

        h3 {
            color: $silver;
            font-weight: $weight-bold;
            font-size: $font-size-16;
            margin-bottom: math.div(20,16)*1em;
        }

        @include tablet {
            padding: 0 $product-details-padding-left-right math.div(32,16)*1em;
            font-weight: $weight-bold;
        }

        @include mobile {
            order: 1;
            margin-top: math.div(56,14)*1em;
            border-top: $overview-border;
            border-bottom: $overview-border;
        }
    }

    .qty-availability-wrapper {
        padding: min(24px, max(20px, math.div(24,1400)*100vw)) $product-details-padding-left-right;
        border-bottom: 1px solid $beige;
        display: flex;
        align-items: center;

        @include mobile {
            justify-content: space-between;
        }

        @include tablet {
            border-top: 1px solid $beige;
        }

        .add-to-cart, .availability {
            margin-bottom: 0;
        }

        .add-to-cart-panel {
            display: flex;
            align-items: center;

            .select-items {
                &::-webkit-scrollbar-track {
                    border-right: 0;
                }
            }
        }

        .qty-input, .qty-dropdown {
            width: 48px;
            height: 28px;
            text-align: left;
            font-weight: $weight-bold;
            font-size: 1rem;
            color: $primary;
            background: transparent;

            @include tablet {
                width: 66px;
            }

            @include mobile {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    .add-to-cart-wrapper {
        @include mobile {
            display: flex;
        }

        .add-to-wishlist {
            font-size: $font-size-12;
            min-width: math.div(54,12)*1em;
            height: math.div(54,12)*1em;
            background-color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 3px;

            @include tablet {
                display: none;
            }
        }
    }

    .add-to-cart-button {
        width: 100%;
        font-size: $font-size-12;
        letter-spacing: $large-letter-spacing;
        height: math.div(54,12)*1em;

        @include tablet {
            height: math.div(72,12)*1em;
        }
    }

    .availability {
        font-size: $font-size-12;
        font-weight: $weight-800;
        letter-spacing: $large-letter-spacing;

        @include tablet {
            margin-left: math.div(44,12)*1em;
        }

        .stock {
            margin-bottom: 0;
            color: $aqua_forest;
            line-height: 1.2;
            text-align: right;
        }

        .not-in-stock {
            color: $red;
        }

        .value {
            color: inherit;
        }
    }

    .compare-products {
        width: 100%;
        margin: 0;
    }

    .overview-buttons {
        @include tablet {
            border-bottom: $overview-border;
        }

        @include mobile {
            margin-top: math.div(32,16)*1rem;
        }
    }

    .add-to-compare-list-button {
        width: 100%;

        &:hover {
            background-color: transparent;
        }

        @include mobile {
            background-color: $white;
            height: math.div(90,16)*1rem;
        }
    }
    
    .usps-container {
        padding: math.div(10,12)*1em;

        @include tablet {
            margin-top: math.div(12,12)*1em;;
        }

        .usp-box {
            padding-top: math.div(18,16)*1rem;
            padding-bottom: math.div(18,16)*1rem;

            @include tablet {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }

        img {
            margin-bottom: math.div(12,12)*1em;
            max-height: math.div(20,12)*1em;

            @include tablet {
                margin-bottom: math.div(10,12)*1em;
            }
        }

        .usp-description {
            margin-top: 4px;

            @include mobile {
                font-weight: $weight-bold;
                font-size: $font-size-12;
                letter-spacing: $large-letter-spacing;
            }
        }

        @include mobile {
            background-color: $white;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
            padding: 0;
        }
    }

    .custom-product-grid {
        padding-left: 0;
        padding-right: 0;
    }

    .product-specs-box {
        margin-bottom: 0;

        .table-wrapper {
            overflow: hidden;
        }
    }
}




.product-category-button {

    a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: color 0.27s ease-in-out;
        height: min(77px, max(64px, math.div(77,1400)*100vw));
        font-size: $font-size-12;
        font-weight: $weight-bold;
        letter-spacing: $large-letter-spacing;
        color: $primary;
        cursor: pointer;
        border: 1px solid $beige;
        padding: 0 math.div(28,12)*1em;


        @include tablet {
            justify-content: center;
            padding: 0 10px;

            &:after {
                content: none;
            }
        }

        @include mobile {
            justify-content: space-between;
        }
    }


    a:before {
        transition: transform 0.27s ease-in-out;
        background-color: #7A6453;
        transform: translateY(105%);
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }


    a:hover {
        color: #F5F5F5;

        &:before {
            transform: translateY(0);
        }
    }
}