@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/custom-scrollbar" as *;
@use "../mixins/link" as *;
@use "../mixins/button-animation" as *;

.shopping-cart-page, .wishlist-page {
    .product-picture {
        text-align: center;

        img {
            max-width: math.div(70,16)*1rem;
            min-width: math.div(33,16)*1rem;
        }
    }

    .product-unit-price, .product-subtotal {
        color: $rough_blue;
        font-weight: $weight-600;
    }

    select, input, .select-selected {
        max-height: math.div(35,16)*1rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    .custom-select {
        z-index: auto;

        .custom-select-active {
            & + .select-items {
                z-index: 10;
            }
        }
    }

    .cart{ 
        label {
            display: none;
        }

        .quantity {
            select, input, .select-selected {
                width: math.div(60,16)*1rem;
                padding-top: 0;
                padding-bottom: 0;
                background: transparent;
            }

            .select-items {
                width: math.div(60,16)*1rem;
                min-width: unset;
            }
        }
    }

    .cart-left-wrapper {
        width: 100%;

        @include tablet {
            width: 72%;
        }
    }

    .cart-right-wrapper {
        width: 100%;

        @include tablet {
            width: 28%;
        }
    }

    .cross-sells {
        width: 100%;
    }

    .common-buttons {
        display: flex;
        align-items: center;
        padding-left: 0;
        flex-wrap: wrap;
        margin-bottom: math.div(40,16)*1rem;

        @include mobile {
            padding-top: math.div(16,16)*1rem;
        }

        button, a {
            margin-right: math.div(16,16)*1rem;

            @include mobile {
                margin-top: math.div(16,16)*1rem;
            }
        }
        
    }

    .cart-footer{ 
        > div {
            padding: math.div(10,16)*1rem;
            border: 1px solid $beige;
        }

        @include tablet {
            padding-left: 5%;
        }

        .cart-collaterals {
            display: inline-block;
            width: 100%;
            background: $white;
            margin-bottom: math.div(20,16)*1rem;

            .title {
                font-size: $font-size-18;

                strong {
                    font-weight: $weight-bold;
                }
            }

            .hint {
                font-size: $font-size-14;
            }

            input {
                width: 100%;
                margin-bottom: math.div(10,16)*1rem;
            }

            button {
                width: 100%;
            }

            .apply-discount-coupon-code-button {
                @include button-animation($primary);
            }

            .remove-discount-button {
                text-align: left;
                background: none;
                border: 0;
                color: $primary;
                text-decoration: underline;
                margin-top: math.div(5,16)*1rem;

                &:hover {
                    color: bulmaLighten($primary, 15%);
                }
            }

            .deals {
                > div {
                    margin-bottom: math.div(10,16)*1rem;
                }
            }
        }

        .totals {
            width: 100%;
            background: $white;
            margin: 0;
            font-size: $font-size-14;

            .cart-total-right {
                font-weight: $weight-bold;
            }

            td {
                vertical-align: middle;
                line-height: normal;
                padding: math.div(10,16)*1rem math.div(5,16)*1rem;
            }

            .order-total {
                color: $rough_blue;
                // font-size: $font-size-18;
            }
        }

        .checkout-buttons {
            margin-top: math.div(16,16)*1rem;

            button {
                width: 100%;
            }
        }
    }

    .no-data {
        text-align: center;
    }

    .table-wrapper {
        @include tablet {
            overflow-x: visible;
        }
    }
}

.shopping-cart-page {
    form {
        display: flex;
        flex-wrap: wrap;
    }
}