﻿* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    margin: 0;
    -webkit-text-size-adjust: none;
}

ol, ul {
    list-style: none;
}

a img {
    border: none;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="file"] > button::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    margin: 0;
    border: 0;
    padding: 0;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
    border-radius: 0;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
    -webkit-appearance: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

script {
    display: none !important;
}
