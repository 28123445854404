﻿@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;

.footer-wrapper {
    background-color: $primary;
    font-size: $font-size-12;
    line-height: math.div(16,12)*1em;
    font-weight: $weight-600;
    color: $beige;

    a {
        @include link($beige, $beige, true);
    }

    .title {
        font-size: $font-size-14;
        font-weight: $weight-bold;
        line-height: math.div(15,14)*1em;
        margin-bottom: math.div(20,14)*1em;
    }

    .list {
        li {
            &:not(:last-child) {
                margin-bottom: math.div(10,12)*1em;
            }
        }
    }

    .footer-top {
        padding-top: math.div(40,12)*1em;
        padding-bottom: math.div(40,12)*1em;

        // @include tablet {
        //     padding-bottom: math.div(56,12)*1em;
        // }
    }

    .footer-bottom {
        border-top: 1.15px solid $beige;
        padding-top: min(25px, max(13px, 45/1280*100vw));
        padding-bottom: min(36px, max(26px, 36/1280*100vw));
    }

    .payment-methods {
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        li {
            padding: math.div(15,12)*1em math.div(6,12)*1em;
        }

        img {
            max-height: min(35px, max(20px, 35/1280*100vw));

            @include mobile {
                max-width: math.div(102,12)*1em;
            }
        }
    }

    .footer-store-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: math.div(40,12)*1em;

        @include tablet {
            align-items: center;
            margin-top: math.div(30,12)*1em;
        }

        @include mobile {
            font-size: $font-size-10;
        }
    }

    .made-by-wrapper {
        @include tablet {
            display: flex;
            align-items: center;
        }

        @include mobile {
            text-align: right;
            font-weight: $weight-normal;

            a {
                font-weight: $weight-bold;
            }
        }
    }

    .made-by {
        @include tablet {
            margin-left: math.div(8,12)*1em;

            img {
                height: math.div(10,12)*1em;
            }
        }

        @include mobile {
            margin-top: math.div(10,12)*1em;

            img {
                margin-left: math.div(3,12)*1em;
            }
        }
    }

    .networks {
        display: flex;
        align-items: center;

        li {
            &:not(:last-child) {
                margin-right: math.div(23,12)*1em;
            }
        }

        img {
            @include mobile {
                max-height: math.div(15,12)*1em;
            }
        }
    }

    .footer-items {
        display: grid;
        grid-template-columns: 1.2fr 1fr;
        grid-gap: math.div(65,12)*1em math.div(10,12)*1em;

        @include tablet {
            grid-template-columns: repeat(4, 1fr);
            column-gap: math.div(55,12)*1em;
            row-gap: math.div(53,12)*1em;
        }
    }

    .footer-item {
        padding-right: 7%;
    }

    .item-1 {
        grid-area: 1 / 1 / 3 / 1;
    }

    .item-3 {
        grid-column: 2;
        grid-row: 1;

        @include tablet {
            grid-column: 2;
            grid-row: 2;
        }
    }

    .item-4 {
        @include mobile {
            grid-column: 2;
            grid-row: 2;
        }
    }
}

.txt_button {
    display: flex;
    position: relative;
    border-style: solid;
    border-width: 0;
    font-size: calc(12 / 16 * 1rem);
    line-height: calc(15.6 / 12 * 1em);
    font-variation-settings: "wght"700;
    justify-content: center;
    align-items: center;
    width: max-content;
    min-block-size: calc(48 / 12 * 1em);
    min-inline-size: calc(181 / 12 * 1em);
    padding-block: calc(16 / 12 * 1em);
    padding-inline: calc(53 / 12 * 1em);
    cursor: pointer;
}


.newsletter-slice {
    background-color: $primary;
    font-size: $font-size-12;
    line-height: math.div(16,12)*1em;
    font-weight: $weight-600;
    color: $beige;
    margin-bottom: 51px;
}


.newsletter-wrapper {

    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    -moz-column-gap: 1.1875rem;
    column-gap: 1.1875rem;
    grid-column: 2/-2;
    padding: 30px 0;

    header {
        width: min(493px,100%);

        h2, h3 {
            color: #DFD9D3;
        }

        h2 {
            font-size: 18px;
            font-weight: 800;
            line-height: 27px;
        }

        h3 {
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            margin-top: 6px;
        }
    }

  
}

#mc-embedded-subscribe-form {
    flex: 1 1 470px !important;

    .required {
        margin: 0;
    }

    .flex-wrapper {
        display: flex;
        flex: 1 1;
        position: relative;
        row-gap: 25px;
        flex-wrap: wrap !important;
    }

    #mc_embed_signup {
        width: 100%;

        .mc-field-group {
            flex: 1 1 300px;

            input {
                width: 100%;
                border: none;
                flex: 1 1 auto;
                height: 48px;
                text-indent: 1rem;
            }
        }

        #mc-embedded-subscribe {
            @extend .txt_button;
            text-indent: 0;
            width: 181px;
            background-color: #DFD9D3;
            color: #7A6453;
        }

        div.mce_inline_error {
            color: #ed1c24;
            background: transparent;
        }
    }

    #mce-error-response,
    #mce-success-response {
        color: #ed1c24;
        background: transparent;        
        font-weight: bold;
        margin-top: 6px;
    }
}