@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;

.pager {
    li {
        width: min(48px, max(32px, math.div(48,1280)*100vw));
        height: min(48px, max(32px, math.div(48,1280)*100vw));
        font-size: clamp($font-size-14, math.div(20,1280)*100vw, $font-size-20);
        font-weight: $weight-800;
        line-height: min(46px, max(30px, math.div(46,1280)*100vw));
        color: $silver;
        border: 1px solid $silver;
        margin: 0 4px;

        @include tablet {
            font-weight: $weight-500;
            margin: 0 8px;
        }

        a {
            @include link($silver, $silver, false);
        }

        &:hover {
            background-color: $silver;

            a {
                color: $white;
            }

            &.next-page,
            &.previous-page {
                * {
                    background-image: url('../../images/previous-next-page-white-mobile.svg');

                    @include tablet {
                        background-image: url('../../images/previous-next-page-white.svg');
                    }
                }
            }

            &.first-page,
            &.last-page {
                * {
                    background-image: url('../../images/first-last-page-white-mobile.svg');

                    @include tablet {
                        background-image: url('../../images/first-last-page-white.svg');
                    }
                }
            }
        }

        &.next-page,
        &.previous-page {
            * {
                background-image: url('../../images/previous-next-page-mobile.svg');

                @include tablet {
                    background-image: url('../../images/previous-next-page.svg');
                }
            }
        }

        &.first-page,
        &.last-page {
            * {
                background-image: url('../../images/first-last-page-mobile.svg');

                @include tablet {
                    background-image: url('../../images/first-last-page.svg');
                }
            }
        }

        &.previous-page,
        &.first-page {
            * {
                transform: rotate(180deg);
            }
        }
    }

    .current-page {
        background-color: $silver;
        color: $white;
    }
}