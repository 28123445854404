@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;
@use "../mixins/button-animation" as *;

.registration-page,
.registration-result-page,
.password-recovery-page,
.email-revalidation-page,
.account-activation-page,
.account-page,
.return-request-page, 
.email-a-friend-page {
    .page-body {
        max-width: math.div(600,16)*1rem;
        margin: 0 auto;
    }
}

// LOGIN PAGE
.login-page {
    .buttons {
        text-align: left;
    }

    button {
        @include mobile {
            width: 100%;
        }

        & + button {
            margin-top: math.div(16,16)*1rem;
        }
    }

    .customer-blocks {
        display: flex;

        @include tablet {
            justify-content: space-between;
        }

        > div {
            float: none;

            @include tablet {
                padding: 0 3%;
            }

            @include mobile {
                width: 100%;
            }
        }

        @include mobile {
            flex-direction: column;
        }
    }

    .register-block, .checkout-as-guest-or-register-block {
        order: 1;

        @include mobile {
            margin-top: math.div(32,16)*1rem;
            margin-bottom: 0;
        }
    }

    .reversed {
        display: flex;
        align-items: center;
        margin: math.div(24,16)*1rem 0;

        * {
            width: auto;
            padding: 0;
            line-height: 1.1;
        }

        input[type="checkbox"] {
            min-width: math.div(12,16)*1rem;
        }

        label {
            margin-left: math.div(10,16)*1rem;
        }

        .forgot-password {
            margin-left: auto;
            a {
                @include link($primary, $primary, true);
            }
        }
    }

    .title{ 
        &.guest {
            margin-top: math.div(56,16)*1rem;
        }
    }
}

//PASSWORD RECOVERY
.password-recovery-page {
    .text {
        margin-bottom: math.div(32,16)*1rem;
    }

    .inputs {
        &:after {
            content: none;
        }
    }

    label {
        &:after {
            content: '*';
            margin-left: 3px;
            color: #e4434b;
        }
    }

    .required {
        display: none;
    }

    .buttons {
        text-align: left;
    }
}

//REGISTER
.registration-page, .customer-info-page {
    .form-fields {
        display: grid;
        gap: math.div(20,16)*1rem;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .fieldset {
        margin-bottom: math.div(20,16)*1rem;
    }

    .inputs {
        margin-bottom: 0;
    }

    .required {
        display: none;
    }

    .email-input {
        @include tablet {
            grid-column: 1 / -1;
        }
    }

    .newsletter-input {
        display: flex;
        align-items: center;

        label {
            width: auto;
            padding: 0;
            margin-right: math.div(10,16)*1rem;
        }
    }

    .buttons {
        margin-top: math.div(40,16)*1rem;

        @include mobile {
            margin-bottom: 0;
        }
    }
}

.registration-page {
    .buttons {
        button {
            width: 100%;
        }
    }
}

//REGISTRATION RESULT PAGE
.registration-result-page{ 
    .result {
        font-size: clamp($font-size-21, math.div(24,1280)*100vw, $font-size-24);
        line-height: normal;
    }

    .buttons {
        text-align: center;
    }
}