@use 'sass:math';
@use "../base/variables" as *;
@use "../addons/bulma-basic" as *;
@use "../mixins/link" as *;

.compare-products-page {
    .compare-products-table{ 
        font-size: $font-size-14;

        @include mobile {
            margin-top: math.div(24,16)*1rem;
        }

        .picture {
            max-width: math.div(200,16)*1rem;
            height: math.div(130,16)*1rem;
            margin: 0 auto;

            img {
                max-height: 80%;
            }
        }

        .remove-button {
            background-image: url('../../images/close.svg');
            background-size: math.div(11,16)*1rem;
            border: 0;
            padding-left: math.div(20,16)*1rem;
            padding-right: 0;
            height: auto;

            &:before {
                content: none;
            }

            &:hover {
                color: bulmaLighten($primary, 15%);
            }
        }

        td {
            padding: math.div(20,16)*1rem;
            vertical-align: middle;

            &:first-child {
                font-weight: $weight-600;
                font-size: $font-size-13;
                line-height: 1.5;
                position: sticky;
                left: 0;
                z-index: 2;
            }

            &:not(:first-child) {
                text-align: center;
            }
        }

        tr {
            &:not(:last-child) {
                border-bottom: 1px solid $beige;
            }

            &:nth-child(even) {
                td {
                    background: $white;
                }
            }

            &:nth-child(odd) {
                td {
                    background: $whitelight;
                }
            }
        }

        .product-name {
            font-size: $font-size-13;
            a {
                @include link($primary, $primary, true);
            }
        }

        .product-price {
            td {
                &:not(:first-child) {
                    color: $rough_blue;
                    font-weight: $weight-bold;
                }
            }
        }

        .main-button {
            color: $white;
            background: transparent;
            border: 0;
            letter-spacing: inherit;
            font-weight: inherit;
            display: block;
            width: 100%;
            height: 100%;
            padding: 0 math.div(10,16)*1rem;
            
            @include mobile {
                font-size: $font-size-12;
            }
        }

        .button-1 {
            min-width: auto;
            width: 100%;
            padding: 0;

            &:hover {
                .main-button {
                    color: $primary;
                }
            }
        }
    }

    .remove-product {
        text-align: center;
    }

    .no-data {
        text-align: center;
    }
}