@use "../base/variables" as *

=custom-scrollbar($width, $vertical:true, $thumb-width: 4px)
    @supports (scrollbar-color: $primary rgba($primary, 0.3)) 
        scrollbar-color: $primary rgba($primary, 0.3)
        scrollbar-width: thin

    /* width */
    &::-webkit-scrollbar 
        width: $width
        height: $width


    /* Track */
    &::-webkit-scrollbar-track 
        @if $vertical
            border-right: 1px solid rgba($primary, 0.3)
        @else    
            border-bottom: 1px solid rgba($primary, 0.3)

    
    /* Handle */
    &::-webkit-scrollbar-thumb 
        @if $vertical
            border-right: $thumb-width solid $primary
        @else    
            border-bottom: $thumb-width solid $primary
