@use "../base/variables" as *

=link($color, $hover, $underline)
    color: $color
    
    &:hover
        color: $hover
        @if $underline
            text-decoration: underline

    &:focus,
    &:active
        color: $color