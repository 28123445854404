﻿.admin-header-links {
    background-color: #BDBDBD;
    text-align: center;
    color: #eee;
    position: absolute;
    z-index: 100;
    width: 100%;
}

.admin-header-links * {
    display: inline-block;
    margin: 0 10px;
    line-height: 35px;
    font-size: 12px;
    font-weight: bold;
}

.admin-header-links .impersonate {
    display: inline-block;
}

.admin-header-links .impersonate a {
    background-color: #555;
    padding: 0 15px;
}

.admin-header-links .impersonate a:hover,
.admin-header-links .impersonate a:focus {
    background-color: #666;
}

.header {
    position: relative;
    z-index: 10;
    margin: auto;
    text-align: center;
}

.header-upper {
    position: relative;
    z-index: 1;
    // border-bottom: 1px solid #ddd;
}

.header-selectors-wrapper {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.header-selectors-wrapper > div {
    display: block;
    width: 50%;
    margin: 10px auto;
    vertical-align: middle;
}

.header-selectors-wrapper select {
    width: 100%;
}

.language-list {
    max-width: 100%;
    font-size: 0;
}

.language-list li {
    display: inline-block;
    margin: 0 1px;
    vertical-align: middle;
}

.language-list a {
    display: block;
    position: relative;
    width: 24px;
    height: 32px;
    line-height: 0;
}

.language-list img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.header-links-wrapper {
    position: relative;
    // padding: 20px 0;
}

// .header-links {
// }

.header-links ul {
    font-size: 0;
}

.header-links li {
    display: inline-block;
    // margin: 0 10px;
}

.header-links span,
.header-links a {
    display: inline-block;
    font-size: 12px; /*reset zeroing*/
    // line-height: 36px;
    // text-transform: uppercase;
}

.header-links a:hover,
.header-links a:focus {
    color: #4ab2f1;
}

// #topcartlink {
//     display: block;
//     width: 70%;
//     max-width: 400px;
//     margin: 10px auto 0;
//     background-color: #f6f6f6;
//     padding: 0 20px;
// }

// #topcartlink a {
//     background: url('../../images/shopping-bag.png') left center no-repeat;
//     padding: 0 0 0 20px;
// }

// .header-lower {
//     position: relative;
//     z-index: 0;
//     padding: 35px 0;
// }

// .header-logo {
    // margin: 0 0 20px;
    // text-align: center;
// }

.header-logo a {
    // display: inline-block;
    // max-width: 100%;
    line-height: 0; /*firefox line-height bug fix*/
}

.header-logo a img {
    max-width: 100%;
    opacity: 1;
}

// .search-box form {
//     display: inline-block;
// }

.search-box form:after {
    content: "";
    display: block;
    clear: both;
}

// .search-box input.search-box-text {
//     float: left;
//     width: 200px;
//     height: 36px;
//     margin: 0 -1px 0 0;
// }

// .search-box .search-box-button {
//     float: left;
//     min-width: 86px;
//     height: 36px;
//     // border: none;
//     // background-color: #4ab2f1;
//     padding: 0 15px;
//     text-align: center;
//     // color: #fff;
//     text-transform: uppercase;
// }

// .search-box .search-box-button:hover,
// .search-box .search-box-button:focus {
//     background-color: #248ece;
// }

.search-box .ui-autocomplete-loading {
    background: #fff url('../../images/ajax-loader-small.gif') right center no-repeat;
}

.ui-helper-hidden-accessible {
    display: none !important;
}

.ui-autocomplete {
    width: 285px !important;
    border: 1px solid #ddd;
    border-top: none;
    overflow: hidden;
    background-color: #fff;
    text-align: left;
    /*override jQuery UI styles, do not delete doubled properties*/
    border-radius: 0;
    padding: 0;
    font: normal 14px Arial, Helvetica, sans-serif;
}

.ui-autocomplete li {
    border-top: 1px solid #ddd;
}

.ui-autocomplete li:first-child {
    border-top: none;
}

.ui-autocomplete a {
    display: block;
    padding: 15px;
    font-size: 14px;
    /*override jQuery UI styles, do not delete doubled properties*/
    margin: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 15px !important;
    line-height: normal !important;
    color: #777 !important;
}

.ui-autocomplete a:hover,
.ui-autocomplete a:focus,
.ui-autocomplete a.ui-state-focus {
    background-color: #f6f6f6 !important;
}

.ui-autocomplete img {
    display: none;
    min-width: 20px;
    margin: 0 10px 0 0;
    vertical-align: middle;
}


/*********** FOOTER ***********/



.footer {
    background-color: #eee;
    text-align: center;
}

.footer-upper {
    width: 95%;
    margin: auto;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    padding: 40px 0;
    font-size: 0;
}

.footer-block {
    width: 95%;
    margin: 1px auto;
    text-align: left;
    font-size: 14px; /*reset zeroing*/
}

.footer-block .title {
    margin: 0 0 1px;
    background: #4ab2f1 url('../../images/toggle-white.png') right center no-repeat;
    padding: 10px 15px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

.footer-block .title strong {
    font-weight: normal;
}

.footer-block .list {
    display: none;
    margin: 0 0 20px;
    background-color: #fff;
    padding: 10px 15px;
}

.footer-block .list a {
    display: block;
    padding: 10px 15px;
    line-height: 20px;
}

.footer-block .list a:hover,
.footer-block .list a:focus {
    color: #4ab2f1;
}

.follow-us {
    margin: 30px auto 0;
    text-align: center;
}

.follow-us .title {
    margin: 0 0 10px;
    background: none;
    color: #444;
    cursor: auto;
}

.follow-us .social ul {
    margin: 0 0 30px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 30px;
    font-size: 0;
}

.follow-us .social li {
    display: inline-block;
    margin: 0 5px;
}

.follow-us .social a {
    display: block;
    width: 38px;
    height: 38px;
    background: url('../../images/social-sprite.png') no-repeat;
    font-size: 0;
}

.follow-us .social .facebook a {
    background-position: 0 0;
}

.follow-us .social .twitter a {
    background-position: -38px 0;
}

.follow-us .social .rss a {
    background-position: -76px 0;
}

.follow-us .social .youtube a {
    background-position: -114px 0;
}

.follow-us .social .google-plus a {
    background-position: -152px 0;
}

.newsletter-email {
    display: inline-block;
    overflow: hidden;
}

.newsletter-email:focus-within {
    overflow: visible;
}

.newsletter-email input[type="email"] {
    float: left;
    width: 180px;
    height: 36px;
    margin: 0 -1px 0 0;
}

.newsletter-subscribe-button {
    float: left;
    height: 36px;
    // border: none;
    // background-color: #4ab2f1;
    padding: 0 15px;
    text-align: center;
    // color: #fff;
    text-transform: uppercase;
}

// .newsletter-subscribe-button:hover,
// .newsletter-subscribe-button:focus {
//     background-color: #248ece;
// }

.newsletter-email .options {
    clear: both;
    padding: 10px 0 0;
}

.newsletter-validation,
.newsletter-result {
    width: 100%;
    overflow: hidden;
    line-height: 28px;
}
/*.newsletter-validation .please-wait {
	display: none !important; 
}*/

.footer-lower {
    width: 95%;
    margin: auto;
    overflow: hidden;
    padding: 25px 0;
    font-size: 12px;
}

.footer-tax-shipping a {
    font-weight: bold;
    color: #4ab2f1;
}

.footer-tax-shipping a:hover,
.footer-tax-shipping a:focus {
    text-decoration: underline;
}

.theme-selector {
    margin: 10px 0 0;
}

.theme-selector select {
    width: 170px;
}


/*********** SIDE COLUMN ***********/



.block {
    max-width: 600px; /*width limit in mobile*/
    min-height: 50px;
    margin: auto;
    text-align: left;
}

.block .title {
    position: relative;
    // margin: 0 0 10px;
    // border-bottom: 1px solid #ddd;
    background: #f6f6f6 url('../../images/toggle-gray.png') right 14px no-repeat;
    // padding: 12px 30px 12px 10px;
    // font-size: 20px;
    // color: #444;
    cursor: pointer;
}

.block .title strong {
    font-weight: normal;
}

.block .listbox {
    display: none;
    // border-bottom: 1px solid #ddd;
    // padding: 10px 5px;
}

// .block .list li {
//     padding: 5px 0 5px 15px;
// }

.block .list a {
    display: inline-block;
    position: relative;
    // padding: 5px 0;
    // font-size: 16px;
    // color: #444;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

// .block .list a:before {
//     content: "";
//     position: absolute;
//     top: 12px;
//     left: -15px;
//     width: 5px;
//     height: 5px;
//     background-color: #ddd;
// }

// .block .list .active > a {
//     color: #4ab2f1;
// }

// .block .list a:hover,
// .block .list a:focus {
//     color: #4ab2f1;
// }

.block a.product-picture {
    display: none;
}

.block a.product-picture:before {
    display: none;
}

.block .sublist {
    margin: 15px 0 5px;
}

.block .sublist a {
    font-size: 14px;
}

.block .view-all {
    margin: 10px 0 0;
}

.block .view-all a {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    color: #4ab2f1;
}

.block .view-all a:hover,
.block .view-all a:focus {
    text-decoration: underline;
}

.block .tags {
    margin: 5px 0 10px;
}

.block .tags ul {
    font-size: 0;
}

.block .tags li,
.product-tags-all-page li {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    overflow: hidden;
    font-size: 17px !important; /*setting base size*/
}

.block .tags li a,
.product-tags-all-page li a {
    float: left;
    line-height: 30px;
    color: #444;
}

.block .tags li a:hover,
.block .tags li a:focus,
.product-tags-all-page li a:hover,
.product-tags-all-page li a:focus {
    color: #4ab2f1;
}

.poll strong {
    display: block;
    margin: 10px 0;
    background-color: #f6f6f6;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: normal;
    color: #444;
    text-transform: uppercase;
}

.poll-options,
.poll-results {
    margin: 10px 0 15px;
    overflow: hidden;
    font-size: 15px;
    color: #444;
}

.poll-options li,
.poll-results li {
    margin: 10px 0;
}

.poll-options li > input {
    margin: 0 5px 0 0;
    cursor: pointer;
}

.poll-options li > label {
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

// .poll .buttons,
// .poll-total-votes {
// }

.poll .buttons button {
    border: none;
    background-color: #aaa;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
}

.poll .buttons button:hover,
.poll .buttons button:focus {
    background-color: #bbb;
}

.poll-total-votes {
    display: block;
    margin: 10px 0 0;
    font-weight: bold;
    font-style: italic;
    color: #444;
}

.home-page-polls {
    text-align: center;
}

.home-page-polls .title {
    border-bottom: 1px solid #ddd;
    color: #444;
    font-size: 30px;
    font-weight: normal;
    margin: 0 0 30px;
    padding: 0 0 15px;
}

.home-page-polls .title strong {
    font-weight: normal;
}

.home-page-polls li {
    display: inline-block;
    margin: 10px;
}


/********** CATEGORY PAGE **********/


.category-description,
.manufacturer-description,
.vendor-description {
    margin: 0 0 25px;
    line-height: 22px;
}

.contact-vendor {
    margin: 0 0 30px;
}

.contact-vendor .button-2 {
    border: none;
    background-color: #4ab2f1;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}

.contact-vendor .button-2:hover,
.contact-vendor .button-2:focus {
    background-color: #248ece;
}

// .product-selectors {
//     margin: 0 0 20px;
//     border-top: 1px solid #ddd;
//     border-bottom: 1px solid #ddd;
//     padding: 20px 0;
// }

.product-selectors:first-child {
    border-top: none;
}

// .product-selectors:after {
//     content: "";
//     display: block;
//     clear: both;
// }

// .product-selectors > div {
//     display: inline-block;
//     margin: 5px;
// }

// .product-selectors span {
//     vertical-align: middle;
//     font-size: 16px;
// }

// .product-selectors select {
//     margin: 0 8px;
// }

// .product-selectors .product-viewmode {
//     display: none;
//     font-size: 0;
// }

// .product-filter + .product-filter {
//     margin-top: 20px;
// }

.product-filter .filter-title {
    margin: 0 0 10px;
    background-color: #f6f6f6;
    padding: 10px;
    font-size: 17px;
    color: #444;
}

.product-filter .filter-title strong {
    font-weight: normal;
}

.product-filter .filter-content {
    padding: 10px;
    color: #444;
}

.product-filter .group {
    font-size: 15px;
}

.product-filter .group + .group {
    margin-top: 16px;
}

.product-filter .group .name {
    padding-bottom: 10px;
}

.product-filter .group .item {
    display: flex;
    padding-left: 15px;
    align-items: center;
}

.product-filter .group .item + .item {
    padding-top: 8px;
}

.product-filter .group .item label {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 8px;
    flex-grow: 1;
}

.price-range-filter .selected-price-range {
    padding: 5px 0;
}

.price-range-filter .selected-price-range .to {
    float: right;
}

.price-range-filter .selected-price-range.manually {
    display: flex;
}

.price-range-filter .selected-price-range.manually .from {
    margin-right: 8px;
}

.price-range-filter .selected-price-range.manually .from,
.price-range-filter .selected-price-range.manually .to {
    width: 50%;
}

.product-filter .attribute-squares {
    font-size: inherit;
}

.product-filter .attribute-squares .attribute-square {
    width: 20px !important;
    height: 20px !important;
    margin-right: 8px;
}

.item-grid:after {
    content: "";
    display: block;
    clear: both;
}

.item-box {
    position: relative;
    width: 100%;
    float: left;
    margin: 0 0 80px;
    text-align: left;
}

.item-box .picture {
    z-index: 1;
    background-color: #fff;
    margin: 0 0 20px;
}

.item-box .picture a {
    display: block;
    position: relative;
}

.item-box .picture a:focus {
    opacity: 0.85;
}

.item-box .picture a:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.item-box .picture a img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

// .item-box:hover .picture a img {
//     opacity: 0.85;
// }

.item-box .details {
    padding: 0 10px;
}

.item-box .product-title {
    margin: 0 0 10px;
    font-size: 17px;
    line-height: 1.2;
    font-weight: normal;
    color: #4ab2f1;
}

.item-box .product-title a {
    display: block;
}

.item-box .product-title a:hover,
.item-box .product-title a:focus {
    color: #248ece;
}

.item-box .sku {
    margin: 0 0 15px;
}

.item-box .product-rating-box {
    display: inline-block;
    margin: 0 0 10px;
}

.item-box .rating {
    background: url('../../images/rating1.png') repeat-x;
    width: 95px;
    height: 14px;
}

.item-box .rating div {
    background: url('../../images/rating2.png') repeat-x;
    height: 14px;
}

.item-box .description {
    display: none;
}

.item-box .description a {
    display: block;
    overflow: hidden;
}

.item-box .prices {
    margin: 0 0 10px;
    overflow: hidden;
}

.item-box .old-price {
    margin: 0 5px 0 0;
    font-size: 16px;
    color: #aaa;
    text-decoration: line-through;
}

.item-box .actual-price {
    margin: 0 5px 0 0;
    font-size: 16px;
    color: #666;
}

.item-box .tax-shipping-info a {
    color: #4ab2f1;
}

.item-box .tax-shipping-info a:hover,
.item-box .tax-shipping-info a:focus {
    text-decoration: underline;
}

.item-box .buttons {
    margin: 0;
    font-size: 0;
}

.item-box button {
    display: inline-block;
    height: 40px;
    border: none;
    vertical-align: middle;
}

.item-box .product-box-add-to-cart-button {
    width: 60%;
    // background-color: #4ab2f1;
    text-align: center;
    // font-size: 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .item-box .product-box-add-to-cart-button:hover,
// .item-box .product-box-add-to-cart-button:focus {
//     background-color: #248ece;
// }

.item-box .add-to-compare-list-button,
.item-box .add-to-wishlist-button {
    width: 20%;
    border-left: 1px solid #fff !important;
    background-color: #eaeaea;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
}

.item-box .add-to-compare-list-button {
    background-image: url('../../images/compare-button.png');
}

.item-box .add-to-wishlist-button {
    background-image: url('../../images/wishlist-button.png');
}

.item-box .add-to-compare-list-button:hover,
.item-box .add-to-compare-list-button:focus,
.item-box .add-to-wishlist-button:hover,
.item-box .add-to-wishlist-button:focus {
    background-color: #ddd;
}

.home-page-category-grid,
.sub-category-grid,
.manufacturer-grid,
.vendor-grid {
    margin: 0 0 20px;
}

.home-page-category-grid .title,
.sub-category-grid .title {
    text-align: center;
    font-size: 22px;
    font-weight: normal;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.home-page-category-grid .title a,
.sub-category-grid .title a {
    display: block;
    padding: 0 0 15px;
}

.home-page-category-grid .picture,
.sub-category-grid .picture {
    margin: 0;
}

.home-page-category-grid .picture a:before,
.sub-category-grid .picture a:before {
    padding-top: 65%;
}

.products-container {
    position: relative;
}

.products-container .ajax-products-busy, .filters-ajax-products-busy {
    display: none;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #ffffffab url(../../images/loading.gif) center no-repeat;
}

.product-grid,
.product-list {
    margin: 0 0 50px;
}

.product-grid .title {
    margin: 0 0 30px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
    font-size: 30px;
    font-weight: normal;
    color: #444;
}

.product-grid .title strong {
    font-weight: normal;
}

// .manufacturer-grid .title {
//     margin: 0 0 15px;
//     text-align: center;
//     font-size: 16px;
// }

// .manufacturer-grid .title a:hover,
// .manufacturer-grid .title a:focus {
//     color: #4ab2f1;
// }

// .manufacturer-grid .picture {
//     border: 1px solid #ddd;
// }

.vendor-grid .title {
    margin: 0 0 15px;
    text-align: center;
    font-size: 16px;
}

.vendor-grid .title a:hover,
.vendor-grid .title a:focus {
    color: #4ab2f1;
}

.vendor-grid .picture {
    border: 1px solid #ddd;
}

.pager {
    margin: 0 0 20px;
}

.pager ul {
    text-align: right;
    font-size: 0;
}

.pager li {
    display: inline-block;
    vertical-align: top;
}

.pager li a,
.pager li span {
    display: block;
    text-align: center;
    cursor: pointer;
}

.pager li.previous-page *,
.pager li.next-page *,
.pager li.first-page *,
.pager li.last-page * {
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    color: #fff;
}


/********** PRODUCT PAGE **********/


.product-details-page {
    padding: 20px 0 0;
}

// .product-essential {
    // margin: 0 0 40px;
    // border-bottom: 1px solid #ddd;
    // padding: 0 0 10px;
// }

.product-essential:after {
    content: "";
    display: block;
    clear: both;
}

// .gallery {
//     margin: 0 auto 50px;
// }

// .gallery .picture-wrapper {
//     margin: 0 0 10px;
// }

.gallery .picture {
    position: relative;
    max-width: 100%;
    margin: 0 auto 10px;
    overflow: hidden;
}

// .gallery .picture:before {
//     content: "";
//     display: block;
//     padding-top: 100%;
// }

.gallery .picture img,
.gallery .picture-thumbs img,
.variant-picture img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.gallery .picture-thumbs {
    overflow: hidden;
    font-size: 0;
}

.gallery .picture-thumbs .thumb-item {
    // display: inline-block;
    position: relative;
    // width: 100px;
    // height: 100px;
    // margin: 0 5px 10px;
    overflow: hidden;
    cursor: pointer;
}

.overview {
    position: relative;
    // margin: 0 0 50px;
}

.overview .discontinued-product {
    background: #f3f3f3;
    margin: 0 0 20px;
}

.overview .discontinued-product h4 {
    display: inline-block;
    font-size: 14px;
    padding: 17px 17px 17px 40px;
    background: url('../../images/discontinued-product-icon.png') no-repeat 13px center;
    color: #de444c;
}

.overview .product-name {
    margin: 0 0 25px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px;
}

// .overview .product-name h1 {
//     font-size: 30px;
//     font-weight: normal;
// }

.overview .short-description {
    margin: 0 0 25px;
    line-height: 22px;
    color: #666;
}

.product-no-reviews,
.product-reviews-overview {
    margin: 0 0 25px;
    color: #444;
}

.product-no-reviews a {
    text-decoration: underline;
}

.product-review-box {
    display: inline-block;
    margin: 0 0 8px;
}

.product-review-box .rating {
    width: 95px;
    height: 14px;
    background: url('../../images/rating1.png') repeat-x;
}

.product-review-box .rating div {
    height: 14px;
    background: url('../../images/rating2.png') repeat-x;
}

.product-review-links a {
    text-decoration: underline;
}

.product-review-links a:hover,
.product-review-links a:focus {
    color: #4ab2f1;
}

.overview .value,
.variant-overview .value {
    color: #444;
}

.overview .value a,
.variant-overview .value a {
    color: #4ab2f1;
}

.overview .value a:hover,
.overview .value a:focus,
.variant-overview .value a:hover,
.variant-overview .value a:focus {
    text-decoration: underline;
}

// .overview-buttons {
//     margin: 0 0 25px;
//     font-size: 0;
// }

// .overview-buttons div {
//     width: 80%;
//     margin: 0 auto 3px;
// }

// .overview .button-2,
// .variant-overview .button-2 {
//     display: block;
//     width: 100%;
//     border: none;
//     background-color: #eaeaea;
//     background-position: left center;
//     background-repeat: no-repeat;
//     padding: 12px 15px 12px 40px;
//     text-align: left;
//     font-size: 14px; /*reset zeroing*/
//     color: #666;
// }

// .overview .button-2:hover,
// .overview .button-2:focus,
// .variant-overview .button-2:hover,
// .variant-overview .button-2:focus {
//     background-color: #ddd;
// }

// .overview .add-to-compare-list-button {
//     background-image: url('../../images/compare-button.png');
// }

.overview .email-a-friend-button {
    background-image: url('../../images/email-button.png');
}

.overview .subscribe-button,
.variant-overview .subscribe-button {
    background-image: url('../../images/email-button.png');
}

.overview .download-sample-button,
.variant-overview .download-sample-button {
    background-image: url('../../images/download-button.png');
}

// .overview .add-to-wishlist-button,
// .variant-overview .add-to-wishlist-button {
//     background-image: url('../../images/wishlist-button.png');
// }

.overview .manufacturers {
    margin: 0 0 20px;
}

.overview .availability,
.variant-overview .availability {
    margin: 0 0 20px;
    overflow: hidden;
}

.overview .availability .stock,
.variant-overview .availability .stock {
    margin: 0 0 10px;
}

.overview .availability .back-in-stock-subscription,
.variant-overview .availability .back-in-stock-subscription {
    display: inline-block;
}

.overview .additional-details,
.variant-overview .additional-details {
    margin: 0 0 20px;
}

.overview .additional-details div,
.variant-overview .additional-details div {
    margin: 0 0 8px;
}

.overview .delivery,
.variant-overview .delivery {
    overflow: hidden;
}

.overview .free-shipping,
.variant-overview .free-shipping {
    display: inline-block;
    margin: 10px 0;
    background: url('../../images/shipping.png') left center no-repeat;
    padding: 2px 0 2px 28px;
    font-weight: bold;
    color: #444;
}

.overview .free-shipping.invisible,
.variant-overview .free-shipping.invisible {
    display: none;
}

.overview .delivery-date,
.variant-overview .delivery-date {
    margin: 10px 0;
}

.overview .min-qty-notification,
.variant-overview .min-qty-notification {
    margin: 10px 0;
    font-style: italic;
    color: #444;
}

.overview .download-sample,
.variant-overview .download-sample {
    display: inline-block;
    margin: 0 0 25px;
}

.overview .prices,
.variant-overview .prices {
    margin: 0 0 10px;
    overflow: hidden;
}

.variant-overview .prices > div {
    margin: 0 0 5px;
}

.overview .prices > div > span,
.variant-overview .prices > div > span {
    vertical-align: middle;
}

.overview .old-product-price,
.variant-overview .old-product-price,
.overview .non-discounted-price,
.variant-overview .non-discounted-price {
    font-size: 14px;
    // color: #999;
    text-decoration: line-through;
}

.overview .product-price,
.variant-overview .product-price {
    font-size: 20px;
    font-weight: bold;
    // color: #444;
}

.overview .tax-shipping-info a,
.variant-overview .tax-shipping-info a {
    color: #4ab2f1;
}

.overview .tax-shipping-info a:hover,
.overview .tax-shipping-info a:focus,
.variant-overview .tax-shipping-info a:hover,
.variant-overview .tax-shipping-info a:focus {
    text-decoration: underline;
}

.customer-entered-price {
    margin: 0 0 20px;
    overflow: hidden;
}

.customer-entered-price .price-input label {
    display: block;
    margin: 0 0 5px;
    font-weight: bold;
    color: #444;
}

.customer-entered-price .price-input input {
    width: 170px;
    text-align: center;
}

.customer-entered-price .price-range {
    margin: 5px 0 0;
    color: #777;
}

.tier-prices {
    margin: 0 0 20px;
}

.tier-prices .title {
    display: none;
}

.tier-prices table td {
    border: 1px solid #ddd;
    padding: 10px;
    color: #444;
}

.tier-prices .field-header {
    min-width: 80px;
}

.tier-prices .item-price {
    background-color: #f9f9f9;
    font-size: 16px;
    color: #e4434b;
}

.overview .add-to-cart {
    margin: 0 0 20px;
}

.overview .add-to-cart-panel,
.variant-overview .add-to-cart-panel {
    display: inline-block;
    position: relative;
}

.overview .add-to-cart-panel > *,
.variant-overview .add-to-cart-panel > * {
    float: left;
}

.variant-overview .qty-label {
    display: none !important;
}

.overview .qty-input,
.variant-overview .qty-input {
    width: 45px;
    height: 43px;
    padding: 0 12px;
    text-align: center;
    font-size: 15px;
    color: #444;
}

.overview .qty-dropdown,
.variant-overview .qty-dropdown {
    height: 43px;
    padding: 0 12px;
    font-size: 15px;
    color: #444;
}

// .overview .add-to-cart-button,
// .variant-overview .add-to-cart-button {
    // height: 43px;
    // border: none;
    // background-color: #4ab2f1;
    // padding: 0 24px;
    // font-size: 15px;
    // color: #fff;
    // text-transform: uppercase;
// }

// .overview .add-to-cart-button:hover,
// .overview .add-to-cart-button:focus,
// .variant-overview .add-to-cart-button:hover,
// .variant-overview .add-to-cart-button:focus {
//     background-color: #248ece;
// }

.product-share-button {
    display: inline-block;
    overflow: hidden;
}

.full-description {
    clear: both;
    line-height: 24px;
}

.product-collateral {
    margin: 0 0 75px;
}

.product-collateral .title {
    margin: 0 0 10px;
    font-size: 18px;
    color: #444;
}

.product-collateral .title strong {
    font-weight: normal;
}

.product-variant-line {
    margin: 0 0 30px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 20px;
}

.product-variant-line:after {
    content: "";
    display: block;
    clear: both;
}

.variant-picture {
    position: relative;
    width: 220px;
    height: 220px;
    margin: 0 auto 15px;
    overflow: hidden;
}

.variant-overview {
    width: 100%;
}

.variant-overview .variant-name {
    margin: 0 0 10px;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: #4ab2f1;
}

.variant-overview .variant-description {
    margin: 0 0 15px;
    line-height: 22px;
}

.variant-overview .add-to-wishlist {
    margin: 0 0 25px;
}

.variant-overview .add-to-wishlist-button {
    display: inline-block;
    width: auto;
}

.variant-overview .add-to-cart {
    margin: 0 0 10px;
}

.product-specs-box {
    margin: 0 0 40px;
}

.product-specs-box td {
    border: 1px solid #ddd;
    padding: 10px;
}

.product-specs-box td.spec-group-name {
    border: 0;
    padding-top: 20px;
    font-size: 16px;
    color: #444;
}

.product-tags-list {
    font-size: 0;
}

.product-tags-list li {
    display: inline-block;
    font-size: 14px; /*reset zeroing*/
}

.product-tags-list li.separator {
    margin: 0 5px 0 3px;
}

.product-tags-list a {
    display: inline-block;
    line-height: 24px;
}

.product-tags-list a:hover,
.product-tags-list a:focus {
    color: #444;
}

.attributes {
    margin: 20px 0;
}

.attributes dl {
    overflow: hidden;
    padding: 0 2px; /* fix for Chrome in Mac, the checkboxes get cut off */
}

.attributes dt,
.attribute-label {
    display: block;
    margin: 0 0 5px;
    white-space: nowrap;
    font-weight: bold;
    color: #444;
}

.attributes dd,
.attribute-data {
    margin: 0 0 10px;
}

.attributes li {
    margin: 8px 0 12px;
}

.attributes select {
    width: auto;
    min-width: 100px;
    max-width: 100%;
}

.attributes .option-list label {
    margin: 0 5px;
}

.attributes .datepicker {
    width: 280px;
    text-align: center;
    color: #444;
}

.attributes .qty-box input {
    width: 48px;
    height: 32px;
    text-align: center;
}

.attributes .option-list:not(.attribute-squares) .disabled {
    opacity: .5;
}

.attributes .attribute-squares .disabled .attribute-square {
    cursor: default;
    opacity: .25;
}

.attributes .attribute-squares .disabled .attribute-square-container {
    background: linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 1.5px), #e91e63 50%, rgba(0,0,0,0) calc(50% + 1.5px), rgba(0,0,0,0) 100%), linear-gradient(to top right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 1.5px), #e91e63 50%, rgba(0,0,0,0) calc(50% + 1.5px), rgba(0,0,0,0) 100%);
}

.attributes .attribute-squares .tooltip-container .not-available-text {
    font-size: 12px;
}

.attributes .attribute-squares.color-squares .tooltip-container .not-available-text {
    margin: 5px;
}

.attributes .attribute-squares.color-squares .tooltip-container {
    width: 120px;
    display: none;
}

.attributes .attribute-squares.color-squares li:hover .tooltip-container {
    margin-left: -42px;
}

.attributes .attribute-squares.color-squares .disabled .tooltip-container {
    display: block;
}

.attributes .attribute-squares.image-squares .tooltip-container .not-available-text {
    margin-bottom: 5px;
    display: none;
}

.attributes .attribute-squares.image-squares .disabled .tooltip-container .not-available-text {
    display: block;
}

.attributes select option.disabled,
.attributes select option:disabled {
    color: #cecece;
}

.ui-datepicker {
    width: 280px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    text-align: center;
    /*override jQuery UI styles, do not delete doubled properties*/
    border: none;
    border-radius: 0;
    padding: 0;
    font: normal 14px Arial, Helvetica, sans-serif;
    color: #777;
}

.ui-datepicker-header {
    position: relative;
    height: 32px;
    background-color: #4ab2f1;
    color: #fff;
    /*override jQuery UI styles, do not delete doubled properties*/
    border: none;
    border-radius: 0;
    background-image: none;
    padding: 0 !important;
    font-weight: normal;
}

.ui-datepicker-header a {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 0;
    /*override jQuery UI styles, do not delete doubled properties*/
    top: 0 !important;
    width: 32px !important;
    height: 32px !important;
    border: none !important;
}

.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    background: url('../../images/calendar-prev.png') center no-repeat;
}

.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url('../../images/calendar-next.png') center no-repeat;
}

.ui-datepicker-header a span {
    /*override jQuery UI styles, do not delete doubled properties*/
    display: none !important;
}

.ui-datepicker-title {
    position: relative;
    z-index: 0;
    line-height: 32px;
    font-weight: bold;
    text-transform: uppercase;
    /*override jQuery UI styles, do not delete doubled properties*/
    line-height: 32px !important;
}

.ui-datepicker-calendar {
    /*override jQuery UI styles, do not delete doubled properties*/
    margin: 0 !important;
    font-size: 14px !important;
}

.ui-datepicker-calendar th {
    background-color: #eee;
}

.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
    width: 14.285%;
    border: 1px solid #ddd;
    /*override jQuery UI styles, do not delete doubled properties*/
    padding: 0;
}

.ui-datepicker-calendar th span,
.ui-datepicker-calendar td a {
    display: block;
    min-height: 32px;
    line-height: 32px;
    color: #444;
}

.ui-datepicker-calendar td a {
    /*override jQuery UI styles, do not delete doubled properties*/
    border: none !important;
    background: none !important;
    padding: 0;
    text-align: center;
    color: #444 !important;
}

.ui-datepicker-calendar .ui-state-active {
    outline: 2px solid #4ab2f1;
    font-weight: bold;
    color: #4ab2f1;
    /*override jQuery UI styles, do not delete doubled properties*/
    color: #4ab2f1 !important;
}

.attribute-squares {
    font-size: 0;
    display: inline-block;
}

.attribute-squares li {
    display: inline-block;
    margin: 0 5px 5px;
    text-align: center;
}

.attribute-squares .attribute-square-container {
    display: block;
    position: relative;
    z-index: 0;
    padding: 1px;
}

.attribute-squares label {
    display: block;
    margin: 0 !important;
    overflow: hidden;
}

.attribute-squares .attribute-square {
    display: inline-block !important;
    width: 32px !important;
    height: 32px !important;
    border: 1px solid #444 !important;
    cursor: pointer;
}

.attribute-squares .selected-value .attribute-square-container {
    background-color: #4ab2f1;
}

.attribute-squares li input {
    display: none;
}

.tooltip-container {
    position: absolute;
    width: 200px;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #eeeeee;
    text-align: center;
    visibility: hidden;
    border-radius: 5px;
}

.tooltip-container:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-bottom: 7px solid #eeeeee;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
}

.tooltip-container:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-bottom: 6px solid #FFFFFF;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

.attribute-squares li:hover .tooltip-container,
.attribute-squares li:focus .tooltip-container {
    visibility: visible;
    margin-left: -83px;
    margin-top: 5px;
    z-index: 999;
}

.tooltip-container .tooltip-header {
    background-color: #F6F6F6;
    height: 25px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding-left: 10px;
    padding-top: 5px;
    text-align: left;
    width: 198px;
    font-size: 15px;
    border-bottom: 1px #EEEEEE solid;
}

.tooltip-container .tooltip-body {
    margin: 5px;
}

.tooltip-container .tooltip-body img {
    border: 0px #FFF;
    width: 190px;
}

.giftcard {
    margin: 0 0 20px;
    overflow: hidden;
}

.giftcard div {
    margin: 0 0 10px;
}

.giftcard label {
    display: block;
    margin: 0 auto 5px;
    font-weight: bold;
    color: #444;
}

.giftcard input,
.giftcard textarea {
    width: 350px;
    max-width: 100%;
}

.giftcard textarea {
    height: 150px;
}


/********** PRODUCT REVIEWS **********/



.product-reviews-page h1 a {
    color: #4ab2f1;
}

.product-reviews-page h1 a:before,
.product-reviews-page h1 a:after {
    content: "''";
}

.product-reviews-page .title {
    margin: 0 0 20px;
    font-size: 20px;
    color: #444;
}

.product-reviews-page .title strong {
    font-weight: normal;
}

.write-review {
    margin: 0 0 60px;
}

.write-review .fieldset {
    margin: 0 0 30px;
}

.tooltip {
    position: initial;
    display: inline-block;
    border-bottom: 1px dotted black;
    color: #444;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background: #f0f0f0 no-repeat 100% 5%;
    border: #c0c0c0 1px dotted;
    text-align: Left;
    padding: 10px 10px 5px 5px;
    position: absolute;
    z-index: 1;
    left: 10%;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
}

.tooltip:hover .tooltiptext,
.tooltip:focus .tooltiptext {
    visibility: visible;
    opacity: 1;
    cursor: help;
}

.write-review .review-rating {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
}

.write-review .review-rating label {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
    color: #444;
}

.write-review .review-rating div.first {
    color: #e4444c;
}

.write-review .review-rating div.rating-options {
    padding-top: 2px;
}

.write-review .review-rating div.rating-options input {
    vertical-align: baseline;
}

.write-review .review-rating div.last {
    color: #4cb17c;
}

.write-review .captcha-box {
    margin: 15px 0 0;
}

.write-review .button-1 {
    // border: none;
    // background-color: #4ab2f1;
    padding: 10px 25px;
    text-align: center;
    // font-size: 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .write-review .button-1:hover,
// .write-review .button-1:focus {
//     background-color: #248ece;
// }

.product-reviews-page .result,
.product-reviews-page .message-error {
    margin: 0 0 20px;
}

.product-review-list {
    overflow: hidden;
}

.product-review-item {
    margin: 0 0 40px;
}

.product-review-item .review-item-head {
    border-top: 1px solid #ddd;
    overflow: hidden;
    background-color: #f6f6f6;
    padding: 10px;
}

.product-review-item .review-title {
    line-height: 20px;
    font-size: 15px;
    color: #444;
}

.product-review-item .product-review-box {
    margin: 3px 0 0;
}

.product-review-item .review-content {
    padding: 20px 10px;
}

.product-review-item .review-text {
    margin: 0 0 15px;
    line-height: 22px;
    color: #444;
}

.product-review-item .avatar {
    margin: 0 0 10px;
}

.product-review-item .reply {
    margin: 15px 0;
    border-top: 1px solid #ddd;
}

.product-review-item .reply-header {
    margin: 15px 0;
    font-weight: bold;
}

.product-review-item .reply-text {
    line-height: 22px;
}

.product-review-item .review-info {
    margin: 0 0 5px;
}

.product-review-item .review-info label {
    font-weight: normal;
}

.product-review-item .review-info a,
.product-review-helpfulness .vote {
    display: inline-block;
    padding: 0 5px;
    color: #4ab2f1;
    cursor: pointer;
}

.product-review-helpfulness .question {
    margin: 0 5px 0 0;
    font-style: italic;
}

.product-review-helpfulness .result {
    margin: 0 0 0 10px;
}

.product-review-item .review-type-rating-ext {
    overflow: hidden;
    margin: 0 0 20px;
}


/********** WISHLIST & COMPARE LIST **********/



// .wishlist-page .page-title {
//     margin: 0 0 -1px;
// }

.wishlist-content {
    margin: 0 0 20px;
}

.wishlist-content .message-error {
    margin: 20px 0;
}

.wishlist-content .table-wrapper {
    margin: 0 0 30px;
}

.wishlist-content .product-content {
    min-width: 300px;
    text-align: left;
}

.wishlist-content .tax-shipping-info {
    margin: 0 0 20px;
    text-align: center;
}

.wishlist-content .tax-shipping-info a {
    color: #4ab3f1;
}

.wishlist-content .tax-shipping-info a:hover,
.wishlist-content .tax-shipping-info a:focus {
    text-decoration: underline;
}

.wishlist-content .buttons {
    font-size: 0;
}

// .wishlist-content .button-2 {
//     display: block;
//     width: 250px;
//     margin: 0 auto 3px;
//     border: none;
//     padding: 10px 25px;
//     text-align: center;
//     line-height: 20px;
//     font-size: 15px;
//     color: #fff;
// }

// .wishlist-content .update-wishlist-button,
// .wishlist-content .wishlist-add-to-cart-button {
//     background-color: #4ab2f1;
//     text-transform: uppercase;
// }

// .wishlist-content .update-wishlist-button:hover,
// .wishlist-content .update-wishlist-button:focus,
// .wishlist-content .wishlist-add-to-cart-button:hover,
// .wishlist-content .wishlist-add-to-cart-button:focus {
//     background-color: #248ece;
// }

// .wishlist-content .email-a-friend-wishlist-button {
//     background-color: #aaa;
// }

// .wishlist-content .email-a-friend-wishlist-button:hover,
// .wishlist-content .email-a-friend-wishlist-button:focus {
//     background-color: #999;
// }

.wishlist-page .share-info {
    text-align: center;
}

.wishlist-page .share-info span {
    display: block;
    margin: 0 0 5px;
}

.wishlist-page .share-info a {
    font-weight: bold;
    // color: #444;
}

.wishlist-page .share-info a:hover,
.wishlist-page .share-info a:focus {
    color: #4ab2f1;
}

.wishlist-page .no-data {
    margin: 25px 0 0;
}

.compare-products-page {
    position: relative;
    overflow: hidden;
}

// .compare-products-page .clear-list {
//     display: inline-block;
//     margin: 0 0 20px;
//     background-color: #4ab2f1;
//     padding: 10px 15px;
//     font-size: 12px;
//     color: #fff;
//     text-transform: uppercase;
// }

// .compare-products-page .clear-list:hover,
// .compare-products-page .clear-list:focus {
//     background-color: #248ece;
// }

.compare-products-page .table-wrapper {
    margin: 0 0 30px;
    padding: 0 0 1px; /*IE mobile table border fix*/
}

// .compare-products-table td {
//     min-width: 150px;
//     border: 1px solid #ddd;
//     background-color: #fff;
//     padding: 20px;
//     text-align: left;
//     vertical-align: top;
//     color: #444;
// }

.compare-products-table td:first-child {
    min-width: 0;
}

// .compare-products-table td label {
//     font-weight: normal;
//     color: #777;
// }

// .compare-products-table .specification-group td {
//     border: 0;
//     padding: 20px 10px 10px 10px;
// }

// .compare-products-table .specification-group td label {
//     font-size: 16px;
//     color: #444;
// }

.compare-products-table .remove-button {
    // display: inline-block;
    // border: none;
    background: url('../../images/remove.png') left center no-repeat;
    // padding: 0 0 0 18px;
    // font-size: 12px;
    // color: #666;
}

// .compare-products-table .remove-button:hover,
// .compare-products-table .remove-button:focus {
//     color: #4ab2f1;
// }

.compare-products-table .picture {
    display: block;
    position: relative;
    max-width: 200px;
    height: 200px;
    overflow: hidden;
}

.compare-products-table .picture img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.compare-products-table .product-name td {
    font-weight: bold;
}

.compare-products-table .product-name td a:hover,
.compare-products-table .product-name td a:focus {
    color: #4ab2f1;
}

.compare-products-table .short-description td {
    line-height: 22px;
}

.compare-products-table .full-description td {
    display: none;
}

.compare-products-page .no-data {
    margin: 25px 0 0;
}


/*********** TOPICS ***********/



.topic-block {
    margin: 0 0 25px;
}

.topic-block-title {
    min-height: 50px;
    margin: 0 0 25px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
}

.topic-block-title h2 {
    font-size: 30px;
    font-weight: normal;
}

.topic-block-body {
    text-align: justify;
    // line-height: 24px;
    // font-size: 16px;
}

.topic-page .page-body {
    text-align: justify;
    // line-height: 22px;
}

.topic-block a,
.topic-page a {
    text-decoration: underline;
}

// .topic-block strong,
// .topic-page strong {
//     color: #444;
// }

.home-page .topic-block {
    margin: 0 0 50px;
}

.popup-window .topic-page {
    padding: 15px;
}

.not-found-page p {
    margin: 30px 15px;
}

.topic-password {
    text-align: center;
}

.enter-password-title {
    margin: 0 0 20px;
    color: #444;
}

.enter-password-form {
    display: inline-block;
    overflow: hidden;
}

.enter-password-form input[type="password"] {
    float: left;
    width: 200px;
    height: 40px;
    margin: 0 -1px 0 0;
}

.enter-password-form button[type="submit"] {
    float: left;
    width: auto;
    min-width: 86px;
    height: 40px;
    // border: none;
    // background-color: #4ab2f1;
    padding: 0 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .enter-password-form button[type="submit"]:hover,
// .enter-password-form button[type="submit"]:focus {
//     background-color: #248ece;
// }


/*********** CONTACT & EMAIL A FRIEND & APPLY FOR VENDOR ACCOUNT ***********/



// .contact-page .page-title,
// .email-a-friend-page .page-title,
// .apply-vendor-page .page-title {
//     margin: 0 0 -1px;
// }

.contact-page .result,
.contact-page .message-error,
.email-a-friend-page .result,
.email-a-friend-page .message-error,
.apply-vendor-page .result,
.apply-vendor-page .message-error {
    margin: 20px 0;
}

.contact-page .button-1,
.email-a-friend-page .button-1,
.apply-vendor-page .button-1 {
    min-width: 140px;
    // border: none;
    // background-color: #4ab2f1;
    padding: 10px 30px;
    text-align: center;
    // font-size: 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .contact-page .button-1:hover,
// .contact-page .button-1:focus,
// .email-a-friend-page .button-1:hover,
// .email-a-friend-page .button-1:focus,
// .apply-vendor-page .button-1:hover,
// .apply-vendor-page .button-1:focus {
//     background-color: #248ece;
// }

.contact-page .topic-block,
.email-a-friend-page .title,
.apply-vendor-page .title {
    margin: 25px 0;
}

.email-a-friend-page .title h2 {
    font-weight: normal;
    color: #4ab2f1;
}

.apply-vendor-page .terms-of-service {
    text-align: center;
}

.apply-vendor-page .terms-of-service > * {
    display: inline-block;
    line-height: 32px;
}

.apply-vendor-page .terms-of-service span,
.apply-vendor-page .terms-of-service a {
    color: #4ab2f1;
    cursor: pointer;
}

/********** REGISTRATION, LOGIN, ACCOUNT PAGES  **********/



.gender {
    display: inline-block;
}

.gender span {
    display: inline-block;
    margin: 0 5px;
}

.gender span > * {
    display: inline !important;
    margin: 0 5px;
    line-height: 32px;
}

.date-of-birth .date-picker-wrapper {
    display: inline;
}

.date-of-birth select {
    width: 31%; /*always single line*/
    max-width: 126px;
}

.date-of-birth select + select {
    margin: 0 0 0 10px;
}

.date-of-birth .select-wrapper + .select-wrapper {
    margin: 0 0 0 10px;
}

#check-availability-button {
    margin: 10px 0 0;
    // border: none;
    background-color: #888;
    padding: 10px 15px;
    // font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

#check-availability-button:hover,
#check-availability-button:focus {
    background-color: #999;
}

.inputs.accept-consent {
    text-align: center;
}

.inputs.accept-consent > * {
    display: inline-block;
    line-height: 32px;
}

.inputs.accept-consent span,
.inputs.accept-consent a {
    color: #4ab2f1;
    cursor: pointer;
    font-size: 14px;
}

.inputs.accept-consent label {
    width: auto;
    margin-left: 10px;
    margin-bottom: 0;
}

// .registration-page .button-1,
// .registration-result-page .button-1,
// .login-page .button-1,
// .password-recovery-page .button-1,
// .email-revalidation-page .button-1,
// .account-activation-page .button-1,
// .account-page .button-1,
// .return-request-page .button-1 {
//     min-width: 140px;
//     border: none;
//     background-color: #4ab2f1;
//     padding: 10px 30px;
//     text-align: center;
//     font-size: 15px;
//     color: #fff;
//     text-transform: uppercase;
// }

// .registration-page .button-1:hover,
// .registration-page .button-1:focus,
// .registration-result-page .button-1:hover,
// .registration-result-page .button-1:focus,
// .login-page .button-1:hover,
// .login-page .button-1:focus,
// .password-recovery-page .button-1:hover,
// .password-recovery-page .button-1:focus,
// .email-revalidation-page .button-1:hover,
// .email-revalidation-page .button-1:focus,
// .account-activation-page .button-1:hover,
// .account-activation-page .button-1:focus,
// .account-page .button-1:hover,
// .account-page .button-1:focus,
// .return-request-page .button-1:hover,
// .return-request-page .button-1:focus {
//     background-color: #248ece;
// }

.return-request-page h1 a {
    color: #4ab2f1;
}

.return-request-page .return-request-file {
    font-size: 14px;
}

.return-request-page .return-request-file .uploadedfileinput {
    display: inline-block;
    vertical-align: middle;
}

.registration-page .message-error,
.login-page .message-error,
.account-page .message-error {
    margin: 0 0 10px;
}

.registration-result-page .result {
    margin: 0 0 30px;
    padding: 30px 0;
    text-align: center;
    // font-size: 16px;
    color: #4cb17c;
}

.customer-info-page .email-to-revalidate,
.customer-info-page .readonly-username,
.customer-info-page .vat-status {
    margin: 0 5px;
    font-size: 14px;
}

.customer-info-page .vat-status {
    display: block;
}

.registration-page .vat-note,
.customer-info-page .vat-note,
.customer-info-page .email-to-revalidate-note {
    display: block;
    font-size: 14px;
    margin-top: 7px;
    white-space: normal;
}

.login-page .customer-blocks {
    margin: 0 0 30px;
}

// .login-page .title {
//     margin: 0 0 15px;
//     font-size: 20px;
//     color: #444;
// }

.login-page .title strong {
    font-weight: normal;
}

.login-page .new-wrapper {
    margin: 0 0 50px;
}

.login-page .new-wrapper .text {
    margin: 0 0 30px;
    // border-top: 1px solid #e6e6e6;
    // background-color: #f9f9f9;
    // padding: 30px;
    // line-height: 22px;
}

.login-page .new-wrapper .topic-block {
    margin: 0;
}

// .login-page .new-wrapper .topic-block-body strong {
//     color: #444;
// }

// .login-page .new-wrapper .button-1 {
//     margin: 0 0 5px;
// }

.login-page .returning-wrapper {
    margin: 0;
}

// .login-page .returning-wrapper .form-fields {
//     overflow: hidden;
//     padding: 30px 15px 0;
// }

.login-page .returning-wrapper .inputs.reversed {
    white-space: normal;
}

// .login-page .returning-wrapper .inputs.reversed * {
//     display: inline-block;
//     width: auto;
//     margin: 5px;
//     font-size: 14px; /*reset zeroing*/
//     color: #444;
// }

// .login-page .returning-wrapper .inputs.reversed a:hover,
// .login-page .returning-wrapper .inputs.reversed a:focus {
//     color: #4ab2f1;
// }

.login-page .captcha-box {
    margin: -15px 0 20px;
}

// .login-page .returning-wrapper .buttons {
//     background-color: #fff;
//     padding: 30px 0;
// }

.external-authentication {
    margin: 0 0 50px;
    overflow: hidden;
    text-align: center;
}

.external-authentication .title {
    margin: 0 0 15px;
    font-size: 20px;
    color: #444;
}

.external-authentication .buttons {
    margin: 0 0 30px;
    border-top: 1px solid #e6e6e6;
    padding: 30px;
}

.external-auth-errors {
    color: #e4444c;
}

.external-auth-association {
    color: #444;
}

.external-auth-association a {
    color: #4ab2f1;
}

.external-auth-association a:hover,
.external-auth-association a:focus {
    text-decoration: underline;
}

.password-recovery-page .result {
    margin: 0 0 5px;
    font-weight: bold;
    color: #444;
}

.password-recovery-page .tooltip {
    margin: 0 0 25px;
}

.add-more-external-records {
    text-align: center;
}

.add-more-external-records a {
    color: #4ab2f1;
}

.add-more-external-records a:hover,
.add-more-external-records a:focus {
    text-decoration: underline;
}

.account-page .buttons {
    text-align: center;
}

.account-page .description {
    margin: 0 0 25px;
    padding: 0 10px;
}

.account-page .table-wrapper {
    margin: 0 0 30px
}

.gdpr-tools-page .result {
    margin: 20px 0;
    font-size: 13px;
    color: #e4434b;
}

.gdpr-tools-page .buttons {
    margin-top: 20px;
}

.address-list-page .info,
.order-list-page .info {
    margin: 0 0 10px;
    // border-top: 1px solid #e6e6e6;
    // background-color: #f9f9f9;
    // padding: 20px;
    line-height: 26px;
    // color: #444;
}

.address-list-page .info .name,
.order-list-page .info .name {
    // font-size: 16px;
    font-weight: bold;
}

// .address-list-page .button-2,
// .order-list-page .button-2 {
//     display: inline-block;
//     margin: 0 0 0 10px;
//     border: none;
//     padding: 0 0 0 18px;
//     font-size: 12px;
//     color: #444;
// }

// .edit-address-button {
//     background: url('../../images/edit.png') left center no-repeat;
// }

// .delete-address-button,
// .cancel-recurring-order-button {
//     background: url('../../images/remove.png') left center no-repeat;
// }

// .retry-recurring-order-button {
//     background: url('../../images/retry.png') left center no-repeat;
// }

// .return-items-button {
//     background: url('../../images/return.png') left center no-repeat;
// }

// .order-details-button {
//     background: url('../../images/details.png') left center no-repeat;
// }

// .address-list-page .button-2:hover,
// .address-list-page .button-2:focus,
// .order-list-page .button-2:hover,
// .order-list-page .button-2:focus {
//     color: #4ab2f1;
// }

.address-list-page .add-button {
    margin: 0 0 20px;
    text-align: center;
}

// .address-edit-page .page-title {
//     margin: 0 0 -1px;
// }

.address-edit-page .message-error {
    margin: 20px 0;
}

// .address-edit-page .edit-address {
//     margin: 0 0 30px;
//     border-top: 1px solid #e6e6e6;
//     background-color: #f9f9f9;
//     padding: 30px 15px;
// }

.recurring-payments table td {
    white-space: nowrap;
}

.recurring-payments table td:first-child {
    min-width: 120px;
    white-space: normal;
}

.recurring-payments .button-2 {
    font-size: 14px;
}

.return-request-list-page .details {
    border-top: 1px solid #e6e6e6;
    background-color: #f9f9f9;
    padding: 20px;
    line-height: 22px;
    color: #444;
}

.return-request-list-page label {
    margin: 0 5px 0 0;
}

.return-request-list-page a {
    color: #4ab2f1;
}

.return-request-list-page a:hover,
.return-request-list-page a:focus {
    text-decoration: underline;
}

.return-request-list-page .comments div {
    margin: 10px 0 0;
    font-size: 15px;
    font-style: italic;
    color: #444;
}

.downloadable-products-page table td {
    color: #444;
}

.reward-points-overview {
    margin: 0 0 25px;
}

.reward-points-history table td {
    color: #444;
}

.reward-points-history table td:first-child {
    min-width: 120px;
}

// .change-password-page .page-title {
//     margin: 0 0 -1px;
// }

.change-password-page .result,
.change-password-page .message-error {
    margin: 20px 0;
}

.change-password-page .fieldset {
    margin: 0 0 30px;
}

.avatar-page .page-title {
    margin: 0;
}

.avatar-page .page-body {
    margin: 0 0 30px;
    overflow: hidden;
    background-color: #f9f9f9;
    padding: 30px 25px;
}

.avatar-page .message-error {
    margin: 0 0 10px;
}

.avatar-page .image {
    margin: 0 0 10px;
}

.avatar-page .image img {
    border: 1px solid #ccc;
}

// .avatar-page .button-1 {
//     font-size: 14px;
// }

.avatar-page .button-2 {
    border: none;
    background-color: #888;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}

.avatar-page .button-2:hover,
.avatar-page .button-2:focus {
    background-color: #999;
}

.avatar-page .info {
    color: #444;
}

.vendorinfo-page .button-2 {
    border: medium none;
    background-color: #888;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}

.vendorinfo-page .button-2:hover,
.vendorinfo-page .button-2:focus {
    background-color: #999;
}

.vendorinfo-page .vendor-picture {
    width: 400px;
    margin: 0 auto;
}

.vendorinfo-page .vendor-picture * {
    display: block;
    margin-top: 15px;
}

// .check-gift-card-balance-page .page-title {
//     margin: 0 0 -1px;
// }

.check-gift-card-balance-page .result,
.check-gift-card-balance-page .message-error {
    margin: 20px 0;
}

.check-gift-card-balance-page .fieldset {
    margin: 0 0 30px;
}


/********** SHOPPING CART **********/



.order-progress {
    margin: 0 0 50px;
    background-color: #f6f6f6;
    padding: 15px 0;
    text-align: center;
}

.order-progress ul {
    font-size: 0;
}

.order-progress li {
    display: inline-block;
    margin: 10px;
}

.order-progress a {
    display: block;
    min-width: 70px;
    font-size: 14px; /*reset zeroing*/
    color: #444;
}

.order-progress a:before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 15px;
    border: 1px solid #bbb;
    background: #fff url('../../images/pointer.png') center no-repeat;
}

.order-progress li.active-step a {
    cursor: pointer;
}

.order-progress li.inactive-step a {
    opacity: 0.3;
    cursor: default;
}

// .shopping-cart-page .page-title {
//     margin: 0 0 -1px;
//     padding: 10px;
// }

.shopping-cart-page .message-error {
    margin: 20px 0;
}

.shopping-cart-page td .message-error {
    margin: 10px 0 0;
    text-align: left;
}

.shopping-cart-page .checkout-attributes a {
    color: #4ab3f1;
}

.shopping-cart-page .checkout-attributes a:hover,
.shopping-cart-page .checkout-attributes a:focus {
    text-decoration: underline;
}

.shopping-cart-page .tax-shipping-info {
    margin: 20px 0;
    text-align: center;
}

.shopping-cart-page .tax-shipping-info a {
    color: #4ab3f1;
}

.shopping-cart-page .tax-shipping-info a:hover,
.shopping-cart-page .tax-shipping-info a:focus {
    text-decoration: underline;
}

.shopping-cart-page .common-buttons {
    margin: 0 0 60px;
    // background-color: #f6f6f6;
    padding: 20px;
    font-size: 0;
}

// .shopping-cart-page .common-buttons button {
//     display: inline-block;
//     min-width: 180px;
//     margin: 5px;
//     // border: none;
//     background-color: #aaa;
//     padding: 10px 20px;
//     // font-size: 15px;
//     color: #fff;
// }

// .shopping-cart-page .common-buttons button:hover,
// .shopping-cart-page .common-buttons button:focus {
//     background-color: #999;
// }

// .shopping-cart-page .common-buttons button:first-child {
//     background-color: #4ab2f1;
// }

// .shopping-cart-page .common-buttons button:first-child:hover,
// .shopping-cart-page .common-buttons button:first-child:focus {
//     background-color: #248ece;
// }

.checkout-attributes {
    margin: -30px 0 30px;
    color: #444;
}

.checkout-attributes dt {
    margin: 0 0 5px;
    font-weight: bold;
}

.checkout-attributes dd {
    margin: 0 0 20px;
}

.checkout-attributes input[type="text"],
.checkout-attributes select {
    min-width: 170px;
}

.checkout-attributes ul {
    font-size: 0;
}

.checkout-attributes li {
    display: inline-block;
    margin: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 8px 10px;
    font-size: 14px; /*reset zeroing*/
}

.checkout-attributes .attribute-squares li {
    border: none;
    padding: 0;
    line-height: 0;
}

.checkout-attributes li label {
    display: inline-block;
    margin: 0 0 0 5px;
}

.selected-checkout-attributes {
    margin: 30px 0;
    color: #444;
}

.selected-checkout-attributes br {
    content: "";
    display: block;
    margin: 3px;
}

.cart-footer {
    margin: 0 0 50px;
    // border-top: 1px solid #ddd;
}

.cart-footer:after {
    content: "";
    display: block;
    clear: both;
}

.cart-footer .totals {
    // width: 350px;
    max-width: 100%;
    // margin: 0 auto 50px;
    // background-color: #f9f9f9;
    // padding: 10px;
    // color: #444;
}

// .cart-footer .total-info {
//     margin: 0 0 20px;
//     border-bottom: 1px solid #ddd;
//     padding: 5px 30px 15px;
// }

.cart-total td {
    width: 50%;
    padding: 5px 0;
    text-align: right;
}

.cart-total td:first-child {
    text-align: left;
}

.cart-total .giftcard-remaining {
    display: block;
    font-style: italic;
}

// .cart-total .order-total {
//     font-size: 20px;
//     color: #4ab2f1;
// }

.cart-total .order-total strong {
    font-weight: normal;
}

.cart-total .earn-reward-points {
    font-style: italic;
}

.min-amount-warning {
    margin: 0 0 20px;
    padding: 0 30px;
    color: #e4434b;
}

.terms-of-service {
    margin: 0 0 20px;
    // padding: 0 30px;
}

.terms-of-service label {
    cursor: pointer;
}

.terms-of-service a {
    margin: 0 0 0 3px;
    // color: #4ab2f1;
}

// .cart-footer .checkout-buttons {
//     padding: 0 10px;
// }

.cart-footer .checkout-disabled {
    font-size: 20px;
    text-align: center;
    background: #ffa500;
    border: 1px solid #c3891e;
    color: #fff;
    padding: 15px 50px;
}

.cart-footer .checkout-button {
    display: inline-block;
    min-width: 160px;
    // border: none;
    // background-color: #4ab2f1;
    padding: 10px 20px;
    // font-size: 16px;
    // color: #fff;
    text-transform: uppercase;
}

// .cart-footer .checkout-button:hover,
// .cart-footer .checkout-button:focus {
//     background-color: #248ece;
// }

.cart-footer .addon-buttons {
    margin: 20px 0 0;
    padding: 0 10px;
}

.cart-collaterals {
    width: 350px;
    max-width: 100%;
    margin: 0 auto 50px;
    padding: 25px 0 0;
}

// .cart-collaterals > div {
//     max-width: 400px;
//     margin: 0 0 50px;
// }

.cart-collaterals .button-2 {
    display: inline-block;
    border: none;
    background-color: #aaa;
    padding: 10px 15px;
    color: #fff;
    text-transform: uppercase;
}

.cart-collaterals .button-2:hover,
.cart-collaterals .button-2:focus {
    background-color: #999;
}

// .cart-collaterals .title {
//     margin: 0 0 5px;
//     font-size: 18px;
//     color: #444;
// }

.cart-collaterals .title strong {
    font-weight: normal;
}

.cart-collaterals .hint {
    margin: 0 0 10px;
}

.cart-collaterals .deals > div {
    margin: 0 0 20px;
}

.cart-collaterals .coupon-code {
    overflow: hidden;
}

// .cart-collaterals .coupon-code input[type="text"] {
//     float: left;
//     width: 60%;
//     height: 36px; /*safari padding fix*/
//     margin: 0 -1px 0 0;
// }

// .cart-collaterals .coupon-code button[type="submit"] {
//     float: left;
//     width: 40%;
//     height: 36px; /*safari padding fix*/
//     padding: 0;
// }

.cart-collaterals .message-failure {
    margin: 5px 0 0;
    font-size: 12px;
    color: #e4434b;
}

.cart-collaterals .message-success {
    margin: 5px 0 0;
    font-size: 12px;
    color: #4cb17c;
}

.cart-collaterals .current-code {
    margin: 5px 0 0;
    color: #4cb17c;
}

.remove-discount-button,
.remove-gift-card-button {
    width: 15px;
    height: 15px;
    border: 1px solid #999;
    background: #fff url('../../images/close.png') center no-repeat;
    cursor: pointer;
}

.cart-collaterals .shipping select {
    height: 36px;
}

.cart-collaterals .shipping-results {
    margin: 30px 0 0;
}

.cart-collaterals .shipping-results li {
    margin: 20px 0;
}

.cart-collaterals .shipping-results li strong {
    display: block;
    margin: 0 0 5px;
    color: #444;
}

.shopping-cart-page .no-data {
    margin: 25px 0 0;
}


/*** ESTIMATE SHIPPING POPUP COMMON ***/


.estimate-shipping-popup {
    position: relative;
    background: #FFF;
    padding: 25px 10px;
    width: auto;
    max-width: 800px;
    margin: 0 auto;
}

.estimate-shipping-popup-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}

.estimate-shipping-popup-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.estimate-shipping-popup-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1);
}

.estimate-shipping-popup-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.estimate-shipping-popup-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0;
}

.estimate-shipping-popup-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}

.shipping-options-loading {
    background: url(../../images/ajax-loader-small.gif) no-repeat;
    width: 16px;
    height: 16px;
    position: relative;
    right: 8px;
    margin: 4% 50%;
}

.shipping-address .required {
    margin-left: 0px;
}

.estimate-shipping-row {
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.estimate-shipping-row.shipping-option {
    cursor: pointer;
}

.estimate-shipping-row.shipping-option.active {
    font-weight: 700;
}

.estimate-shipping-row-item {
    flex: 0 1 100%;
}

.estimate-shipping-row-item.shipping-item {
    padding: 8px 0;
    overflow: hidden;
    overflow-wrap: break-word;
}

.estimate-shipping-row-item.shipping-header-item {
    padding: 12px 0;
    border-bottom: 1px solid #f2f2f2;
    align-self: flex-end;
}

.estimate-shipping-row-item.address-item + .estimate-shipping-row-item.address-item {
    padding-left: 15px;
}

.estimate-shipping-row-item + .estimate-shipping-row-item {
    padding-left: 10px;
}

.estimate-shipping-row-item-radio {
    flex: 0 0 35px;
}

.ship-to-title {
    margin-bottom: 10px;
}

.choose-shipping-title {
    margin-top: 20px;
}

.estimate-shipping-address-control {
    width: 100%;
    // height: 36px;
}

.estimate-shipping-radio {
    display: none;
}

.estimate-shipping-radio + label {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    padding: 9px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
}

.estimate-shipping-radio:checked + label:after {
    content: ' ';
    width: 6px;
    height: 6px;
    border-radius: 50px;
    position: absolute;
    top: 6px;
    left: 6px;
    background: #ffffff;
}

.estimate-shipping-radio:checked + label {
    background-color: #4ab2f1;
    color: #99a1a7;
    border: 1px solid #adb8c0;
    border-color: #4ab2f1;
}

.apply-shipping-button {
    // border: none;
    padding: 8px 25px;
    // background-color: #4ab2f1;
    // color: #fff;
}

.apply-shipping-button-container {
    margin-top: 15px;
    text-align: center;
}

.shipping-options-header {
    top: 0;
    z-index: 1;
    position: sticky;
    background-color: #fff;
}

.shipping-options {
    position: relative;
    z-index: 1;
    overflow: hidden;
    overflow-y: auto;
    max-height: 200px;
    background: #FFF no-repeat;
    background-image: -webkit-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), -webkit-radial-gradient(50% 100%, farthest-side, rgba(242, 242, 242, 1), rgba(0, 0, 0, 0));
    background-image: -moz-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), -moz-radial-gradient(50% 100%, farthest-side, rgba(242, 242, 242, 1), rgba(0, 0, 0, 0));
    background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(242, 242, 242, 1), rgba(0, 0, 0, 0));
    background-position: 0 0, 0 100%;
    background-size: 100% 7px;
}

.shipping-options:before,
.shipping-options:after {
    content: "";
    position: relative;
    z-index: -1;
    display: block;
    height: 30px;
    margin: 0 0 -30px;
    background: -webkit-linear-gradient(top, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
    background: -moz-linear-gradient(top, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
    background: linear-gradient(to bottom, #FFF, #FFF 30%, rgba(255, 255, 255, 0));
}

.shipping-options:after {
    margin: -30px 0 0;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFF 70%, #FFF);
}

.no-shipping-options {
    text-align: center;
    margin: 4% 0;
}

.estimate-shipping-popup .message-failure {
    margin: 5px 0 -5px;
    font-size: 12px;
    color: #e4434b;
}


/*** SHOPPING CART ESTIMATE SHIPPING ***/


// .shopping-cart-page .common-buttons .estimate-shipping-button {
//     display: inline-block;
//     min-width: 180px;
//     margin: 5px;
//     border: none;
//     background-color: #aaa;
//     padding: 10px 20px;
//     font-size: 15px;
//     text-align: center;
//     color: #fff;
// }

// .shopping-cart-page .common-buttons .estimate-shipping-button:hover,
// .shopping-cart-page .common-buttons .estimate-shipping-button:focus {
//     background-color: #999;
// }


/*** PRODUCT ESTIMATE SHIPPING ***/


.product-estimate-shipping {
    margin-bottom: 20px;
}

.product-estimate-shipping .shipping-date {
    margin-top: 3px;
}

.product-estimate-shipping .shipping-title {
    font-size: 16px;
    font-weight: bold;
    color: #444;
    margin-bottom: 5px;
    justify-content: center;
}

.product-estimate-shipping .shipping-title .shipping-price {
    margin-left: 5px;
    vertical-align: middle;
}

.product-estimate-shipping .shipping-title .shipping-loading {
    margin-left: 5px;
    vertical-align: middle;
    background: url(../../images/ajax-loader-small.gif) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.product-estimate-shipping .open-estimate-shipping-popup .arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: 0 0 3px 6px;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
}


/********** CHECKOUT **********/



.checkout-page .section {
    margin: 0 0 30px;
}

.checkout-page .section.order-summary {
    margin: 100px 0 30px;
}

.checkout-page .cart-options {
    min-height: 60px;
    overflow: hidden;
}

.checkout-page .cart-footer {
    border-top: none;
}

.checkout-page .total-info {
    margin: 0;
    border-bottom: none;
    // padding: 5px 20px 10px;
}

.checkout-page .button-1 {
    display: inline-block;
    min-width: 140px;
    // border: none;
    // background-color: #4ab2f1;
    padding: 10px 30px;
    text-align: center;
    // font-size: 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .checkout-page .button-1:hover,
// .checkout-page .button-1:focus {
//     background-color: #248ece;
// }

.checkout-page .address-item {
    width: 350px;
    max-width: 100%;
    margin: 0 auto 40px;
}

.checkout-page .address-item ul {
    margin: 0 0 15px;
    background-color: #f9f9f9;
    padding: 20px;
    color: #444;
}

.checkout-page .address-item li {
    padding: 5px 0;
}

.checkout-page .address-item li.name {
    font-size: 16px;
    font-weight: bold;
}

.enter-address .message-error {
    margin: 20px 0;
}

.enter-address .edit-address {
    background-color: #f9f9f9;
    // border-top: 1px solid #e6e6e6;
    margin: 0 0 30px;
    // padding: 30px 15px;
}

.checkout-page .ship-to-same-address {
    padding: 30px 0;
    text-align: center;
}

.checkout-page .ship-to-same-address .selector {
    margin: 0 0 5px;
    font-weight: bold;
    color: #444;
}

.checkout-page .pickup-in-store {
    text-align: center;
}

.checkout-page .pickup-in-store .selector {
    margin: 0 0 5px;
    font-weight: bold;
    color: #444;
}

.select-pickup-point {
    text-align: center;
}

.checkout-page .pickup-points-map {
    min-width: 400px;
    min-height: 350px;
    vertical-align: middle;
    margin-top: 5px;
    margin-bottom: 5px;
}

.checkout-page .edit-address-button {
    float: right;
    margin: 20px 10px 10px 12px;
    display: none;
}

.checkout-page .delete-address-button {
    float: right;
    margin: 20px 0 10px 0;
    display: none;
}

// .shipping-method .method-list,
// .payment-method .method-list {
//     margin: 0 auto 30px;
//     overflow: hidden;
//     text-align: center;
//     font-size: 0;
// }

// .shipping-method .method-list li,
// .payment-method .method-list li {
//     margin: 20px 0;
//     font-size: 14px; /*reset zeroing*/
// }

// .shipping-method .method-list li label,
// .payment-method .method-list li label {
//     font-size: 13px;
//     font-weight: bold;
//     color: #444;
// }

// .shipping-method .method-description {
//     margin: 5px 0 0;
// }

.payment-method .use-reward-points {
    margin: 0 0 30px;
    text-align: center;
    color: #444;
}

// .payment-method .payment-logo {
//     display: inline-block;
//     vertical-align: middle;
// }

// .payment-method .payment-logo label {
//     display: block;
//     font-size: 0 !important;
// }

// .payment-method .payment-details {
//     width: 200px;
//     display: inline-block;
//     margin: 0 0 0 10px;
//     text-align: left;
//     vertical-align: middle;
// }

.payment-info .info {
    padding: 30px 15px;
    color: #444;
}

.payment-info .info tr {
    display: block;
    margin: 0 0 15px;
    font-size: 0;
}

.payment-info .info td {
    display: inline-block;
    width: 100% !important;
    max-width: 400px !important;
    font-size: 14px; /*reset zeroing*/
}

.payment-info .info td:only-child {
    width: 100% !important;
    max-width: 100% !important;
}

.payment-info .info td input[type="text"] {
    width: 100% !important;
}

.payment-info .info td input[name="CardCode"] {
    width: 65px !important;
}

.payment-info .info td select {
    min-width: 70px;
}

// .payment-info .info td:first-child {
//     margin: 0 0 10px;
// }

.payment-info .info p {
    text-align: center;
}

.confirm-order .buttons {
    padding: 10px 0;
}

// .confirm-order .button-1 {
//     font-size: 16px;
// }

// .order-review-data > div,
// .order-details-area > div,
// .shipment-details-area > div {
//     width: 350px;
//     max-width: 100%;
//     margin: 0 auto 40px;
//     background-color: #f9f9f9;
//     padding: 20px;
//     color: #444;
// }

.order-review-data li,
.order-details-area li,
.shipment-details-area li {
    padding: 3px 0;
}

.order-review-data .title,
.order-details-area .title,
.shipment-details-area .title {
    margin: 0 0 5px;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
}

.order-review-data .title strong,
.order-details-area .title strong,
.shipment-details-area .title strong {
    font-weight: 700;
}

.order-review-data .payment-method-info,
.order-review-data .shipping-method-info,
.order-details-area .payment-method-info,
.order-details-area .shipping-method-info {
    margin-top: 20px;
}

.order-completed .details {
    margin: 0 0 30px;
    padding: 30px 15px;
    text-align: center;
    color: #444;
}

.order-completed .details div {
    margin: 5px 0;
}

// .order-completed .details strong {
//     font-weight: normal;
//     text-transform: uppercase;
// }

// .order-completed .details a {
//     color: #4ab2f1;
// }

.order-completed .details a:hover,
.order-completed .details a:focus {
    text-decoration: underline;
}

.opc .step-title {
    // margin: 0 0 1px;
    overflow: hidden;
    // background-color: #f6f6f6;
}

.opc .allow .step-title {
    // background-color: #4ab2f1;
    cursor: pointer;
}

.opc .step-title .number,
.opc .step-title .title {
    float: left;
    min-height: 40px;
    padding: 10px 15px;
    line-height: 20px;
    font-size: 16px;
    font-weight: normal;
}

.opc .allow .step-title .number,
.opc .allow .step-title .title {
    color: #fff;
    cursor: pointer;
}

.opc .step-title .number {
    width: 42px;
    border-right: 1px solid #fff;
    text-align: center;
}

.opc .allow .step-title .number {
    background-color: #248ece;
}

.opc .step {
    // margin: 10px 0;
    // padding: 30px 15px;
    text-align: center;
}

.opc .section {
    margin: 0 0 30px;
}

.opc .section > label {
    display: block;
    margin: 0 0 10px;
}

.opc input[type="text"],
.opc select {
    max-width: 100%;
}

.opc .buttons {
    margin-bottom: 0;
}

// .opc .back-link {
//     margin: 0 0 10px;
// }

.opc .back-link small {
    display: none;
}

.opc .back-link a {
    display: inline-block;
    margin: 0 0 0 -15px;
    background: url('../../images/back-button.png') left center no-repeat;
    padding: 0 0 0 18px;
    color: #444;
}

.opc .back-link a:hover,
.opc .back-link a:focus {
    color: #4ab2f1;
}

.opc .buttons .please-wait {
    display: block;
    margin: 10px 0 0;
    background: none;
}

.opc .section.ship-to-same-address {
    margin: 0 0 30px;
    padding: 0;
}

.opc .section.pickup-in-store {
    margin: 0 0 30px;
    padding: 0;
}

.opc .payment-info .info tr {
    text-align: left;
}

.opc .section.order-summary {
    margin: 0;
}


/****** ORDER DETAILS & SHIPPING DETAILS ******/



// .order-details-page .page-title {
//     border-bottom: none;
// }

.order-details-page .page-title h1 {
    margin: 0 0 30px;
    // border-bottom: 1px solid #ddd;
    // padding: 0 0 10px;
}

// .order-details-page .page-title a {
//     display: inline-block;
//     min-width: 180px;
//     margin: 5px 0;
//     border: none;
//     background-color: #aaa;
//     background-image: none;
//     padding: 10px 20px;
//     font-size: 15px;
//     color: #fff;
// }

// .order-details-page .page-title a:hover,
// .order-details-page .page-title a:focus {
//     background-color: #999;
// }

// .order-details-page .page-title a.print-order-button {
//     background-color: #4ab2f1;
// }

// .order-details-page .page-title a.print-order-button:hover,
// .order-details-page .page-title a.print-order-button:focus {
//     background-color: #248ece;
// }

.order-details-page .order-overview {
    margin: 0 0 50px;
    line-height: 26px;
    text-align: center;
    font-size: 16px;
    // color: #444;
}

.order-details-page .order-overview .order-number {
    margin: 0 0 10px;
    text-transform: uppercase;
}

// .order-details-page .order-overview .order-total strong {
//     font-weight: normal;
//     color: #4ab2f1;
// }

.order-details-page .repost .button-2 {
    display: inline-block;
    margin: 10px 0;
    border: none;
    background-color: #4ab2f1;
    padding: 10px 15px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.order-details-page .repost .button-2:hover,
.order-details-page .repost .button-2:focus {
    background-color: #248ece;
}

.order-details-page .download a {
    font-weight: normal;
    color: #4ab2f1;
}

.order-details-page .download a:hover,
.order-details-page .download a:focus {
    text-decoration: underline;
}

.user-agreement-page .terms-of-agreement {
    margin: 0 0 20px;
    padding: 20px;
    text-align: center;
}

.user-agreement-page .button-1 {
    min-width: 140px;
    // border: none;
    // background-color: #4ab2f1;
    padding: 10px 30px;
    text-align: center;
    // font-size: 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .user-agreement-page .button-1:hover,
// .user-agreement-page .button-1:focus {
//     background-color: #248ece;
// }

.order-details-page .tax-shipping-info {
    margin: 30px 0;
}

.order-details-page .tax-shipping-info a {
    color: #4ab2f1;
}

.order-details-page .tax-shipping-info a:hover,
.order-details-page .tax-shipping-info a:focus {
    text-decoration: underline;
}

.order-details-page .actions {
    margin: 30px 0 0;
    font-size: 0;
}

// .order-details-page .actions button {
//     display: inline-block;
//     min-width: 165px;
//     margin: 5px;
//     border: none;
//     background-color: #aaa;
//     background-image: none;
//     padding: 10px 20px;
//     font-size: 15px;
//     color: #fff;
// }

// .order-details-page .actions button:hover,
// .order-details-page .actions button:focus {
//     background-color: #999;
// }

// .order-details-page .actions button:first-child {
//     background-color: #4ab2f1;
// }

// .order-details-page .actions button:first-child:hover,
// .order-details-page .actions button:first-child:focus {
//     background-color: #248ece;
// }

// .order-details-page .totals.section {
//     width: 370px;
//     padding: 0 10px;
//     max-width: 100%;
//     margin: 0 auto 50px;
// }

// .order-details-page .total-info {
//     background-color: #f9f9f9;
//     padding: 15px 20px;
//     color: #444;
// }


/****** SEARCH & SITEMAP ******/



// .search-page .page-title {
//     margin: 0 0 -1px;
// }

.search-input .fieldset {
    margin: 0 0 30px;
}

.search-input .form-fields {
    padding: 30px 15px 10px;
}

.search-input input + label {
    display: inline;
    margin: 0 0 0 5px;
}

.advanced-search {
    margin: 30px 0 0;
}

.advanced-search .price-range {
    display: inline-block;
}

.advanced-search .price-range input {
    width: 80px;
    margin: 3px;
}

.search-input .buttons {
    text-align: center;
}

.search-input .button-1 {
    min-width: 140px;
    // border: none;
    // background-color: #4ab2f1;
    padding: 10px 30px;
    text-align: center;
    // font-size: 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .search-input .button-1:hover,
// .search-input .button-1:focus {
//     background-color: #248ece;
// }

.search-results {
    margin: 30px 0 0;
}

.search-results .warning {
    margin: 10px 0;
    color: #e4434b;
}

.sitemap-page .description {
    margin: 0 0 30px;
    text-align: center;
}

.sitemap-page .entity {
    margin: 0 0 30px;
}

.sitemap-page .entity-title {
    margin: 0 0 15px;
    padding: 0 10px;
    text-align: center;
}

// .sitemap-page .entity-title h2 {
//     font-size: 20px;
//     font-weight: normal;
//     color: #444;
// }

.sitemap-page .entity-body {
    // border-top: 1px solid #e6e6e6;
    // background-color: #f9f9f9;
    padding: 30px 15px;
    text-align: center;
}

.sitemap-page .entity ul {
    font-size: 0;
}

.sitemap-page .entity li {
    display: inline-block;
    position: relative;
    margin: 0 8px;
    padding: 0 12px;
    // line-height: 50px;
    // font-size: 14px; /*reset zeroing*/
}

.sitemap-page .entity li:before {
    content: "";
    position: absolute;
    top: 23px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #ddd;
}

// .sitemap-page .entity a {
//     color: #444;
// }

// .sitemap-page .entity a:hover,
// .sitemap-page .entity a:focus {
//     color: #4ab2f1;
// }

.sitemap-page .entity a:empty {
    display: none;
}


/****** BLOG & NEWS ******/



.block-blog-archive .number {
    display: block;
    color: #444;
    cursor: auto;
}

.block-blog-archive .sublist {
    margin: 5px 0 5px 15px;
}

.block-blog-archive li.month {
    padding: 3px 0 3px 15px;
}

.blog-page .page-title,
.news-list-page .page-title {
    margin: 0;
}

.blog-page .post,
.news-items .news-item {
    margin: 0 0 30px;
}

.blog-page .post:after {
    content: "";
    display: block;
    clear: both;
}

.post-title,
.news-title {
    display: inline-block;
    padding: 20px 10px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #444;
}

.post-title:hover,
.post-title:focus,
.news-title:hover,
.news-title:focus {
    color: #4ab2f1;
}

.post-date,
.news-date {
    display: block;
    margin: 0 0 15px;
    background-color: #f6f6f6;
    padding: 10px;
    font-style: italic;
    color: #444;
}

.post-body,
.news-body {
    margin: 0 0 20px;
    padding: 0 10px;
    line-height: 22px;
}

.blog-page .tags,
.blogpost-page .tags {
    margin: 0 0 15px;
    overflow: hidden;
    padding: 0 10px;
}

.blog-page .tags label,
.blogpost-page .tags label {
    display: inline-block;
    margin: 0 3px 3px 0;
    font-weight: bold;
    color: #444;
}

.blog-page .tags ul,
.blogpost-page .tags ul {
    display: inline-block;
    margin: 0 0 3px 3px;
    font-size: 0;
}

.blog-page .tags li,
.blogpost-page .tags li {
    display: inline-block;
    font-size: 14px; /*reset zeroing*/
}

.blog-page .tags li.separator,
.blogpost-page .tags li.separator {
    margin: 0 8px 0 0;
}

.blog-page .tags a,
.blogpost-page .tags a {
    display: block;
    color: #4ab2f1;
}

.blog-page .tags a:hover,
.blog-page .tags a:focus,
.blogpost-page .tags a:hover,
.blogpost-page .tags a:focus {
    text-decoration: underline;
}

.blog-posts .buttons,
.news-items .buttons {
    margin: 0;
    padding: 0 10px;
}

.blog-posts .buttons .read-comments {
    display: block;
    margin: 0 0 15px;
    font-weight: bold;
    color: #444;
}

.blog-posts .buttons .read-comments:hover,
.blog-posts .buttons .read-comments:focus {
    color: #4ab2f1;
}

.blog-posts .buttons .read-more,
.news-items .buttons .read-more {
    display: inline-block;
    border: none;
    background-color: #4ab2f1;
    padding: 10px 20px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.blog-posts .buttons .read-more:hover,
.blog-posts .buttons .read-more:focus,
.news-items .buttons .read-more:hover,
.news-items .buttons .read-more:focus {
    background-color: #248ece;
}

.new-comment {
    margin: 50px 0 60px;
}

.new-comment .notifications {
    margin: 0 0 10px;
}

.new-comment .result {
    color: #690;
}

.new-comment .form-fields {
    margin: 0 0 20px;
}

.new-comment .button-1 {
    // border: none;
    // background-color: #4ab2f1;
    padding: 10px 30px;
    text-align: center;
    // font-size: 15px;
    // color: #fff;
    text-transform: uppercase;
}

// .new-comment .button-1:hover,
// .new-comment .button-1:focus {
//     background-color: #248ece;
// }

.comment-list {
    margin: 0 0 100px;
}

.comment-list .title {
    margin: 0 0 15px;
    padding: 0 10px;
    font-size: 20px;
    color: #444;
}

.comment-list .title strong {
    font-weight: normal;
}

.comment-list .comment {
    margin: 0 0 40px;
    border-top: 1px solid #ddd;
}

.comment-list .comment:after {
    content: "";
    display: block;
    clear: both;
}

.comment-info {
    width: 150px;
    margin: 20px auto;
}

.comment-info .username {
    display: block;
    margin: 0 0 -1px;
    border: 1px solid #ddd;
    background-color: #f6f6f6;
    padding: 11px 0;
    font-weight: bold;
    color: #444;
}

.comment-info a.username:hover,
.comment-info a.username:focus {
    color: #4ab2f1;
}

.comment-info .avatar {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    overflow: hidden;
}

.comment-info .avatar img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border: solid 2px #CEDBE1 !important;
}

.comment-time {
    margin: 0 0 15px;
    font-size: 13px;
    font-weight: bold;
}

.comment-time span {
    color: #444;
}

.comment-title {
    margin: 0 0 15px;
    font-size: 16px;
    color: #444;
}

.comment-body {
    line-height: 22px;
}

.news-list-homepage {
    margin: 0 0 75px;
}

.news-list-homepage .title {
    margin: 0 0 -1px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
    font-size: 30px;
    font-weight: normal;
    color: #444;
}

.news-list-homepage .title strong {
    font-weight: normal;
}

.news-list-homepage .view-all {
    text-align: center;
}

.news-list-homepage .view-all a {
    color: #444;
    text-decoration: underline;
}

.news-list-homepage .view-all a:hover,
.news-list-homepage .view-all a:focus {
    color: #4ab2f1;
}


/*** FORUM & PROFILE ***/



.forums-main-page .topic-block {
    margin: 0 0 35px;
    text-align: center;
}

.forums-main-page .topic-block p {
    text-align: center;
}

.forums-main-page .pager {
    display: none;
}

.forum-breadcrumb {
    margin: 0 0 40px;
}

.forums-header {
    margin: 0 0 50px;
    overflow: hidden;
}

.forums-header:after {
    content: "";
    display: block;
    clear: both;
}

.forums-header .current-time {
    margin: 0 0 15px;
    color: #444;
}

.forum-search-box .basic {
    overflow: hidden;
    margin: 0 0 10px;
}

.forum-search-box .advanced a {
    line-height: 22px;
    color: #444;
    text-decoration: underline;
}

.forum-search-box .advanced a:hover,
.forum-search-box .advanced a:focus {
    color: #4ab2f1;
}

// .forum-search-page .page-title {
//     margin: 0 0 -1px;
// }

.forum-search-page .search-error,
.forum-search-page .no-result {
    margin: 0 0 15px;
    text-align: center;
    color: #e4434b;
}

/* forum group */

.forums-table-section {
    margin: 0 0 60px;
}

.forums-table-section + .pager {
    margin: -30px 0 30px;
}

.forums-table-section-title {
    margin: 0 0 15px;
    padding: 0 10px;
}

.forums-table-section-title a,
.forums-table-section-title strong {
    font-size: 22px;
    font-weight: normal;
    color: #444;
}

.forums-table-section-title a:hover,
.forums-table-section-title a:focus {
    color: #4ab2f1;
}

.forums-table-section .image div {
    width: 38px;
    height: 32px;
    margin: auto;
    background: url('../../images/topic-type1.png') center no-repeat;
}

.forums-table-section .image div.sticky {
    background: url('../../images/topic-type2.png') center no-repeat;
}

.forums-table-section .image div.announcement {
    background: url('../../images/topic-type3.png') center no-repeat;
}

.forums-table-section .forum-title,
.forums-table-section .topic-title {
    margin: 5px 0;
}

.forums-table-section .forum-title a,
.forums-table-section .topic-title a {
    font-size: 16px;
    font-weight: bold;
    color: #444;
}

.forums-table-section .forum-title a:hover,
.forums-table-section .forum-title a:focus,
.forums-table-section .topic-title a:hover,
.forums-table-section .topic-title a:focus {
    color: #4ab2f1;
    text-decoration: none;
}

.forums-table-section .topic-title span {
    display: block;
    color: #777;
}

.forums-table-section .forum-description,
.forums-table-section .topic-starter {
    margin: 5px 0;
}

.forums-table-section .latest-post {
    white-space: nowrap;
}

.forums-table-section .latest-post div {
    margin: 5px 0;
}

.forums-table-section .latest-post label {
    color: #777;
}

.forums-table-section .view-all {
    margin: 30px 0 0;
    padding: 0 10px;
}

.forums-table-section .view-all a {
    background-color: #4ab2f1;
    padding: 10px 15px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.forums-table-section .view-all a:hover,
.forums-table-section .view-all a:focus {
    background-color: #248ece;
}

/* forum-page, topic page */

.forum-page .forum-info {
    margin: 0 0 30px;
}

.forum-page .forum-name,
.forum-topic-page .topic-name {
    margin: 0 0 25px;
    border-bottom: 1px solid #ddd;
    padding: 0 10px 10px;
}

.forum-page .forum-name h1,
.forum-topic-page .topic-name h1 {
    font-size: 30px;
    font-weight: normal;
}

.forum-actions,
.topic-actions {
    margin: 0 0 30px;
    font-size: 0;
}

.forum-actions .actions a,
.topic-actions .actions a {
    display: inline-block;
    margin: 1px;
    padding: 10px 10px 10px 33px;
    font-size: 14px;
}

.forum-actions .actions .new-topic {
    background: #eee url('../../images/new.png') left center no-repeat;
}

.forum-actions .actions .watch-forum,
.topic-actions .actions .watch-forum {
    background: #eee url('../../images/watch.png') left center no-repeat;
}

.forum-actions .actions a:hover,
.forum-actions .actions a:focus,
.topic-actions .actions a:hover,
.topic-actions .actions a:focus {
    background-color: #e6e6e6;
}

.forum-actions .pager.upper,
.topic-actions .pager.upper {
    display: none;
    margin: 0;
}

.topic-actions .reply-topic-button {
    background: #eee url('../../images/reply.png') left center no-repeat;
}

.topic-actions .watch-topic-button {
    background: #eee url('../../images/watch.png') left center no-repeat;
}

.topic-actions .move-topic-button {
    background: #eee url('../../images/move.png') left center no-repeat;
}

.topic-actions .edit-topic-button {
    background: #eee url('../../images/edit_.png') left center no-repeat;
}

.topic-actions .delete-topic-button {
    background: #eee url('../../images/remove_.png') left center no-repeat;
}

.topic-actions.lower .actions {
    display: none;
}

.active-discussions-page .forums-table-section-title {
    margin: 0 0 25px;
    border-bottom: 1px solid #ddd;
    padding: 0 10px 10px;
}

.active-discussions-page .forums-table-section-title strong {
    font-size: 30px;
    font-weight: normal;
}

.active-discussions-page .forums-table-section-body {
    margin: 0 0 20px;
}

/* topic post */

.topic-post {
    margin: 0 0 30px;
    border-top: 1px solid #ddd;
}

.topic-post:last-child {
    border-bottom: 1px solid #ddd;
}

.topic-post:after {
    content: "";
    display: block;
    clear: both;
}

.topic-post .post-info {
    margin: 30px 0;
}

.topic-post .user-info {
    width: 150px;
    margin: 0 auto 15px
}

.topic-post .username {
    display: block;
    margin: 0 0 -1px;
    border: 1px solid #ddd;
    background-color: #f6f6f6;
    padding: 11px 0;
    font-weight: bold;
    color: #444;
}

.topic-post a.username:hover,
.topic-post a.username:focus {
    color: #4ab2f1;
}

.topic-post .avatar,
.profile-info-box .avatar {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    overflow: hidden;
}

.topic-post .avatar img,
.profile-info-box .avatar img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.topic-post .avatar img {
    border: solid 2px #CEDBE1 !important;
}

.topic-post .user-stats {
    margin: 0 0 15px;
}

.topic-post .user-stats li {
    display: inline-block;
    margin: 0 10px;
}

.topic-post .user-stats span,
.profile-info-box .profile-stats span {
    margin: 0 0 0 3px;
    font-weight: bold;
    color: #444;
}

.topic-post .pm-button,
.profile-info-box .pm-button {
    display: inline-block;
    border: none;
    background-color: #888;
    padding: 10px 20px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.topic-post .pm-button:hover,
.topic-post .pm-button:focus,
.profile-info-box .pm-button:hover,
.profile-info-box .pm-button:focus {
    background-color: #999;
}

.topic-post .post-time {
    margin: 0 0 15px;
    background-color: #f6f6f6;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: bold;
}

.topic-post .post-time span {
    color: #444;
}

.topic-post .post-actions {
    margin: 0 0 15px;
    font-size: 0;
}

.topic-post .post-actions > div {
    display: inline-block;
}

.topic-post .post-actions a {
    display: inline-block;
    margin: 1px 5px;
    font-size: 14px;
}

.topic-post .post-actions .edit-post-button,
.topic-post .post-actions .delete-post-button {
    padding: 10px 10px 10px 33px;
}

.topic-post .post-actions .edit-post-button {
    background: url('../../images/edit_.png') left center no-repeat;
}

.topic-post .post-actions .delete-post-button {
    background: url('../../images/remove_.png') left center no-repeat;
}

.topic-post .post-actions .edit-post-button:hover,
.topic-post .post-actions .edit-post-button:focus,
.topic-post .post-actions .delete-post-button:hover,
.topic-post .post-actions .delete-post-button:focus {
    color: #4ab2f1;
}

.topic-post .post-actions .post-link-button {
    padding: 10px;
    font-weight: bold;
    color: #4ab2f1;
}

.topic-post .post-actions .post-link-button:hover,
.topic-post .post-actions .post-link-button:focus {
    text-decoration: underline;
}

.topic-post .post-actions .quote-post-button {
    background-color: #888;
    padding: 10px 20px;
    color: #fff;
}

.topic-post .post-actions .quote-post-button:hover,
.topic-post .post-actions .quote-post-button:focus {
    background-color: #999;
    color: #fff;
}

.topic-post .post-text {
    /*topic post-text also gets style from blog post-body*/
    padding: 0 10px 20px;
    line-height: 22px;
    color: #444;
    margin-bottom: 0;
}

.topic-post .post-vote {
    text-align: center;
    display: table;
    margin: 0 auto 20px;
}

.topic-post .user-posted-image,
.latest-posts .user-posted-image {
    max-width: 100%;
    height: auto;
}

.topic-post .quote,
.latest-posts .quote {
    margin: 10px 0;
    border: 1px dashed #ccc;
    background-color: #f9f9f9;
    padding: 10px;
    color: #777;
}

.topic-post .signature {
    border-top: 1px solid #ddd;
    padding: 20px;
    font-size: 13px;
    font-style: italic;
    clear: both;
}

.topic-post .post-vote span.vote {
    cursor: pointer;
    display: block;
    width: 40px;
    height: 24px;
}

.topic-post .post-vote span.up {
    background: url('../../images/vote-up.png') no-repeat 50% 50%;
}

.topic-post .post-vote span.vote.up.selected {
    background: url('../../images/vote-up-selected.png') no-repeat 50% 50%;
}

.topic-post .post-vote span.down {
    background: url('../../images/vote-down.png') no-repeat 50% 50%;
}

.topic-post .post-vote span.vote.down.selected {
    background: url('../../images/vote-down-selected.png') no-repeat 50% 50%;
}

.topic-post .vote-count-post {
    display: block;
    font-size: 160%;
}
/* forum edit & send message */

// .forum-edit-page .page-title,
// .move-topic-page .page-title,
// .private-message-send-page .page-title {
//     margin: 0 0 -1px;
// }

.move-topic-page label {
    white-space: normal;
}

.forum-edit-page .message-error,
.private-message-send-page .message-error {
    margin: 20px 0;
}

.forum-edit-page .inputs strong {
    display: inline-block;
    max-width: 400px;
    font-size: 22px; /*reset zeroing*/
    font-weight: normal;
    color: #444;
    white-space: normal;
}

.forum-edit-page .inputs .topic-subject {
    font-size: 17px;
}

.forum-edit-page .inputs.reversed {
    margin: 0;
}

.forum-edit-page .inputs.reversed label {
    width: auto;
    margin: 5px;
    font-size: 14px; /*reset zeroing*/
    white-space: normal;
}

.forum-edit-page .bb-code-editor-wrapper,
.private-message-send-page .bb-code-editor-wrapper {
    max-width: 400px;
    margin: 20px auto;
    background-color: #ddd;
    padding: 10px;
}

.forum-edit-page .toolbar .button,
.private-message-send-page .toolbar .button {
    margin: 0 2px 0 0;
    border: #cec6b5 1px solid;
    padding: 2px;
}

.forum-edit-page .toolbar .button:hover,
.forum-edit-page .toolbar .button:focus,
.private-message-send-page .toolbar .button:hover,
.private-message-send-page .toolbar .button:focus {
    border: #333 1px solid;
}

.forum-edit-page textarea,
.private-message-send-page textarea {
    display: block;
    margin: auto;
}

.forum-edit-page .buttons button,
.move-topic-page .buttons button,
.private-messages .buttons button {
    min-width: 140px;
    border: none;
    background-color: #999;
    padding: 10px 30px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
}

.forum-edit-page .buttons button:hover,
.forum-edit-page .buttons button:focus,
.move-topic-page .buttons button:hover,
.move-topic-page .buttons button:focus,
.private-messages .buttons button:hover,
.private-messages .buttons button:focus {
    background-color: #888;
}

// .forum-edit-page .buttons .button-1,
// .move-topic-page .buttons .button-1,
// .private-messages .buttons .button-1 {
//     background-color: #4ab2f1;
// }

// .forum-edit-page .buttons .button-1:hover,
// .forum-edit-page .buttons .button-1:focus,
// .move-topic-page .buttons .button-1:hover,
// .move-topic-page .buttons .button-1:focus,
// .private-messages .buttons .button-1:hover,
// .private-messages .buttons .button-1:focus {
//     background-color: #248ece;
// }

.private-message-send-page a {
    font-size: 14px;
    color: #4ab2f1;
}

.private-message-send-page span {
    font-size: 14px;
}

/* jQuery tabs */

.ui-tabs {
    /*override jQuery UI styles, do not delete doubled properties*/
    border: none;
    border-radius: 0;
    background: none;
    padding: 0;
    font: normal 14px Arial, Helvetica, sans-serif;
    color: #777;
}

.ui-widget.ui-widget-content.ui-tabs {
    border: none;
}

.ui-tabs-nav {
    margin: 0 0 30px;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    font-size: 0;
    /*override jQuery UI styles, do not delete doubled properties*/
    margin: 0 0 30px !important;
    border-width: 0 0 1px;
    border-radius: 0;
    background: none;
    padding: 0 !important;
    line-height: normal;
    font-weight: normal;
    color: #444;
}

.ui-tabs-nav li {
    margin: 0 0 -1px;
    /*override jQuery UI styles, do not delete doubled properties*/
    float: none !important;
    margin: 0 0 -1px !important;
    border: none !important;
    border-radius: 0;
    background: none !important;
    padding: 0 !important;
}

.ui-tabs-nav li a {
    display: block;
    border: 1px solid #ddd;
    padding: 12px 24px;
    text-align: center;
    font-size: 18px;
    color: #444;
    /*override jQuery UI styles, do not delete doubled properties*/
    float: none !important;
    padding: 12px 24px !important;
}

.ui-tabs-nav li.ui-state-active a {
    background-color: #eee;
    color: #4ab2f1;
}

.ui-tabs-panel {
    /*override jQuery UI styles, do not delete doubled properties*/
    padding: 0 !important;
}

/* inbox & view message */

.private-messages-page .table-wrapper,
.private-message-view-page .view-message {
    margin: 0 0 30px;
}

.private-messages-page th.select {
    text-align: center;
}

.private-messages-page td.from,
.private-messages-page td.to,
.private-messages-page td.subject {
    min-width: 150px;
}

.private-messages-page td.date {
    white-space: nowrap;
}

.private-messages-page td.subject a {
    color: #444;
}

.private-messages-page td.subject a.pm-unread {
    color: #4ab2f1;
}

.private-messages-page .pager {
    margin: 0 0 30px;
}

.private-messages-page .buttons,
.private-message-view-page .buttons {
    font-size: 0;
}

.private-messages-page .buttons .button-1,
.private-messages-page .buttons .button-2,
.private-message-view-page .buttons .button-1,
.private-message-view-page .buttons .button-2 {
    display: block;
    width: 250px;
    margin: 0 auto 3px;
    // font-size: 15px; /*reset zeroing*/
}

.private-messages-page .no-items {
    padding: 20px;
    text-align: center;
}

.private-message-view-page .view-message {
    margin: 0 0 30px;
}

.private-message-view-page .message-head {
    margin: 0 0 20px;
    line-height: 22px;
}

.private-message-view-page .message-head span {
    font-weight: bold;
    color: #444;
}

.private-message-view-page .message-body {
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 30px 20px;
    line-height: 22px;
    text-align: justify;
}

.private-message-view-page .back-pm-button {
    text-transform: capitalize !important;
}

/* profile page  */

.profile-info-box {
    overflow: hidden;
    /*override jQuery UI styles, do not delete doubled properties*/
    line-height: normal;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #777;
}

.profile-info-box .user-details {
    margin: 0 0 30px;
}

.profile-info-box .avatar {
    margin: 0 auto 15px;
}

.profile-info-box .title {
    margin: 0 0 10px;
    font-size: 18px;
    color: #444;
}

.profile-info-box .stats {
    margin: 0 0 15px;
}

.latest-posts {
    /*override jQuery UI styles, do not delete doubled properties*/
    line-height: normal;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #777;
}

.latest-posts .topic {
    margin: 0 0 35px;
}

.latest-posts .topic-title {
    margin: 0 0 20px;
    border-top: 1px solid #ddd;
    background: #f6f6f6;
    padding: 10px;
    font-size: 16px;
}

.latest-posts .topic-title a {
    font-weight: bold;
    color: #444;
}

.latest-posts .topic-title a:hover,
.latest-posts .topic-title a:focus {
    color: #4ab2f1;
}

.latest-posts .topic-body {
    margin: 0 0 15px;
    line-height: 22px;
}

.latest-posts .topic-data {
    color: #444;
}

/* BB codes */

pre {
    white-space: pre-wrap;
    white-space: normal;
    word-wrap: break-word;
}

.csharpcode {
    margin: 10px 0;
    border: 1px dashed #ccc;
    background-color: #fff;
    padding: 10px;
    font-family: "Courier New", Courier, monospace;
    color: #000;
}

.csharpcode .rem {
    color: green;
}

.csharpcode .kwrd {
    color: #00f;
}

.csharpcode .str {
    color: #006080;
}

.csharpcode .op {
    color: #00c;
}

.csharpcode .preproc {
    color: #c63;
}

.csharpcode .asp {
    background-color: #ff0;
}

.csharpcode .html {
    color: maroon;
}

.csharpcode .attr {
    color: red;
}

.csharpcode .alt {
    width: 100%;
    margin: 0;
    background-color: #f6f6f6;
}

.csharpcode .lnum {
    color: #666;
}



@media all and (max-width: 769px) {

    .review-rating .name-description {
        text-align: center;
        width: 100%;
        display: block;
    }

    .write-review .review-rating div {
        text-align: center;
        padding-bottom: 10px;
        display: inline-block;
    }


    /*** ESTIMATE SHIPPING POPUP ***/


    .shipping-address {
        flex-flow: column;
    }

    .address-item {
        width: 100%;
    }

    .estimate-shipping-row-item.address-item + .estimate-shipping-row-item.address-item {
        padding-left: 0px;
        padding-top: 8px;
    }
}





@media all and (max-width: 1000px) {


    /*** GLOBAL TABLES ***/


    // .cart {
    //     display: block;
    // }

    // .cart colgroup,
    // .cart thead {
    //     display: none;
    // }

    // .cart tbody {
    //     display: block;
    //     overflow: hidden;
    // }

    // .cart tr {
    //     display: block;
    //     float: left;
    //     width: 100%;
    //     margin: 40px 0 0;
    //     border: 1px solid #ddd;
    // }

    // .cart td {
    //     display: block;
    //     border: none;
    //     padding: 10px;
    // }

    // .cart td.remove-from-cart {
    //     margin: 0 0 15px;
    //     border-bottom: 1px solid #ddd;
    //     background-color: #eee;
    //     padding: 10px;
    // }

    // .cart td.product-picture {
    //     border-top: 1px solid #ddd;
    //     padding: 15px 10px 5px;
    // }

    // .cart td.product {
    //     min-width: 0;
    //     border-bottom: 1px solid #ddd;
    //     padding: 5px 10px 15px;
    //     text-align: center;
    // }

    // .cart td.quantity {
    //     display: inline-block;
    //     padding: 0 10px;
    //     vertical-align: middle;
    // }

    // .cart td.unit-price,
    // .cart td.subtotal {
    //     display: inline-block;
    //     padding: 20px 10px;
    //     vertical-align: middle;
    // }

    // .order-details-page .data-table {
    //     display: block;
    // }

    // .order-details-page .data-table colgroup,
    // .order-details-page .data-table thead {
    //     display: none;
    // }

    // .order-details-page .data-table tbody {
    //     display: block;
    //     overflow: hidden;
    // }

    // .order-details-page .data-table tr {
    //     display: block;
    //     float: left;
    //     width: 100%;
    //     margin: 40px 0 0;
    //     border: 1px solid #ddd;
    // }

    // .order-details-page .data-table td {
    //     display: block;
    //     border: none;
    //     padding: 10px;
    // }

    // .order-details-page .data-table td.product,
    // .order-details-page .data-table td.tracking-number {
    //     min-width: 0;
    //     border-bottom: 1px solid #ddd;
    //     padding: 5px 10px 15px;
    //     text-align: center;
    // }


    /*** MOBILE NAVIGATION ***/


    .header-menu {
        position: relative;
        z-index: 1;
        // width: 90%;
        // max-width: 600px;
        // margin: 0 auto 40px;
    }

    .menu-toggle {
        // background: #4ab2f1 url('../../images/menu-icon.png') right center no-repeat;
        // padding: 15px;
        // font-size: 15px;
        // font-weight: bold;
        // color: #fff;
        // text-transform: uppercase;
        cursor: pointer;
    }

    // .top-menu {
    //     display: none;
    // }

    // .top-menu > li {
    //     position: relative;
    //     margin: 1px 0;
    //     background-color: #f6f6f6;
    // }

    // .top-menu > li > a {
    //     display: block;
    //     min-height: 55px;
    //     padding: 18px;
    //     font-size: 15px;
    //     color: #555;
    // }

    // .top-menu > li > a:focus {
    //     position: relative;
    //     z-index: 1;
    // }

    // .top-menu .sublist {
    //     display: none;
    //     background-color: #fff;
    //     padding: 5px 0;
    // }

    // .top-menu .sublist li {
    //     position: relative;
    //     margin: 1px 0 1px 20px;
    // }

    // .top-menu .sublist li a {
    //     display: block;
    //     padding: 15px 18px;
    //     font-size: 15px;
    // }

    // .sublist-toggle {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 55px;
    //     height: 55px;
    //     border-left: 1px solid #fff;
    //     background: url('../../images/toggle-black.png') center no-repeat;
    //     padding: 15px;
    //     font-size: 15px;
    //     font-weight: bold;
    //     color: #fff;
    //     text-transform: uppercase;
    //     cursor: pointer;
    // }

    // .sublist .sublist-toggle {
    //     height: 49px;
    // }

    .sublist .sublist li {
        background-color: #f6f6f6;
    }

    .sublist .sublist .sublist li {
        background-color: #fff;
    }

    .block-category-navigation {
        display: none;
    }

    .block-account-navigation {
        float: none;
        margin: auto !important;
    }

    .write-review .review-rating {
        text-align: center;
    }

    .write-review .review-rating div.name-description {
        width: 100%;
        margin: 0 5px 0 0;
        text-align: center;
    }
}





@media all and (min-width: 481px) {


    /*** GLOBAL ***/


    .master-wrapper-content {
        width: 92.5%;
    }


    /*** GLOBAL TABLES ***/


    .cart tr {
        width: 46%;
        margin: 40px 2% 0;
    }

    .cart tr:nth-child(2n+1) {
        clear: both;
    }

    .order-details-page .data-table tr {
        width: 46%;
        margin: 40px 2% 0;
    }

    .order-details-page .data-table tr:nth-child(2n+1) {
        clear: both;
    }


    /*** HEADER ***/


    .header-selectors-wrapper > div {
        display: inline-block;
        width: 30%;
        margin: 10px auto;
    }

    // .search-box input.search-box-text {
    //     width: 280px;
    // }

    .ui-autocomplete {
        width: 280px !important;
    }

    .ui-autocomplete img {
        display: inline;
    }


    /*** FOOTER ***/


    .footer-block {
        width: 85%;
    }

    .newsletter-email input[type="text"] {
        width: 240px;
    }


    /*** CATEGORY ***/


    .item-box {
        width: 49%;
        // margin: 0 0.5% 50px;
    }

    // .item-box:nth-child(2n+1) {
    //     clear: both;
    // }

    .item-box .product-rating-box {
        margin: 0 0 15px;
    }

    .item-box .prices {
        min-height: 40px;
    }

    .item-box .product-title {
        min-height: 42px;
        margin: 0 0 5px;
    }


    /*** PRODUCT PAGE ***/


    .overview-buttons div {
        display: inline-block;
        width: auto;
        margin: 0 1px 2px;
    }


    /*** WISHLIST ***/


    // .wishlist-content .button-2 {
    //     display: inline-block;
    //     width: auto;
    //     min-width: 140px;
    //     margin: 0 1px 5px;
    // }


    /*** TOPICS ***/


    .enter-password-form input[type="password"] {
        width: 260px;
    }


    /*** FORUM & PROFILE ***/

    .forums-header {
        background-color: #f9f9f9;
        padding: 30px 20px 20px;
    }

    .ui-tabs-nav li {
        display: inline-block;
        min-width: 150px;
        margin: 0 5px -1px;
        /*override jQuery UI styles, do not delete doubled properties*/
        margin: 0 5px -1px !important;
    }

    .private-messages-page .buttons .button-1,
    .private-messages-page .buttons .button-2,
    .private-message-view-page .buttons .button-1,
    .private-message-view-page .buttons .button-2 {
        display: inline-block;
        width: auto;
        margin: 1px;
    }

    .topic-post .post-text {
        float: left;
        width: 90.5%;
        text-align: left;
    }

    .forum-group .forum-table th.latest-post,
    .forum-group .forum-table th.topics,
    .forum-group .forum-table th.posts,
    .forum-group .forum-table td.latest-post,
    .forum-group .forum-table td.topics,
    .forum-group .forum-table td.posts {
        display: table-cell;
    }

    .topic-group .forum-table th.latest-post,
    .topic-group .forum-table td.latest-post {
        display: table-cell;
    }

    .forum-table td.image {
        max-width: 40px;
    }

    .forum-table td.image div {
        max-width: 100%;
        background-size: contain;
    }


    /*** ESTIMATE SHIPPING POPUP ***/

    .estimate-shipping-popup {
        padding: 25px;
    }
}


@media all and (min-width: 769px) {


    /**** GLOBAL ***/


    .master-wrapper-content {
        width: 94%;
    }

    .home-page-polls li {
        min-width: 100px;
    }


    /**** GLOBAL TABLES ***/


    .cart tr {
        width: 31.33333%;
        margin: 40px 1% 0;
    }

    .cart tr:nth-child(2n+1) {
        clear: none;
    }

    .cart tr:nth-child(3n+1) {
        clear: both;
    }

    .order-details-page .data-table tr {
        width: 31.33333%;
        margin: 40px 1% 0;
    }

    .order-details-page .data-table tr:nth-child(2n+1) {
        clear: none;
    }

    .order-details-page .data-table tr:nth-child(3n+1) {
        clear: both;
    }


    /*** HEADER ***/


    .header-selectors-wrapper > div {
        width: 20%;
    }


    /*** FOOTER ***/


    .footer-block {
        display: inline-block;
        width: 25.33333%;
        margin: 0 4%;
        vertical-align: top;
    }

    .footer-block .title {
        margin: 0 0 15px;
        background: none;
        padding: 0;
        color: #444;
        cursor: auto;
    }

    .footer-block .list {
        display: block !important; /* prevents toggle display:none on resize */
        background: none;
        padding: 0;
    }

    .footer-block .list li {
        padding: 5px 0;
    }

    .footer-block .list a {
        display: inline;
        padding: 0;
    }

    .follow-us {
        width: 98%;
        margin: 25px auto 0;
    }


    /*** CATEGORY ***/


    // .product-selectors {
    //     text-align: left;
    // }

    // .product-selectors .product-viewmode {
    //     display: inline-block;
    //     float: right;
    // }

    // .product-selectors .product-viewmode span {
    //     display: none;
    // }

    // .product-selectors .product-viewmode a {
    //     display: inline-block;
    //     width: 24px;
    //     height: 32px;
    //     margin: 0 0 0 12px;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     opacity: 0.5;
    // }

    // .product-selectors .product-viewmode a.grid {
    //     background-image: url('../../images/view-grid.png');
    // }

    // .product-selectors .product-viewmode a.list {
    //     background-image: url('../../images/view-list.png');
    // }

    // .product-selectors .product-viewmode a.selected {
    //     opacity: 1;
    // }

    .item-box {
        width: 32.33333%;
        // margin: 0 0.5% 50px;
    }

    // .item-box:nth-child(2n+1) {
    //     clear: none;
    // }

    // .item-box:nth-child(3n+1) {
    //     clear: both;
    // }

    .product-list .item-box {
        float: none;
        width: auto;
        margin: 0 5px 20px;
    }

    .product-list .item-box .product-item:after {
        content: "";
        display: block;
        clear: both;
    }

    .product-list .item-box .picture {
        float: left;
        width: 220px;
    }

    .product-list .item-box:hover .picture a img,
    .product-list .item-box:focus .picture a img {
        opacity: 1;
    }

    .product-list .item-box .picture:hover a img,
    .product-list .item-box .picture:focus a img {
        opacity: 0.85;
    }

    .product-list .item-box .picture + .details {
        margin: 0 0 0 240px;
    }

    .product-list .item-box .product-title {
        height: auto;
        padding: 10px 0;
        font-size: 18px;
    }

    .product-list .item-box .description {
        display: block;
        margin: 0 0 10px;
        line-height: 22px;
    }

    .product-list .item-box .prices {
        height: auto;
        margin: 0 0 30px;
    }

    // .product-list .item-box .buttons {
    // }

    .product-list .item-box .product-box-add-to-cart-button {
        width: auto;
        min-width: 150px;
        padding: 0 20px;
    }

    .product-list .item-box .add-to-compare-list-button,
    .product-list .item-box .add-to-wishlist-button {
        width: 42px;
    }


    /*** PRODUCT PAGE ***/


    // .gallery {
        // width: 550px;
    // }

    .variant-picture {
        float: left;
    }

    .variant-picture + .variant-overview {
        width: auto;
        margin: 0 0 0 240px;
    }

    .variant-overview {
        text-align: left;
    }


    /*** PRODUCT REVIEWS ***/


    .product-review-item .review-title {
        float: left;
        max-width: 600px;
    }

    .product-review-item .product-review-box {
        float: right;
    }

    .write-review .review-rating div {
        display: inline-block;
        margin: 0 0 0 5px;
        cursor: default;
        vertical-align: middle;
        font-size: 14px;
        height: 17px;
    }




    /*** REGISTRATION, LOGIN, ACCOUNT ***/


    .address-list-page .section,
    .order-list-page .section,
    .return-request-list-page .section {
        display: inline-block;
        // width: 45%;
        // margin: 20px 1%;
        vertical-align: top;
    }

    .recurring-payments {
        width: 100% !important;
        margin: 20px 0 !important;
    }


    /*** SHOPPING CART ***/


    .order-progress {
        padding: 20px;
    }

    .order-progress li {
        margin: 0 10px;
    }

    .order-progress a {
        min-width: 80px;
    }

    .cart-options .common-buttons {
        text-align: right;
    }

    .cart-options .checkout-attributes,
    .cart-options .selected-checkout-attributes {
        padding: 0 10px;
    }

    .cart-footer .totals {
        float: right;
        // width: 45%;
        // margin: 0 2.5%;
    }

    .cart-footer .terms-of-service {
        text-align: left;
    }

    .cart-footer .checkout-buttons,
    .cart-footer .addon-buttons {
        text-align: right;
    }

    // .cart-footer .cart-collaterals {
    //     float: left;
    //     width: 45%;
    //     margin: 0 2.5%;
    // }

    .cart-footer .cart-collaterals div,
    .cart-footer .cart-collaterals label {
        text-align: left;
    }

    .cart-footer .estimate-shipping label {
        display: block;
        margin: 0 0 10px;
    }

    .cart-footer .estimate-shipping .buttons {
        text-align: right;
    }


    /*** CHECKOUT ***/


    .checkout-page .selected-checkout-attributes {
        float: right;
        text-align: right;
    }

    // .checkout-page .cart-footer .totals {
    //     max-width: 350px;
    //     margin: 0;
    // }

    .checkout-page .address-grid {
        overflow: hidden;
    }

    .checkout-page .address-item {
        display: inline-block;
        vertical-align: top;
        zoom: 1;
        width: 48%;
        margin: 0 1% 40px;
    }

    // .shipping-method .method-list li,
    // .payment-method .method-list li {
    //     display: inline-block;
    //     width: 25%;
    //     margin: 20px 2%;
    //     vertical-align: top;
    // }

    // .shipping-method .method-name {
    //     background-color: #f6f6f6;
    //     padding: 10px;
    // }

    // .payment-method .payment-logo {
    //     display: block;
    //     background-color: #f6f6f6;
    //     padding: 10px;
    // }

    // .payment-method .payment-details {
    //     display: block;
    //     width: auto;
    //     min-width: 0;
    //     margin: 10px 0 0;
    //     text-align: center;
    // }

    .payment-info .info {
        padding: 30px 75px;
    }

    .order-review-data,
    .order-details-area,
    .shipment-details-area {
        overflow: hidden;
    }

    // .order-review-data > div,
    // .order-details-area > div,
    // .shipment-details-area > div {
    //     float: left;
    //     width: 48%;
    //     margin: 0 1% 40px;
    // }


    /*** ORDER DETAILS & SHIPPING DETAILS ***/


    .order-details-page .section.options {
        text-align: right;
    }

    .order-details-page .selected-checkout-attributes {
        padding: 0 10px;
    }

    .order-details-page .tax-shipping-info {
        text-align: right;
    }

    .order-details-page .totals {
        float: right;
        clear: both;
    }

    .shipment-details-page .tracking-number .tracking-url {
        color: #4ab2f1;
    }


    /*** BLOG & NEWS ***/


    .blog-posts .tags {
        float: right;
        width: 80%;
        text-align: right;
    }

    .blog-posts .buttons {
        float: left;
        width: 20%;
        text-align: left;
    }

    .new-comment {
        text-align: center;
    }

    .new-comment .buttons {
        text-align: center;
    }

    .comment-list .comment {
        display: table;
        width: 100%;
        margin: 0;
    }

    .comment-list .comment:last-child {
        border-bottom: 1px solid #ddd;
    }

    .comment-info {
        display: table-cell;
        width: 15%;
        margin: 0;
        vertical-align: top;
    }

    .comment-info .username {
        min-height: 40px;
        margin: 0;
        border: none;
        text-align: center;
    }

    .comment-info .avatar {
        border: none;
    }

    .comment-info .avatar img {
        max-width: 80%;
        max-height: 80%;
    }

    .comment-content {
        display: table-cell;
        width: 85%;
        border-left: 1px solid #ddd;
        vertical-align: top;
        text-align: left;
    }

    .comment-time {
        min-height: 40px;
        margin: 0;
        background-color: #f6f6f6;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: normal;
    }

    .comment-title {
        margin: 0 0 -20px;
        padding: 20px;
    }

    .comment-body {
        padding: 20px;
    }


    /*** FORUM & PROFILE ***/


    .forum-actions,
    .topic-actions {
        overflow: hidden;
    }

    .forum-actions .actions,
    .topic-actions .actions {
        float: left;
    }

    .forum-actions .pager,
    .topic-actions .pager {
        float: right;
        max-width: 450px;
    }

    .forum-actions .pager li,
    .topic-actions .pager li {
        margin: 2px 4px;
    }

    .forum-actions .pager.upper {
        display: block;
    }

    .topic-actions.lower .actions {
        display: block;
    }

    .topic-posts {
        margin: 0 0 30px;
    }

    .topic-post {
        display: table;
        width: 100%;
        margin: 0;
    }

    .topic-post .post-info {
        display: table-cell;
        width: 15%;
        margin: 0;
        vertical-align: top;
        text-align: left;
    }

    .topic-post .user-info {
        width: auto;
    }

    .topic-post .username {
        min-height: 40px;
        margin: 0;
        border: none;
        text-align: center;
    }

    .topic-post .avatar {
        margin: auto;
        border: none;
    }

    .topic-post .avatar img {
        max-width: 80%;
        max-height: 80%;
    }

    .topic-post .user-stats li {
        margin: 0;
    }

    .topic-post .send-pm {
        margin: 0 0 20px;
    }

    .topic-post .pm-button,
    .profile-info-box .pm-button {
        padding: 8px 16px;
        font-size: 13px;
    }

    .topic-post .post-content {
        display: table-cell;
        width: 85%;
        border-left: 1px solid #ddd;
        vertical-align: top;
        text-align: left;
    }

    .topic-post .post-head {
        min-height: 40px;
        overflow: hidden;
        background-color: #f6f6f6;
    }

    .topic-post .post-time {
        float: left;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
    }

    .topic-post .post-actions {
        float: right;
        margin: 0;
    }

    .topic-post .post-actions a {
        margin: 0;
        line-height: 20px;
    }

    // .topic-post .post-actions .manage-post a {
    // }

    .topic-post .post-actions .quote-post a {
        min-width: 70px;
    }

    .topic-post .post-actions a.post-link-button {
        margin: 0 0 0 10px;
        border-left: 1px solid #ddd;
        text-align: center;
    }

    .topic-post .post-text {
        padding: 20px 5px;
    }

    .topic-post .post-vote {
        padding: 20px 0 20px 15px;
    }

    .forum-table th.replies,
    .forum-table th.views,
    .forum-table th.votes,
    .forum-table td.replies,
    .forum-table td.views,
    .forum-table td.votes {
        display: table-cell;
    }

    .forum-table td.image {
        max-width: none;
    }

    .forum-table td.image div {
        max-width: none;
        background-size: initial;
    }

    .ui-tabs-nav {
        text-align: left;
    }

    .ui-tabs-nav li {
        margin: 0 10px -1px 0;
        /*override jQuery UI styles, do not delete doubled properties*/
        margin: 0 10px -1px 0 !important;
    }

    .profile-info-box .avatar {
        float: left;
        margin: 0 15px 15px 0;
    }

    .profile-info-box .profile-stats {
        float: left;
        text-align: left;
    }

    .profile-info-box .title {
        text-align: left;
    }

    .profile-info-box .send-pm {
        clear: both;
        text-align: left;
    }
}





@media all and (min-width: 1001px) {


    /*** GLOBAL STYLES ***/


    .master-wrapper-content {
        width: 980px;
        margin: auto;
    }

    .center-2 {
        float: right;
        width: 73%;
    }

    .side-2 {
        float: left;
        width: 25%;
    }

    // .page {
    //     text-align: left;
    // }

    .inputs {
        text-align: left;
    }

    // .inputs label {
    //     display: inline-block;
    //     width: 265px;
    //     margin: 0 10px 0 0;
    //     text-align: right;
    // }

    // .inputs input[type="text"],
    // .inputs input[type="email"],
    // .inputs input[type="tel"],
    // .inputs input[type="password"],
    // .inputs select {
    //     min-height: 40px;
    // }

    .inputs .option-list li {
        margin: 0 10px 0 0;
    }

    .center-1 .buttons {
        text-align: center;
    }

    // .center-2 .inputs label {
    //     width: 136px;
    // }

    .inputs.accept-consent label {
        width: auto;
    }

    .page-title a + h1 {
        float: left;
    }

    .link-rss {
        display: block;
        float: right;
        margin: 6px 0 0;
    }


    /*** GLOBAL TABLES ***/


    .cart tr {
        width: auto;
        margin: 0;
    }

    .cart label {
        display: none;
    }

    .order-details-page .data-table tr {
        width: auto;
        margin: 0;
    }

    .order-details-page .data-table label {
        display: none;
    }


    /*** HEADER ***/


    // .header {
    //     width: 980px;
    //     margin: auto;
    // }

    .header-upper:after {
        content: "";
        display: block;
        clear: both;
    }

    .header-selectors-wrapper {
        float: left;
        border: none;
        padding: 0;
    }

    .header-selectors-wrapper > div {
        width: auto;
        min-width: 100px;
        margin: 0 12px 0 0;
        line-height: 43px;
    }

    .header-selectors-wrapper select {
        height: 25px;
        padding: 4px;
        font-size: 12px;
    }

    .language-list {
        text-align: left;
    }

    // .header-links-wrapper {
    //     float: right;
    //     padding: 0;
    // }

    // .header-links li {
        // margin: 0 16px;
    // }

    .header-links a,
    .header-links span {
        // line-height: 45px;
        text-transform: none;
    }

    // #topcartlink {
    //     display: inline-block;
    //     width: auto;
    //     margin: 0;
    //     padding: 0 18px;
    // }

    .flyout-cart {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 100;
        width: 300px;
        // box-shadow: 0 0 2px rgba(0,0,0,0.25);
        // background-color: #fff;
    }

    // .flyout-cart.active {
    //     display: block;
    // }

    .mini-shopping-cart {
        padding: 12px;
        text-align: left;
        font-size: 12px;
    }

    .mini-shopping-cart .count {
        padding: 5px 0 15px;
        // color: #444;
    }

    .mini-shopping-cart .count a {
        margin: 0 2px;
        font-weight: bold;
        color: #444;
    }

    .mini-shopping-cart .items {
        // border-top: 1px solid #ddd;
        // border-bottom: 1px solid #ddd;
        margin: 0 0 20px;
    }

    .mini-shopping-cart .item {
        overflow: hidden;
        padding: 10px 0;
    }

    .mini-shopping-cart .picture {
        float: left;
        width: 70px;
        text-align: center;
    }

    .mini-shopping-cart .picture a {
        display: block;
        position: relative;
        overflow: hidden;
    }

    .mini-shopping-cart .picture a:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .mini-shopping-cart .picture img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        max-width: 100%;
    }

    // .mini-shopping-cart .picture + .product {
    //     margin: 0 0 0 80px;
    // }

    .mini-shopping-cart .name {
        margin: 0 0 10px;
        // font-size: 14px;
    }

    // .mini-shopping-cart .name a {
    //     color: #4ab2f1;
    // }

    // .mini-shopping-cart .name a:hover,
    // .mini-shopping-cart .name a:focus {
    //     color: #248ece;
    // }

    .mini-shopping-cart .attributes {
        margin: 5px 0;
        color: #444;
    }

    .mini-shopping-cart .totals {
        margin: 0 0 12px;
        font-size: 14px;
    }

    .mini-shopping-cart .totals strong {
        font-size: 16px;
        color: #444;
    }

    .mini-shopping-cart .buttons {
        margin: 0 0 5px;
    }

    .mini-shopping-cart button[type="button"] {
        display: inline-block;
        // border: none;
        // background-color: #4ab2f1;
        padding: 10px 20px;
        font-size: 12px;
        // color: #fff;
        text-transform: uppercase;
    }

    // .mini-shopping-cart button[type="button"]:hover,
    // .mini-shopping-cart button[type="button"]:focus {
    //     background-color: #248ece;
    // }

    // .header-lower {
    //     display: table;
    //     width: 100%;
    // }

    // .header-logo {
    //     display: table-cell;
    //     text-align: left;
    //     vertical-align: middle;
    //     font-size: 0;
    // }

    // .search-box {
    //     display: table-cell;
    //     text-align: right;
    //     vertical-align: middle;
    // }

    // .search-box input.search-box-text,
    // .search-box .search-box-button {
    //     height: 40px;
    // }


    /*** NAVIGATION ***/


    .header-menu {
        position: relative;
        z-index: 5;
        // width: 980px;
        // margin: 0 auto 30px;
        // border-top: 1px solid #ddd;
        // border-bottom: 1px solid #ddd;
        // padding: 25px 0;
        // text-align: center;
    }

    // .menu-toggle,
    // .sublist-toggle {
    //     display: none;
    // }

    .header-menu > ul {
        display: block ; /* prevents toggle display:none on resize */
        font-size: 0;
    }

    // .header-menu > ul.mobile {
    //     display: none !important;
    // }

    .header-menu > ul > li {
        display: inline-block;
        border-left: 1px solid #ddd;
        padding: 0 15px;
        vertical-align: middle;
    }

    .header-menu > ul > li:first-child {
        border-left: none;
    }

    .header-menu > ul > li > a {
        display: block;
        // padding: 5px 10px;
        // line-height: 20px;
        // font-size: 17px; /*reset zeroing*/
        // color: #555;
        cursor: pointer;
    }

    // .header-menu > ul > li > a:hover,
    // .header-menu > ul > li > a:focus {
    //     color: #4ab2f1;
    // }

    .header-menu .sublist {
        // display: none;
        position: absolute;
        // width: 200px;
        // box-shadow: 0 0 2px rgba(0,0,0,0.2);
        // background-color: #fff;
        // padding: 10px 0;
        text-align: left;
    }

    .header-menu .sublist li {
        position: relative;
    }

    .header-menu .sublist li a {
        display: block;
        position: relative;
        // padding: 10px 20px 10px 30px;
        // font-size: 14px;
        // color: #444;
    }

    // .header-menu .sublist li a:before {
    //     content: "";
    //     position: absolute;
    //     top: 17px;
    //     left: 15px;
    //     width: 5px;
    //     height: 5px;
    //     background-color: #ddd;
    // }

    // .header-menu .sublist li:hover > a,
    // .header-menu .sublist li:focus > a {
    //     background-color: #f6f6f6;
    // }

    // .header-menu ul li:hover > .sublist,
    // .header-menu ul li:focus > .sublist {
    //     display: block;
    // }

    .header-menu .sublist .sublist {
        top: -10px;
        // left: 100% !important;
    }


    /*** FOOTER ***/


    .footer-upper {
        width: 980px;
        padding: 35px 10px;
    }

    .footer-upper:after {
        content: "";
        display: block;
        clear: both;
    }

    .footer-block {
        float: left;
        width: 220px;
        margin: 0;
    }

    // .footer-block:first-child {
    // }

    .footer-block .list {
        margin: 0;
    }

    .follow-us {
        float: right;
        width: 300px !important;
        margin: 0;
        text-align: left;
    }

    .follow-us .social li {
        margin: 0 10px 0 0;
    }

    .newsletter-email {
        display: block;
    }

    .newsletter-email input[type="text"] {
        width: 200px;
    }

    .newsletter-email .newsletter-subscribe-button {
        min-width: 100px;
        padding: 0 5px;
    }

    .newsletter-validation .field-validation-valid {
        text-align: left;
    }

    .footer-lower {
        position: relative;
        width: 980px;
        padding: 25px 5px;
    }

    .footer-lower:after {
        content: "";
        display: block;
        clear: both;
    }

    .footer-info {
        float: right;
        line-height: 32px;
    }

    .footer-powered-by {
        float: left;
        margin: 0;
        line-height: 32px;
    }

    .theme-selector {
        float: left;
        margin: 0 0 0 15px;
    }


    /*** COLUMN ***/


    .block {
        float: none;
        width: auto;
        margin: 0 0 20px;
    }

    .block .title {
        // margin: 0;
        // border-bottom: 1px solid #ddd;
        background: none;
        // cursor: auto;
    }

    .block .title strong {
        font-weight: bold;
    }

    .block .listbox {
        display: block;
        border-bottom: none;
    }

    .block a.product-picture {
        display: inline-block;
        position: relative;
        width: 30%;
        margin: 0 0 0 -15px;
        vertical-align: middle;
    }

    .block a.product-name {
        vertical-align: middle;
    }

    .block a.product-picture + a.product-name {
        width: 70%;
    }

    .block a.product-picture + a.product-name:before {
        display: none;
    }


    /*** CATEGORY ***/

    .item-box .buttons {
        text-align: left;
    }

    .home-page .product-grid .item-box,
    .product-details-page .product-grid .item-box,
    .shopping-cart-page .product-grid .item-box {
        width: 24%;
        margin: 0 0.5% 50px;
    }

    .home-page .product-grid .item-box:nth-child(3n+1),
    .product-details-page .product-grid .item-box:nth-child(3n+1),
    .shopping-cart-page .product-grid .item-box:nth-child(3n+1) {
        clear: none;
    }

    .home-page .product-grid .item-box:nth-child(4n+1),
    .product-details-page .product-grid .item-box:nth-child(4n+1),
    .shopping-cart-page .product-grid .item-box:nth-child(4n+1) {
        clear: both;
    }


    /*** PRODUCT PAGE ***/


    // .gallery {
        // float: left;
        // width: 46%;
    // }

    .gallery .picture {
        width: auto;
    }

    // .overview {
    //     float: right;
    //     width: 52%;
    // }

    .rental-attributes {
        overflow: hidden;
    }

    .rental-attributes .attribute-item {
        float: left;
        margin: 0 20px 0 0;
    }

    .rental-attributes .datepicker {
        width: 160px;
        text-align: left;
    }

    .attribute-squares li {
        margin: 0 10px 10px 0;
    }

    .product-details-page .title {
        text-align: left;
    }


    /*** PRODUCT REVIEWS ***/


    .product-review-item .review-title {
        max-width: 850px;
    }

    .write-review .review-rating div.name-description {
        width: 265px;
        margin: 0 5px 0 0;
        text-align: right
    }

    .product-review-item .review-text {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .product-review-item .avatar {
        display: table-cell;
        vertical-align: top;
        width: 120px;
    }

    .product-review-item .avatar img {
        max-width: 100%;
    }

    .product-review-item .text-body {
        display: table-cell;
        vertical-align: top;
    }

    .product-review-item .avatar + .text-body {
        padding: 0 0 0 20px;
    }


    /*** WISHLIST & COMPARE LIST ***/

    // .compare-products-page .page-title {
    //     margin: 0 0 -1px;
    // }

    .compare-products-page .clear-list {
        position: absolute;
        top: 7px;
        right: 0;
    }

    .compare-products-table .full-description td {
        display: table-cell;
    }

    .compare-products-table .full-description td p {
        margin-top: 0;
    }


    /*** TOPICS ***/


    .center-1 .topic-block-title {
        text-align: center;
    }

    .topic-page p {
        text-align: justify;
    }


    /*** REGISTRATION, LOGIN, ACCOUNT ***/


    .gender span {
        margin: 0 10px 0 0;
    }

    .gender span > * {
        margin: 0 5px 0 0;
    }

    .date-of-birth select + select {
        margin: 0 0 0 11px;
    }

    #check-availability-button {
        display: block;
        margin: 10px auto;
    }

    .login-page .customer-blocks {
        overflow: hidden;
    }

    .login-page .new-wrapper {
        float: left;
        width: 49%;
        margin: 0;
    }

    // .login-page .new-wrapper .text {
    //     min-height: 200px;
    // }

    .login-page .returning-wrapper {
        float: right;
        width: 49%;
    }

    // .login-page .returning-wrapper .form-fields {
    //     min-height: 200px;
    //     padding: 30px 0 0;
    // }

    // .login-page .returning-wrapper .inputs label {
    //     width: 110px;
    // }

    // .login-page .returning-wrapper input[type="text"],
    // .login-page .returning-wrapper input[type="email"],
    // .login-page .returning-wrapper input[type="password"] {
    //     width: 300px;
    // }

    .address-list-page .section,
    .order-list-page .section,
    .return-request-list-page .section {
        display: block;
        width: auto;
        // margin: 0 0 40px;
    }

    // .address-list-page .buttons,
    // .order-list-page .buttons {
    //     position: absolute;
    //     top: 5px;
    //     right: 10px;
    // }

    .avatar-page .message-error,
    .avatar-page .buttons {
        text-align: left;
    }

    .registration-page .vat-note {
        margin-left: 275px;
    }

    .customer-info-page .vat-note,
    .customer-info-page .email-to-revalidate-note {
        margin-left: 150px;
    }

    .customer-info-page .vat-status {
        display: inline-block;
    }

    /*** SHOPPING CART ***/


    .shopping-cart-page .cart-options {
        overflow: hidden;
    }

    .shopping-cart-page .checkout-attributes {
        float: left;
        max-width: 565px;
        margin: -30px 0 15px;
    }

    .shopping-cart-page .checkout-attributes li {
        margin: 5px 10px 5px 0;
    }

    .shopping-cart-page .selected-checkout-attributes {
        float: right;
        max-width: 395px;
        margin: 15px 0;
    }


    /*** CHECKOUT ***/


    .order-progress {
        margin: -30px 0 50px;
    }

    .checkout-page .address-item {
        width: 31%;
    }

    .checkout-page .terms-of-service {
        text-align: center;
    }

    .payment-info .info {
        padding: 30px 15px;
    }

    .payment-info .info td:first-child {
        width: 265px !important;
        // margin: 0 10px 0 0;
        // text-align: right;
    }

    .payment-info .info td:only-child {
        width: 100% !important;
    }

    .order-review-data .title,
    .order-details-area .title,
    .shipment-details-area .title {
        text-align: left;
    }

    .opc .order-review-data ul {
        text-align: left;
    }


    /*** SEARCH & SITEMAP ***/


    .advanced-search .price-range {
        margin-left: 114px;
    }


    /*** BLOG & NEWS ***/


    .comment-list {
        margin: 0 0 50px;
    }

    .news-list-homepage .news-items {
        overflow: hidden;
        text-align: left;
    }

    .news-list-homepage .news-item {
        float: left;
        width: 31.33333%;
        margin: 0 1% 30px;
    }

    .news-list-homepage .news-item:nth-child(3n+1) {
        clear: both;
    }

    .news-list-homepage .news-item:only-child {
        float: none;
        width: auto;
        margin: 0 0 30px;
    }

    .news-list-homepage .news-head {
        text-align: center;
    }

    .news-list-homepage .news-body {
        text-align: justify;
    }


    /*** FORUM & PROFILE ***/


    .forum-breadcrumb {
        margin: -30px 0 30px;
    }

    .current-time {
        float: right;
    }

    .forum-search-box {
        float: left;
    }

    .forum-search-box .advanced {
        text-align: left;
    }

    .forum-search-box .advanced a {
        line-height: normal;
    }

    .forums-table-section td {
        min-width: 150px;
    }

    .forums-table-section td:first-child {
        min-width: 90px;
    }

    .forums-table-section .view-all {
        text-align: right;
    }

    .topic-actions .pager.upper {
        display: block;
        max-width: 400px;
    }

    .forum-table .forum-details,
    .forum-table .topic-details {
        min-width: 225px;
    }

    .forum-table td {
        padding: 20px;
    }

    .forum-edit-page textarea,
    .private-message-send-page textarea {
        width: 600px;
        height: 250px;
    }

    .private-message-send-page .bb-code-editor-wrapper {
        display: inline-block;
        width: 400px;
        margin: 10px 0;
        vertical-align: middle;
    }
}





@media all and (min-width: 1367px) {


    /*** GLOBAL STYLES ***/


    .master-wrapper-content {
        width: 1104px;
    }

    // .inputs label {
    //     width: 375px;
    // }

    // .center-2 .inputs label {
    //     width: 213px;
    // }

    .inputs.accept-consent label {
        width: auto;
    }


    /*** HEADER ***/


    .header {
        width: 100%;
    }


    /*** NAVIGATION ***/


    // .header-menu {
    //     width: 100%;
    // }

    .header-menu > ul > li {
        padding: 0 20px;
    }

    // .header-menu > ul > li > a,
    // .header-menu > ul > li > span {
    //     font-size: 18px;
    // }


    /*** FOOTER ***/


    .footer-upper {
        width: 1200px;
        padding: 35px 30px;
    }

    .footer-block {
        width: 280px;
    }

    .footer-lower {
        width: 1200px;
        padding: 25px 20px;
    }


    /*** PRODUCT REVIEWS ***/


    .product-review-item .review-title {
        max-width: 1050px;
    }

    .write-review .review-rating div.name-description {
        margin: 0 5px 0 0;
        min-width: 375px;
    }


    /*** REGISTRATION, LOGIN, ACCOUNT ***/


    // .login-page .returning-wrapper input[type="text"],
    // .login-page .returning-wrapper input[type="email"],
    // .login-page .returning-wrapper input[type="password"] {
    //     width: 360px;
    // }

    .registration-page .vat-note {
        margin-left: 385px;
    }

    .customer-info-page .vat-note,
    .customer-info-page .email-to-revalidate-note {
        margin-left: 225px;
    }

    /*** SHOPPING CART ***/


    .cart-footer .totals {
        width: 395px;
        margin: 0;
    }

    .cart-footer .cart-collaterals {
        width: 760px;
        margin: 0;
    }

    .cart-footer .cart-collaterals .deals {
        float: left;
        // width: 360px;
    }

    .cart-footer .cart-collaterals .shipping {
        float: right;
        // width: 360px;
    }

    // .cart-footer .estimate-shipping .inputs label {
    //     display: inline-block;
    //     width: 35%;
    // }

    .cart-footer .estimate-shipping .inputs input,
    .cart-footer .estimate-shipping .inputs select {
        width: 65%;
    }


    /*** CHECKOUT ***/


    // .shipping-method .method-list li,
    // .payment-method .method-list li {
    //     margin: 20px 1%;
    // }

    .payment-info .info td:first-child {
        width: 375px !important;
    }

    .payment-info .info td:only-child {
        width: 100% !important;
    }


    /*** FORUM & PROFILE ***/


    .forum-actions .pager,
    .topic-actions .pager {
        max-width: 600px !important;
    }

    .topic-post .post-text {
        width: 930px;
        max-width: 100%;
    }
}







.hero-title {
    font-family: 'Manrope', 'Cairo', sans-serif;
    font-size: 61px;
    font-weight: 800;
    line-height: 55.51px;
}

.xlarge-title {
    font-family: 'Manrope', 'Cairo', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 52.8px;
}

.large-title {
    font-family: 'Manrope', 'Cairo', sans-serif;
    font-size: 40px;
    font-weight: 800;
    line-height: 44px;
}

.normal-title {
    font-family: 'Manrope', 'Cairo', sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
}

.small-title {
    font-family: 'Manrope', 'Cairo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
}

.promoted-text {
    font-family: 'Manrope', 'Cairo', sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
}

.paragraph-text {
    font-family: 'Manrope', 'Cairo', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 36.26px;
}
